// ServiceWorkerUpdateHandler.js
import React, { useEffect, useState } from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';

const ServiceWorkerUpdateHandler = () => {
  const [waitingServiceWorker, setWaitingServiceWorker] = useState(null);
  const [showReload, setShowReload] = useState(false);

  useEffect(() => {
    // Check if service workers are supported
    if ('serviceWorker' in navigator) {
      const wb = navigator.serviceWorker;

      // Add event listeners
      const handleInstalling = (installingWorker) => {
        console.log('Service Worker installing...');
      };

      const handleInstalled = (event) => {
        if (event.isUpdate) {
          console.log('New Service Worker installed.');
          setWaitingServiceWorker(event.worker);
          setShowReload(true);
        }
      };

      const handleControllerChange = () => {
        // This fires when the service worker controlling this page
        // changes, eg a new service worker has taken control.
        window.location.reload();
      };

      // Register service worker
      wb.register('/service-worker.js')
        .then(registration => {
          // Check if there's already a waiting service worker
          if (registration.waiting) {
            setWaitingServiceWorker(registration.waiting);
            setShowReload(true);
          }

          registration.addEventListener('installing', (event) => {
            handleInstalling(event.target);
          });

          registration.addEventListener('installed', handleInstalled);
          registration.addEventListener('controlling', handleControllerChange);
        })
        .catch(error => {
          console.error('Error during service worker registration:', error);
        });

      return () => {
        wb.removeEventListener('controlling', handleControllerChange);
      };
    }
  }, []);

  const reloadPage = () => {
    if (waitingServiceWorker) {
      // Send skip waiting message to the waiting service worker
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  };

  if (!showReload) return null;

  return (
    <View style={styles.container}>
      <View style={styles.alertBox}>
        <Text style={styles.title}>Update Available</Text>
        <Text style={styles.message}>
          A new version of the application is available. Please reload to update.
        </Text>
        <TouchableOpacity 
          style={styles.button}
          onPress={reloadPage}
        >
          <Text style={styles.buttonText}>Reload Application</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    bottom: 16,
    right: 16,
    zIndex: 999,
  },
  alertBox: {
    backgroundColor: 'white',
    borderRadius: 8,
    padding: 16,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    maxWidth: 320,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  message: {
    fontSize: 14,
    color: '#666',
    marginBottom: 16,
  },
  button: {
    backgroundColor: '#007AFF',
    borderRadius: 6,
    padding: 12,
    alignItems: 'center',
  },
  buttonText: {
    color: 'white',
    fontSize: 16,
    fontWeight: '600',
  }
});

export default ServiceWorkerUpdateHandler;