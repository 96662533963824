import React, {useContext, useEffect, useState, useRef} from 'react';
import styles from './TicketCoordinatesBStyles';
import {
  ScrollView,
  StatusBar,
  Text,
  View,
  TextInput,
  ActivityIndicator,
} from 'react-native';
import Colors from '../../theme/colors';
import {SafeAreaView} from 'react-native';
import SmartphoneSVG from '../../components/svgs/SmartphoneSVG';
import TitleA from '../../components/titleA/TitleA';
import SubheadingA from '../../components/subheadingA/SubheadingA';
import BodyA from '../../components/bodyA/BodyA';
import EmailSVG from '../../components/svgs/EmailSVG';
import TouchableItem from '../../components/TouchableItem';
import BackArrowSVG from '../../components/svgs/BackArrowSVG';
import ButtonA from '../../components/buttonA/ButtonA';
import CartSVG from '../../components/svgs/CartSVG';
import {useNavigation} from '@react-navigation/native';
import {Checkbox} from 'react-native-paper';

import {db, FUNCTIONS} from '../../services/firebase';
import {httpsCallable} from 'firebase/functions';
// contexts
import {AuthContext} from '../../contexts/context';
import Constants from 'expo-constants';
import {loadStripe} from '@stripe/stripe-js';
import {setReferral, emptyCart} from '../../reducers/cartactions';
import {connect, useSelector} from 'react-redux';
import {bindActionCreators} from 'redux';
// import Swiper from 'react-native-swiper';
import Swiper from 'react-native-swiper/src';

import getEnvVars from '../../config';
import {useMemo} from 'react';
import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import ErrorDisplay from '../../components/errors/TicketCoordinateErrorDisplay';
import CheckboxSVG from '../../components/svgs/CheckboxSvg';
import ParticipantForm from '../../components/forms/ParticipantForm';

const {paiement} = getEnvVars();
const stripePromise = loadStripe(paiement.publishableKey);


function isEmail(emailAdress) {
  let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  if (emailAdress.match(regex)) return true;
  else return false;
}

const TicketCoordinatesB = (props) => {
  const navigation = useNavigation();
  const {user} = useContext(AuthContext);
  // console.log('user', user ? true : false);

  const {route, emptyCart, cart} = props;

  const [event, setEvent] = useState([]);
  const swiperRef = useRef(null);

  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const {referralID} = useSelector((state) => state.referralID);
  const [showInvoices, setShowInvoices] = useState(false);
  const [currentListIndex, setCurrentListIndex] = useState(0);
  const [invoiceAvailable, setInvoiceAvailable] = useState(false);


  // Fonction pour gérer les changements de la checkbox
  const handleCheckboxChange = () => {
    setShowInvoices(!showInvoices);
  };
  const [listCoordinate, setListCoordinate] = useState([]);
  const [invoicesInfo, setInvoiceInfo] = useState();

  // const [error, setError] = useState(false);
  const [error, setError] = useState({
    errorVoice: false,
    errorFields: false,
  });
  const createEmptyInvoice = (invoiceInfo) => {
    const invoice = {
      name: '',
      nameFocused: false,
      adress: '',
      adressFocused: false,

      errors: {},
    };

    invoiceInfo?.map((p) => {
      invoice[p.field] = '';
      invoice[`${p.field}Focused`] = false;
    });
    return invoice;
  };

  const createEmptyParticipant = (participantInfo) => {
    const participant = {
      firstName: '',
      lastName: '',
      firstNameFocused: false,
      lastNameFocused: false,
      email: '',
      emailFocused: false,
      errors: {},
    };

    if (cart?.accessRestriction?.verifiedEmail) {
      participant.email = cart.accessRestriction.verifiedEmail;
    }
  
    participantInfo
      ?.sort((a, b) => {
      if (a.order === undefined && b.order === undefined) return 0;
      if (a.order === undefined) return 1;
      if (b.order === undefined) return -1;
      return a.order - b.order;
    })
    .map((p) => {
      if (p.field === 'email' && participant.email) return;
      participant[p.field] = '';
      participant[`${p.field}Focused`] = false;
    });
  
    return participant;
  };

  // ----------------Age array---------------
  const ages = [];
  for (let age = 18; age <= 65; age++) {
    ages.push(`${age} years old`);
  }
  // --------------------------------------

  // Function to get the month name
  const getMonthName = (monthIndex) => {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    return months[monthIndex];
  };

  useEffect(() => {
    const unsubscribe = db
      .collection('events')
      .doc(route?.params?.eventId)
      .onSnapshot(
        async (eventDoc) => {
          try {
            if (eventDoc.exists) {
              const docdata = eventDoc.data();
              let listProducts = props?.route?.params?.options.products;
              const isMultiple = listProducts.some((lp) => lp.isMultiple);

              if (docdata.isInvoiceRequred) {
                setInvoiceAvailable(true);
                const dataInvoice = {
                  id: route?.params?.eventId,
                  hasInvoiceInfo: false, // Initialize as false
                  invoiceInfoField: Array.from({length: 1}, () =>
                    createEmptyInvoice(docdata.invoiceInfo),
                  ),
                };
                setInvoiceInfo(dataInvoice);
              }

              const list = isMultiple
                ? listProducts?.flatMap((product) => {
                    const isMultiple = product?.isMultiple || false;
                    const quantity = product?.quantity || 1;

                    return Array.from({length: quantity}, (_, index) => {
                      // For each ticket, create participants array
                      const participants = Array.from(
                        {length: product?.personsNumber || 1},
                        (_, participantIndex) => {
                          if (participantIndex === 0 && index === 0) {
                            return createEmptyParticipant(
                              docdata.participantInfo,
                            );
                          } else {
                            const participant = {
                              name: '',
                              nameFocused: false,
                              email: '',
                              emailFocused: false,
                              errors: {},
                            };

                            docdata.participantInfo
                              ?.sort((a, b) => {
                                if (
                                  a.order === undefined &&
                                  b.order === undefined
                                )
                                  return 0;
                                if (a.order === undefined) return 1;
                                if (b.order === undefined) return -1;
                                return a.order - b.order;
                              })
                              .map((p) => {
                                participant[p.field] = '';
                                participant[`${p.field}Focused`] = false;
                              });

                            return participant;
                          }
                        },
                      );

                      const obj = {
                        id: `${product.id}-${index + 1}`,
                        idProduct: product.id,
                        order: index + 1,
                        isMultiple,
                        personsNumber: product?.personsNumber || null,
                        participants,
                      };

                      if (isMultiple) {
                        obj.societyInfo = {
                          name: '',
                          address: '',
                          codePostal: '',
                          siret: '',
                          nameFocused: false,
                          addressFocused: false,
                          postalCodeFocused: false,
                          siretFocused: false,
                          errors: {},
                        };
                      }

                      return obj;
                    });
                  })
                : [
                    {
                      id: listProducts[0].id,
                      isMultiple: false,
                      participants: Array.from({length: 1}, () =>
                        createEmptyParticipant(docdata.participantInfo),
                      ),
                    },
                  ];

              setListCoordinate(list);
              setEvent({...docdata, id: eventDoc.id});
            } else {     
              setEvent(null);
            }
          } catch (error) {
            console.log('error processing snapshot', error);
          }
        },
        (error) => {
          console.log('Snapshot error:', error);
        },
      );

    return () => unsubscribe();
  }, [route, user, props.route, cart?.accessRestriction]);

  const handleSocietyChange = (id, field, value) => {
    setListCoordinate(
      listCoordinate.map((item) => {
        if (item.id === id) {
          const updatedSocietyInfo = {
            ...item.societyInfo,
            [field]: value,
          };
          return {...item, societyInfo: updatedSocietyInfo};
        }
        return item;
      }),
    );
  };

  const handleChange = (id, index, field, value) => {
    setListCoordinate(
      listCoordinate.map((item) => {
        if (item.id === id) {
          const updatedParticipants = [...item.participants];
          updatedParticipants[index] = {
            ...updatedParticipants[index],
            [field]: value,
          };
          return {...item, participants: updatedParticipants};
        }
        return item;
      }),
    );
  };

  const handelInvoiceField = (invoiceIndex, field, value) => {
    setInvoiceInfo((prevInvoicesInfo) => ({
      ...prevInvoicesInfo,
      invoiceInfoField: prevInvoicesInfo.invoiceInfoField.map((item, i) => {
        if (invoiceIndex === i) {
          return {
            ...item,
            [field]: value,
          };
        }
        return item;
      }),
    }));
  };

  const handelFocusInvoiceField = (invoiceIndex, field, focused) => {
    setInvoiceInfo((prevInvoicesInfo) => ({
      ...prevInvoicesInfo,
      invoiceInfoField: prevInvoicesInfo.invoiceInfoField.map((item, i) => {
        if (invoiceIndex === i) {
          return {
            ...item,
            [`${field}Focused`]: focused,
          };
        }
        return item;
      }),
    }));
  };

  const handleFocusChange = (id, index, field, focused) => {
    if (index !== undefined) {
      setListCoordinate(
        listCoordinate.map((item) => {
          if (item.id === id) {
            const updatedParticipants = [...item.participants];
            updatedParticipants[index] = {
              ...updatedParticipants[index],
              [`${field}Focused`]: focused,
            };
            return {...item, participants: updatedParticipants};
          }
          return item;
        }),
      );
    }
  };

  const handleSocietyFocusChange = (id, index, field, focused) => {
    setListCoordinate(
      listCoordinate.map((item) => {
        if (item.id === id) {
          const updatedSocietyInfo = {
            ...item.societyInfo,
            [`${field}Focused`]: focused,
          };
          return {...item, societyInfo: updatedSocietyInfo};
        }
        return item;
      }),
    );
  };

  const validateFields = () => {
    const newParticipants = listCoordinate?.map((lc, listIndex) => {
      // Validate participants
      const participantsData = lc.participants.map(
        (participant, participantIndex) => {
          let isNotEmpty = Object.keys(participant)
            .filter((key) => fieldsOfCoordinates.includes(key))
            .some((key) => Boolean(participant[key]));

          if (participantIndex === 0 || (participantIndex > 0 && isNotEmpty)) {
            // Create array to store field errors with their indices
            const participantErrorsArray = Object.keys(participant)
              .filter((field) => {
                if (fieldsOfCoordinates.includes(field)) {
                  if (field === 'email' && !isEmail(participant[field].trim()))
                    return true;
                  if (
                    (field === 'firstName' || field === 'lastName') &&
                    !participant[field].trim()
                  )
                    return true;
                  if (
                    checkIfRequired(field, event.participantInfo) &&
                    !participant[field].trim()
                  )
                    return true;
                  return false;
                }
              })
              .map((field) => ({
                field,
                listIndex,
                participantIndex,
                error: true,
              }));

            // Convert array to object with field keys and error info
            const participantErrors = Object.fromEntries(
              participantErrorsArray.map((error) => [
                error.field,
                {
                  hasError: true,
                  listIndex: error.listIndex,
                  participantIndex: error.participantIndex,
                },
              ]),
            );

            console.log(
              'participantErrors for participant',
              participantIndex,
              'in list',
              listIndex,
              participantErrors,
            );
            return {...participant, errors: participantErrors};
          } else {
            return {...participant, errors: {}};
          }
        },
      );

      const shouldValidateSocietyInfo = lc.isMultiple && event?.isCompanyInfosRequired;
      

      // Society info validation with indices
      const societyInfoErrors = shouldValidateSocietyInfo
        ? Object.fromEntries(
            Object.keys(lc.societyInfo)
              .filter(
                (field) =>
                  fieldsSociety.includes(field) && !lc.societyInfo[field],
              )
              .map((field) => [
                field,
                {
                  hasError: true,
                  listIndex,
                  type: 'society',
                },
              ]),
          )
        : {};

      const societyInfoData = lc.societyInfo
        ? {...lc.societyInfo, errors: societyInfoErrors}
        : null;

      return {
        ...lc,
        participants: participantsData,
        societyInfo: societyInfoData,
      };
    });

    setListCoordinate(newParticipants);

    // Check if there are any errors
    const hasParticipantErrors = newParticipants.some((p) =>
      p.participants.some(
        (participant) => Object.keys(participant.errors).length > 0,
      ),
    );

    
    const hasSocietyErrors = newParticipants.some(
      (p) => 
        p.isMultiple && 
        event?.isCompanyInfosRequired && 
        p.societyInfo && 
        Object.keys(p.societyInfo.errors).length > 0
    );

    return !hasParticipantErrors && !hasSocietyErrors;
  };

  const validetInputInvoice = () => {
    if (!showInvoices) {
      return true;
    }
    if (invoicesInfo?.invoiceInfoField && event?.isInvoiceRequred) {
      const updatedInvoiceInfo = invoicesInfo.invoiceInfoField.map(
        (invoice, index) => {
          let isNotEmpty = Object.keys(invoice)
            .filter((key) => filedVoice.includes(key))
            .some((key) => Boolean(invoice[key]));

          if (index === 0 || (index > 0 && isNotEmpty)) {
            const invoiceErrors = Object.fromEntries(
              Object.keys(invoice)
                .filter((field) => {
                  if (filedVoice.includes(field)) {
                    if (
                      checkIfRequired(field, event.invoiceInfo) &&
                      !invoice[field].trim()
                    ) {
                      return true;
                    }
                    return false;
                  }
                  return false;
                })
                .map((field) => [field, true]),
            );

            return {...invoice, errors: invoiceErrors};
          } else {
            return {...invoice, errors: {}};
          }
        },
      );

      setInvoiceInfo((prevState) => ({
        ...prevState,
        invoiceInfoField: updatedInvoiceInfo,
      }));

      const allValid = updatedInvoiceInfo.every(
        (invoice) => Object.keys(invoice.errors).length === 0,
      );

      return allValid;
    } else {
      return true;
    }
  };

  const filterFields = (obj, fieldArray) => {
    const result = {};
    for (const key of fieldArray) {
      if (obj.hasOwnProperty(key)) {
        result[key] = typeof obj[key] === 'string' ? obj[key].trim() : obj[key];
      }
    }
    return result;
  };

  const calculateFinalPrice = (product, promoCodeDiscount) => {
    if (product.isFree) return 0;

    const applicablePrice =
      product.includePlatformFees && product.priceAfterFeePlatform
        ? product.priceAfterFeePlatform
        : product.price;

    if (!promoCodeDiscount || !product.hasDiscount) return applicablePrice;

    const {quantity, promoCode} = product;
    const maxDiscountTickets = promoCode?.[0]?.maxSoldTicket;

    if (product.includePlatformFees && product.priceAfterFeePlatform) {
      if (product?.promoCode && promoCodeDiscount) {
        const promo = product?.promoCode?.[0];
        const discountAmount = promo?.discount || 0;

        if (promo?.mode === 'rate') {
          const discountedTickets = Math.min(quantity, maxDiscountTickets);

          const normalTickets = Math.max(0, quantity - discountedTickets);

          const totalDiscountPrice =
            product.priceAfterDiscount * discountedTickets;
          const totalRegularPrice =
            product.priceAfterFeePlatform * normalTickets;

          return (totalDiscountPrice + totalRegularPrice) / quantity;
        } else if (promo?.mode === 'amount') {
          const discountedPrice =
            product.priceAfterFeePlatform - discountAmount;

          const discountedTickets = Math.min(quantity, maxDiscountTickets);

          const normalTickets = Math.max(0, quantity - discountedTickets);

          const totalDiscountPrice =
            Math.max(0, discountedPrice) * discountedTickets;
          const totalRegularPrice =
            product.priceAfterFeePlatform * normalTickets;

          return (totalDiscountPrice + totalRegularPrice) / quantity;
        }
      }
    }

    if (quantity > maxDiscountTickets) {
      return (
        (product.priceAfterDiscount * maxDiscountTickets +
          product.price * (quantity - maxDiscountTickets)) /
        quantity
      );
    }

    return product.priceAfterDiscount;
  };

  const calculateCompositionPrice = (product) => {
    if (!product.iscomposite || !product.composition) return 0;
    return product.composition.reduce((sum, item) => sum + item.price, 0);
  };
  
  const createProductFields = (
    product,
    promoCodeDiscount,
    event,
    listCoordinate,
  ) => {
    const finalPrice = calculateFinalPrice(product, promoCodeDiscount);
    const compositionPrice = calculateCompositionPrice(product);
    const totalPrice = finalPrice + compositionPrice;

    const fields = {
      quantity: product.quantity,
      name: product.name,
      photo: product.photo,
      id: product.id,
      isFree: product.isFree,
      isalcoholic: product.isalcoholic,
      tier: product.tier,
      eventid: event.id,
      categoryid: product.categoryid,
      iscomposite: product.iscomposite,
      originalPrice: product.price,
      composition: product.composition ? JSON.stringify(product.composition) : '',
      price: Number(totalPrice.toFixed(2)),
      total: product.MontantChoixAmout
        ? Number((product.MontantChoixAmout + compositionPrice).toFixed(2))
        : Number((totalPrice * product.quantity).toFixed(2)),
    };
  
    // Add optional fields
    if (product.isAccessTimeThreshold) {
      fields.accessTimeThreshold = product.accessTimeThreshold;
      fields.isAccessTimeThreshold = true;
    }

    if (product.isAccessTimeLimit) {
      fields.accessTimeLimit = product.accessTimeLimit;
      fields.isAccessTimeLimit = true;
    }

    if (product.includePlatformFees) {
      fields.includePlatformFees = true;
      fields.platformFee = product.platformFee;
    }

    if (product.isTvaActive) {
      fields.isTvaActive = true;
      fields.tvaRate = product.tvaRate;
      fields.priceHt = product.priceHt;
    }

    if (product.products?.length > 0) {
      fields.products = product.products;
      fields.description = product.products.map((p) => p.product).join(', ');
    }

    // Add attendees if multiple
    const attendees = listCoordinate.filter(
      (lc) => lc.idProduct === product.id,
    );
    const isMultiple = listCoordinate.some((lc) => lc.isMultiple);

    if (isMultiple) {
      fields.attendees = processAttendees(attendees);
    }

    return Object.fromEntries(
      Object.entries(fields).filter(([_, value]) => value !== undefined),
    );
  };

  const processAttendees = (attendees) => {
    return attendees.map((att) => ({
      participants: att.participants
        .map((p) => filterFields(p, fieldsOfCoordinates))
        .filter((participant, index) => {
          if (index === 0) return true;
          return Object.keys(participant).some((key) =>
            Boolean(participant[key]),
          );
        }),
      index: att.order,
      companyinfos: att.isMultiple
        ? filterFields(att.societyInfo, fieldsSociety)
        : undefined,
      hasMultiPersonsTickets: att.isMultiple,
    }));
  };

  const createOrderOptions = (
    userinfos,
    items,
    status,
    options,
    invoiceInfo,
  ) => {
    const version = Constants.manifest.version;
    const ownerid = event?.ownerid || '';

    // Initialize the base order options
    const orderOptions = {
      email: userinfos.email,
      items,
      status,
      clientid: user.uid, // assuming `user` was meant to be `userinfos`
      displayName: userinfos.name,
      appversion: version,
      pickmode: 'inhouse',
      origin: 'webapp',
      userinfos,
      paymentinfos: '',
      event: {
        id: event.id,
        title: event.title,
        coverImage: event.coverImage,
        address: event.address,
        startDate: event.startDate,
        ownerid: event?.ownerid || '',
      },
      ownerid,
      ...(options.promoCodeDiscount && {
        promocode: {
          promoCodeValue: options.promoCodeDiscount.promoCodeValue,
          promoDiscount: options.promoCodeDiscount.discount,
          mode: options.promoCodeDiscount.mode,
        },
      }),
      ...(options.goid && {goid: options.goid}),
      ...(referralID && {referralid: referralID}),
      ...(cart?.accessRestriction && {
        accessRestriction: cart.accessRestriction,
      }),
    };

    console.log(invoicesInfo, showInvoices, 'invoicesInfo');
    // Add invoice information if it exists
    if (invoiceInfo?.hasInvoiceInfo) {
      orderOptions.hasInvoiceinfos = true;
      orderOptions.fieldsInfoVoices = invoiceInfo.invoiceInfoField
        .map((field) => {
          const filteredField = Object.keys(field)
            .filter((key) => {
              return (
                field[key] !== '' &&
                !key.endsWith('Focused') &&
                key !== 'errors'
              );
            })
            .reduce((obj, key) => {
              obj[key] = field[key];
              return obj;
            }, {});

          return filteredField;
        })
        .filter((field) => Object.keys(field).length > 0);
    }

    return orderOptions;
  };

  const handleFinalizePress = async () => {
    try {
      // Validate inputs
      const fieldsValid = validateFields();
      const invoiceValid = validetInputInvoice();

      if (!fieldsValid || !invoiceValid) {
        setError((prev) => ({
          ...prev,
          errorFields: !fieldsValid,
          errorVoice: !invoiceValid,
        }));
        return;
      }
      console.log('invoiceValid', invoiceValid);
      console.log(invoicesInfo);
      // Set hasInvoiceInfo based on validation results and checkbox state
      const finalInvoiceInfo =
        showInvoices && invoiceValid
          ? {
              ...invoicesInfo,
              hasInvoiceInfo: true,
              invoiceInfoField: invoicesInfo.invoiceInfoField,
            }
          : null;

      const {options} = props.route.params;
      const {products, shouldCreateOrder} = options;

      // Process user information
      const isMultiple = listCoordinate.some((lc) => lc.isMultiple);
      let userinfos = {name: '', email: ''};

      if (!isMultiple && listCoordinate.length > 0) {
        userinfos = listCoordinate[0].participants.map((att) =>
          filterFields(att, fieldsOfCoordinates),
        )[0];
        userinfos.name = userinfos.firstName + ' ' + userinfos.lastName;
        await db
          .collection('users')
          .doc(user.uid)
          .set(userinfos, {merge: true});
      }

      // Process products and create order
      const tier = products.some((p) => p.tier === 'ticket')
        ? 'ticket'
        : 'food';
      const items = products.map((product) =>
        createProductFields(
          product,
          options.promoCodeDiscount,
          event,
          listCoordinate,
        ),
      );
      console.log('items', items);

      // Handle order creation
      let orderId = '';
      if (shouldCreateOrder) {
        setCheckoutLoading(true);
        // console.log('Creating order...');
        // console.log('options', options);

        orderId = await createOrder(
          tier,
          items,
          userinfos,
          options,
          finalInvoiceInfo,
        );
        console.log('orderId', orderId);
        if (!orderId) {
          navigation.navigate('CheckoutFailed');
          setCheckoutLoading(false);
          return;
        }
      }

      // Handle payment
      const reducedPrices = createReducedPrices(
        products,
        options.promoCodeDiscount
      );
      console.log('reducedPrices', reducedPrices);

      if (reducedPrices.length > 0) {
        await handleStripePayment(reducedPrices, tier, orderId);
      } else {
        handleFreeOrder(tier);
      }
    } catch (err) {
      console.error('Checkout error:', err);
      navigation.navigate('CheckoutFailed');
      setCheckoutLoading(false);
    }
  };

  const createReducedPrices = (products, promoCodeDiscount) => {
    return products.reduce((filtered, product) => {
      if (product.isFree) return filtered;

      const compositionPrice = calculateCompositionPrice(product);
      const finalPrice = calculateFinalPrice(product, promoCodeDiscount);
      const totalPrice = finalPrice + compositionPrice;

      filtered.push({
        quantity: product.quantity,
        price_data: {
          currency: 'eur',
          product_data: {
            name: product.name,
            images: product.photo ? [product.photo] : [],
          },
          unit_amount: Number((totalPrice * 100).toFixed(0)),
        },
      });

      return filtered;
    }, []);
  };

  const handleStripePayment = async (reducedPrices, tier, orderId) => {
    const stripe = await stripePromise;
    const checkoutSession = await createcheckoutsession(
      reducedPrices,
      tier,
      orderId,
    );
    await stripe.redirectToCheckout({
      sessionId: checkoutSession.data.session.id,
    });
  };

  const handleFreeOrder = (tier) => {
    emptyCart();
    setCheckoutLoading(false);
    navigation.navigate('CheckoutSuccess', tier);
  };

  const createOrder = async (tier, items, userinfos, options, invoiceInfo) => {
    let status = tier === 'ticket' ? 'confirmed' : 'ordered';
    if (options.products.some((product) => !product.isFree)) {
      status = 'preordered';
    }
    const orderOptions = createOrderOptions(
      userinfos,
      items,
      status,
      options,
      invoiceInfo,
    );
    console.log('orderOptions', orderOptions);
    if (tier === 'ticket') {
      return await createTicketOrder(orderOptions);
    }
    return await createFoodOrder(orderOptions);
  };

  const createTicketOrder = async (options) => {
    const precreateReservtion = httpsCallable(FUNCTIONS, 'precreateReservtion');
    const result = await precreateReservtion(options);

    if (result.data?.haserror) return null;

    const reservationId = result.data?.reservation;
    if (reservationId) setReferral('');
    return reservationId;
  };

  const createFoodOrder = async (options) => {
    const preCreateOrder = httpsCallable(FUNCTIONS, 'precreateOrder');
    return await preCreateOrder({...options, storeid: props.store.id});
  };

  const handleFinalizePress1 = async () => {
    const isFieldsValid = validateFields();

    const isInvoiceValid = validetInputInvoice();
    setError((prevError) => ({
      ...prevError,
      errorFields: !isFieldsValid,
      errorVoice: !isInvoiceValid,
    }));
    if (isFieldsValid && isInvoiceValid) {
      // setError(false);
      try {
        let isMultiple = listCoordinate.some((lc) => lc.isMultiple);
        let userinfos = {
          name: '',
          email: '',
        };

        if (!isMultiple) {
          userinfos = listCoordinate[0].participants.map((att) =>
            filterFields(att, fieldsOfCoordinates),
          )[0];
          await db
            .collection('users')
            .doc(user.uid)
            .set(userinfos, {merge: true});
        }

        const {options} = props.route.params;

        var tier = 'food';

        const {products, promoCodeDiscount, shouldCreateOrder, goid} = options;

        const ownerid = products[0]?.ownerid;
        const items = products.map((product) => {
          tier = product.tier === 'ticket' ? 'ticket' : 'food';
          const applicablePrice =
            product.includePlatformFees && product.priceAfterFeePlatform
              ? product.priceAfterFeePlatform
              : product.price;
          console.log('applicablePrice', applicablePrice);
          var compositionprice = product.iscomposite
            ? product.composition.reduce(function (a, b) {
                console.log('aa', a, 'bb', b);
                return a + b.price;
              }, 0)
            : 0;
          // let finalPrice = product.isFree
          //   ? 0
          //   : promoCodeDiscount && product.hasDiscount
          //   ? product.priceAfterDiscount
          //   : product.price;

          let finalPrice = product.isFree
            ? 0
            : promoCodeDiscount && product.hasDiscount
            ? product?.quantity > product?.promoCode[0]?.maxSoldTicket
              ? (product.priceAfterDiscount *
                  product?.promoCode[0]?.maxSoldTicket +
                  product.priceAfterDiscount *
                    (product.quantity - product?.promoCode[0]?.maxSoldTicket)) /
                product.quantity
              : product.priceAfterDiscount
            : applicablePrice;
          let fields = {
            quantity: product.quantity,
            name: product.name,
            photo: product.photo,
            id: product.id,
            isFree: product.isFree,
            isalcoholic: product.isalcoholic,
            tier: product.tier,
            eventid: event.id,
            categoryid: product.categoryid,
            iscomposite: product.iscomposite,
            originalPrice: product.price,
            composition:
              product.composition !== undefined
                ? JSON.stringify(product.composition)
                : '',
            price: Number((finalPrice + compositionprice).toFixed(2)),
            total: product.MontantChoixAmout
              ? Number(
                  (product.MontantChoixAmout + compositionprice).toFixed(2),
                )
              : Number(
                  ((finalPrice + compositionprice) * product.quantity).toFixed(
                    2,
                  ),
                ),
          };

          let attendees = listCoordinate.filter(
            (lc) => lc.idProduct === product.id,
          );
          let isMultiple = listCoordinate.some((lc) => lc.isMultiple);
          if (isMultiple) {
            fields.attendees = attendees.map((att) => {
              let obj = {};
              obj.participants = att.participants
                .map((att) => filterFields(att, fieldsOfCoordinates))
                .filter((participant, index) => {
                  if (index === 0) return true;
                  else {
                    let isEmpty = Object.keys(participant).every(
                      (key) => !Boolean(participant[key]),
                    );
                    if (isEmpty) return false;
                    else return true;
                  }
                });
              obj.index = att.order;
              if (att.isMultiple) {
                obj.companyinfos = filterFields(att.societyInfo, fieldsSociety);
              }
              obj.hasMultiPersonsTickets = att.isMultiple;
              return obj;
            });
          }
          if (product.isAccessTimeThreshold) {
            fields.accessTimeThreshold = product.accessTimeThreshold;
            fields.isAccessTimeThreshold = product.isAccessTimeThreshold;
          }
          if (product.isAccessTimeLimit) {
            fields.accessTimeLimit = product.accessTimeLimit;
            fields.isAccessTimeLimit = product.isAccessTimeLimit;
          }
          if (product.includePlatformFees) {
            fields.includePlatformFees = product.includePlatformFees;
            fields.platformFee = product.platformFee;
          }
          if (product.isTvaActive) {
            fields.isTvaActive = product.isTvaActive;
            fields.tvaRate = product.tvaRate;
            fields.priceHt = product.priceHt;
          }
          if (product?.includePlatformFees) {
            fields.includePlatformFees = product.includePlatformFees;
            fields.platformFee = product.platformFee;
          }

          if (product?.products?.length > 0) {
            fields.products = product.products;
            fields.description = product.products
              ?.map((p) => `${p.product}`)
              .join(', ');
          }
          const filteredValues = {};

          for (const key in fields) {
            if (fields[key] !== undefined) {
              filteredValues[key] = fields[key];
            }
          }
          return filteredValues;
        });

        const {store} = props;
        const version = Constants.manifest.version;

        var reducedPrices = products.reduce(function (filtered, product) {
          var compositionprice = product.iscomposite
            ? product.composition.reduce(function (a, b) {
                return a + b.price;
              }, 0)
            : 0;
          const applicablePrice =
            product.includePlatformFees && product.priceAfterFeePlatform
              ? product.priceAfterFeePlatform
              : product.price;
          if (!product.isFree) {
            let finalPrice = product.isFree
              ? 0
              : promoCodeDiscount && product.hasDiscount
              ? product?.quantity > product?.promoCode[0]?.maxSoldTicket
                ? (product.priceAfterDiscount *
                    product?.promoCode[0]?.maxSoldTicket +
                    product.priceAfterDiscount.toFixed(2) *
                      (product.quantity -
                        product?.promoCode[0]?.maxSoldTicket)) /
                  product.quantity
                : product.priceAfterDiscount
              : applicablePrice;
            filtered.push({
              quantity: product.quantity,
              price_data: {
                currency: 'eur',
                product_data: {
                  name: product.name,
                  images: product.photo ? [product.photo] : [],
                },
                unit_amount: Number(
                  ((finalPrice + compositionprice) * 100).toFixed(0),
                ),
              },
            });
          }
          return filtered;
        }, []);

        console.log(reducedPrices);
        console.log(items);

        var status = 'preordered';
        if (reducedPrices.length == 0) {
          status = tier === 'ticket' ? 'confirmed' : 'ordered';
        }
        let newid = '';
        if (shouldCreateOrder) {
          setCheckoutLoading(true);
          const options = {
            email: userinfos.email,
            items: items,
            status: status,
            clientid: user.uid,
            displayName: userinfos.name,
            appversion: version,
            pickmode: 'inhouse',
            origin: 'webapp',
            userinfos: userinfos,
            paymentinfos: '',
            ownerid: ownerid,
          };
          if (promoCodeDiscount) {
            options.promocode = {
              promoCodeValue: promoCodeDiscount.promoCodeValue,
              promoDiscount: promoCodeDiscount.discount,
              mode: promoCodeDiscount.mode,
            };
          }

          if (invoicesInfo?.hasInvoiceInfo) {
            options.hasInvoiceinfos = true;
            options.fieldsInfoVoices = invoicesInfo?.invoiceInfoField
              .map((field) => {
                const filteredField = Object.keys(field)
                  .filter((key) => {
                    // Exclure les champs vides et les champs qui ne sont pas des inputs remplis
                    return (
                      field[key] !== '' &&
                      !key.endsWith('Focused') &&
                      key !== 'errors'
                    );
                  })
                  .reduce((obj, key) => {
                    obj[key] = field[key];
                    return obj;
                  }, {});

                return filteredField;
              })
              .filter((field) => Object.keys(field).length > 0);
          }
          // console.log('options.fieldsInfoVoices', options.fieldsInfoVoices);
          if (goid) {
            options.goid = goid;
          }
          if (referralID !== '') {
            options.referralid = referralID;
          }
          if (tier === 'ticket') {
            options.event = {
              id: event.id,
              title: event.title,
              coverImage: event.coverImage,
              address: event.address,
              startDate: event.startDate,
              ownerid: event?.ownerid || '',
            };

            try {
              const precreateReservtion = httpsCallable(
                FUNCTIONS,
                'precreateReservtion',
              );
              const result = await precreateReservtion(options);
              if (!result.data?.haserror) {
                newid = result.data?.reservation;
              }
              if (newid) {
                setReferral('');
              }
              if (!newid) {
                navigation.navigate('CheckoutFailed');
                setCheckoutLoading(false);
                return;
              }
            } catch (error) {
              console.log(error);
              navigation.navigate('CheckoutFailed');
              setCheckoutLoading(false);
              return;
            }
          } else {
            /* i fix also error pour preCreateOrder  */
            options.storeid = store.id;
            // const preCreateOrder = httpsCallable(FUNCTIONS, 'precreateOrder');
            // newid = await preCreateOrder(options);
            try {
              const preCreateOrder = httpsCallable(FUNCTIONS, 'precreateOrder');

              newid = await preCreateOrder(options);
            } catch (error) {
              console.error('error in precreateOrder', error);
              navigation.navigate('CheckoutFailed');
              setCheckoutLoading(false);
              return;
            }
          }
          setCheckoutLoading(true);
        }

        if (reducedPrices.length > 0) {
          const stripe = await stripePromise;
          setCheckoutLoading(true);
          const checkoutsession = await createcheckoutsession(
            reducedPrices,
            tier,
            newid,
          );
          const result = await stripe.redirectToCheckout({
            sessionId: checkoutsession.data.session.id,
          });
        } else {
          //CheckoutSuccess
          emptyCart();
          setCheckoutLoading(false);
          navigation.navigate('CheckoutSuccess', tier);
        }
      } catch (err) {
        console.log('finalise error', err);
        navigation.navigate('CheckoutFailed');
      }
    } else {
      // setError(true);
      return;
    }
  };

  const createcheckoutsession = async (prices, tier, newid) => {
    const {store} = props;
    const {params} = props.route;
    const dbuser = await db.collection('users').doc(user.uid).get();
    const docuser = dbuser.data();

    let options = {
      prices: prices,
      tier: tier,
      newid: newid,
      customer: docuser.customerId,
      clientid: user.uid,
      //paymentMethods: ['card', 'paypal'],
      // savepm: this.state.isSelected,
      accountid: store.accountid,
      mode: 'payment',
    };
    const {shouldCreateOrder, goid} = params.options;
    if (goid) {
      options.goid = goid;
      options.shouldCreateOrder = shouldCreateOrder;
    }
    if (goid && !shouldCreateOrder) {
      options.successurl = 'Cart';
    }
    const callCreateCheckoutSession = httpsCallable(
      FUNCTIONS,
      'createCheckoutSession',
    );
    const result = await callCreateCheckoutSession(options);
    return result;
  };

  const fieldsOfCoordinates = useMemo(() => {
    let baseField = ['email', 'firstName', 'lastName'];
    if (event?.participantInfo)
      event?.participantInfo?.map((field) => baseField.push(field.field));
    return baseField;
  }, [event]);

  const filedVoice = useMemo(() => {
    let baseFieldVoice = [];
    if (event?.invoiceInfo)
      event?.invoiceInfo?.map((field) => baseFieldVoice.push(field.field));
    return baseFieldVoice;
  }, [event]);
  const fieldsSociety = ['name', 'address', 'codePostal', 'siret'];

  const checkIfInclude = useCallback((fieldName, participantInfo) => {
    return participantInfo?.some((field) => field.field === fieldName);
  }, []);
  const checkIfRequired = useCallback((fieldName, participantInfo) => {
    return participantInfo?.some(
      (field) => field.field === fieldName && field.required,
    );
  }, []);
  const checkIfRequiredInvoices = useCallback((fieldName, invoiceInfo) => {
    return invoiceInfo?.some(
      (field) => field.field === fieldName && field.required,
    );
  }, []);
  const {t} = useTranslation();
  // t('AllServicesData.Événements')
  const genders = [
    /*'Male'*/ t('TicketCoordinatesBPage.Mâle'),
    /*'Female'*/ t('TicketCoordinatesBPage.Femelle'),
    t('TicketCoordinatesBPage.Autre'),
  ];

  const previousSlide = () => {
    if (swiperRef.current) {
      const prevIndex = currentListIndex - 1;
      if (prevIndex >= 0) {
        swiperRef.current.scrollBy(-1, true);
        setCurrentListIndex(prevIndex);
      }
    }
  };

  const nextSlide = () => {
    if (swiperRef.current) {
      const nextIndex = currentListIndex + 1;
      if (nextIndex < listCoordinate.length) {
        swiperRef.current.scrollBy(1, true);
        setCurrentListIndex(nextIndex);
      }
    }
  };

  const handleIndexChanged = (index) => {
    setCurrentListIndex(index);
  };

  const renderEmailInput = (participant, lc, index, key) => {
    // Check if this is the first participant and we have an access restriction
    const isFirstParticipant = index === 0;
    const hasAccessRestriction = cart?.accessRestriction?.verifiedEmail;
    const isAccessRestrictedEmail = isFirstParticipant && hasAccessRestriction;

    // If this is first participant with access restriction, use that email
    const emailValue = isAccessRestrictedEmail
      ? cart.accessRestriction.verifiedEmail
      : participant[key];

    return (
      <View style={{position: 'relative', marginBottom: 15}}>
        <SubheadingA style={styles.label}>
          {t(`${key}`)}
          {checkIfRequired(key, event.participantInfo) && (
            <Text style={{color: Colors.red}}> *</Text>
          )}
          {isAccessRestrictedEmail && (
            <BodyA style={styles.verifiedLabel}>
              {t('TicketCoordinatesBPage.Verified Access')}
            </BodyA>
          )}
        </SubheadingA>
        <TextInput
          placeholder="••••••••••••••••••••••••••••"
          keyboardType="email-address"
          placeholderTextColor={Colors.lightGray}
          maxLength={50}
          style={[
            styles.textInput,
            {paddingLeft: 44},
            participant?.errors[key] && styles.errorBorder,
            isAccessRestrictedEmail && styles.verifiedInput,
          ]}
          value={emailValue}
          onChangeText={(text) => handleChange(lc.id, index, key, text)}
          onFocus={() => handleFocusChange(lc.id, index, key, true)}
          onBlur={() => handleFocusChange(lc.id, index, key, false)}
          isFocused={participant[`${key}Focused`]}
          editable={!isAccessRestrictedEmail} // Disable editing for verified email
        />
        <EmailSVG style={styles.inputLeftIcon} />
        {isAccessRestrictedEmail && (
          <View style={styles.verifiedIcon}>
            <CheckboxSVG width={16} height={16} fill={Colors.darkGreen} />
          </View>
        )}
      </View>
    );
  };

  if (checkoutLoading) {
    return (
      <View style={{paddingTop: 50}}>
        <ActivityIndicator size="large" color="green" />
      </View>
    );
  }

  return (
    <SafeAreaView style={styles.screenContainer}>
      <StatusBar backgroundColor={Colors.white} barStyle="dark-content" />

      <ScrollView
        showsVerticalScrollIndicator={false}
        style={styles.scrollView}>
        <View style={styles.imageContainer}>
          <SmartphoneSVG style={styles.image} />
        </View>

        <View style={styles.container}>
          <View style={styles.firstRow}>
            <TitleA>
              {' '}
              {t(
                'TicketCoordinatesBPage.Veuillez entrer vos coordonnées pour les billets',
              )}
            </TitleA>
          </View>
          <Swiper
            ref={swiperRef}
            onIndexChanged={handleIndexChanged}  // Add this prop
            // loop
            loop={false}
            showsPagination={false}
            scrollEnabled={true}
            height="100%"
            style={{
              height: 'auto',
            }}>
            {listCoordinate.map((lc, index) => (
              <View
                // showsVerticalScrollIndicator={false}
                style={styles.scrollView}
                key={index}>
                {listCoordinate.length > 1 && (
                  <View style={styles.QRCodeSection}>
                    <View style={styles.ticketIndex}>
                      <TitleA>{` ${index + 1}/${
                        listCoordinate.length
                      }`}</TitleA>
                    </View>
                    <TouchableItem
                      style={{
                        position: 'absolute',
                        // top: 172,
                        left: 30,
                      }}
                      onPress={previousSlide}>
                      <BackArrowSVG
                        style={{height: 20, width: 31, padding: 7}}
                        fill={Colors.lightGray}
                      />
                    </TouchableItem>
                    <TouchableItem
                      style={{
                        position: 'absolute',
                        // top: 172,
                        right: 30,
                      }}
                      onPress={nextSlide}>
                      <BackArrowSVG
                        style={{
                          transform: [{scaleX: -1}],
                          height: 20,
                          width: 31,
                          padding: 7,
                        }}
                        fill={Colors.lightGray}
                      />
                    </TouchableItem>
                  </View>
                )}
                <View>
                  {lc.isMultiple && event?.isCompanyInfosRequired && (
                    <>
                      <TitleA style={{marginBottom: 5, marginTop: 5}}>
                        {t('companyInfo')}
                      </TitleA>
                      <View style={styles.inputsContainer}>
                        {Object.keys(lc.societyInfo)
                          .filter((v) => fieldsSociety.includes(v))
                          .map((key) => (
                            <View style={{marginBottom: 15}}>
                              <SubheadingA style={styles.label}>
                                {t(`companyTranslation.${key}`)}
                                <Text style={{color: Colors.red}}> *</Text>
                              </SubheadingA>
                              <TextInput
                                placeholder="••••••••••••••••••••••••••••"
                                placeholderTextColor={Colors.lightGray}
                                maxLength={50}
                                style={[
                                  styles.textInput,
                                  lc.societyInfo?.errors[key] &&
                                    styles.errorBorder,
                                ]}
                                value={lc.societyInfo[key]}
                                onChangeText={(text) =>
                                  handleSocietyChange(lc.id, key, text)
                                }
                                onFocus={() =>
                                  handleSocietyFocusChange(
                                    lc.id,
                                    undefined,
                                    key,
                                    true,
                                  )
                                }
                                onBlur={() =>
                                  handleSocietyFocusChange(
                                    lc.id,
                                    undefined,
                                    key,
                                    false,
                                  )
                                }
                                isFocused={lc.societyInfo[`${key}Focused`]}
                              />
                            </View>
                          ))}
                      </View>
                    </>
                  )}
                  {lc.participants.map((participant, index) => (
                    <ParticipantForm
                      key={index}
                      participant={participant}
                      participantIndex={index}
                      lc={lc}
                      fieldsOfCoordinates={fieldsOfCoordinates}
                      event={event}
                      handleChange={handleChange}
                      handleFocusChange={handleFocusChange}
                      cart={cart}
                      styles={styles}
                    />
                  ))}
                </View>
              </View>
            ))}
          </Swiper>
          <View style={{marginBottom: showInvoices ? '8px' : '15px'}}>
            {invoiceAvailable && (
              <>
                <View
                  style={{
                    flexDirection: 'row',
                    gap: 5,
                    alignItems: 'center',
                  }}>
                  <Checkbox
                    status={showInvoices ? 'checked' : 'unchecked'}
                    onPress={handleCheckboxChange}
                    label="Afficher les informations de facture"
                  />
                  <TitleA> {t('FactureDetails')} </TitleA>
                </View>
                {showInvoices && (
                  <View style={[styles.inputsContainer, {marginTop: '8px'}]}>
                    {invoicesInfo?.invoiceInfoField.map((invoice, j) => (
                      <View key={j}>
                        {Object.keys(invoice)
                          .filter((v) => filedVoice.includes(v))
                          .map((key) => (
                            <View key={key} style={{marginBottom: 15}}>
                              <SubheadingA style={styles.label}>
                                {t(`${key}`)}
                                {checkIfRequiredInvoices(
                                  key,
                                  event.invoiceInfo,
                                ) && (
                                  <Text style={{color: Colors.red}}> *</Text>
                                )}
                              </SubheadingA>
                              <TextInput
                                placeholder="••••••••••••••••••••••••••••"
                                placeholderTextColor={Colors.lightGray}
                                maxLength={50}
                                style={[
                                  styles.textInput,
                                  invoice?.errors?.[key] && styles.errorBorder,
                                ]}
                                value={invoice[key]}
                                onChangeText={(text) =>
                                  handelInvoiceField(j, key, text)
                                }
                                onFocus={() =>
                                  handelFocusInvoiceField(j, key, true)
                                }
                                onBlur={() =>
                                  handelFocusInvoiceField(j, key, false)
                                }
                                isFocused={invoice[`${key}Focused`]}
                              />
                            </View>
                          ))}
                      </View>
                    ))}
                  </View>
                )}
              </>
            )}
          </View>

          <ErrorDisplay
            listCoordinate={listCoordinate}
            error={error}
            currentListIndex={currentListIndex}
            showInvoices={showInvoices}
          />

          <View style={styles.buttonContainer}>
            <ButtonA onPress={handleFinalizePress} disabled={isDisabled}>
              <CartSVG
                style={{
                  width: 18,
                  height: 18,
                  marginBottom: -2,
                  marginRight: 10,
                }}
                stroke={Colors.background}
              />
              {t('TicketCoordinatesBPage.Finalisation du paiement')}
            </ButtonA>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const mapStateToProps = (state) => {
  const {cart, store, event} = state;
  return {cart, store, event};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setReferral,
      emptyCart,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(TicketCoordinatesB);
