import {
  SafeAreaView,
  ScrollView,
  StatusBar,
  Text,
  View,
  TextInput,
} from 'react-native';
import React, {useState, useEffect} from 'react';
import styles from './CommandeReviewsStyles';
import Colors from '../../theme/colors';
import TitleA from '../../components/titleA/TitleA';
import getEnvVars from '../../config';
import StartFillNoteSVG from '../../components/svgs/StartFillNoteSVG';
import CheckedNoteSVG from '../../components/svgs/CheckedNoteSVG';

import ButtonA from '../../components/buttonA/ButtonA';
import Constants from 'expo-constants';
import TitleBigA from '../../components/titleBigA/TitleBigA';
import axios from 'axios';

import {AuthContext} from '../../contexts/context';
import BodyA from '../../components/bodyA/BodyA';
const CommandeNote = () => {
  const {user} = React.useContext(AuthContext);
  const GOOGLE_API_KEY = 'AIzaSyDmsSuw9FA61jQhYJKNLa1BiGl_ubliCxc';
  const {expo} = getEnvVars();
  const locationId = 'ChIJ30RjoMFx5kcRNE-UKUAakBQ';
  {
    /*  ID client  from google cloud of aggouni accound  */
  }
  const IDclient =
    '946898901720-n8ldjsnraleadg49om8k1to1v2jg1sko.apps.googleusercontent.com';
  const address = 'Brocante, 7 Rue des Patriarches, 75005 Paris';
  const [placeId, setPlaceId] = React.useState(null);
  const [locations, setLocations] = useState([]);
  const [locationIds, setLocationId] = useState(null);
  const [ratings, setRatings] = React.useState({
    satisfait: {checked: [false, false, false, false, false], rating: 0},
    gout: {checked: [false, false, false, false, false], rating: 0},
    portions: {checked: [false, false, false, false, false], rating: 0},
    packaging: {checked: [false, false, false, false, false], rating: 0},
  });
  const handleStarPress = (category, index) => {
    const newChecked = ratings[category].checked.map((_, i) => {
      return i <= index ? true : false;
    });
    setRatings((prev) => ({
      ...prev,
      [category]: {checked: newChecked, rating: index + 1},
    }));
  };
  const [checkedText, setCheckedText] = useState(null);
  const handleTextClick = (text) => {
    setCheckedText(text === checkedText ? null : text);
  };
  const [ReviewsText, setReviewsText] = useState('');
  const handleTextChange = (text) => {
    setReviewsText(text);
  };
  {
    /* get List Locations */
  }
  const listLocations = async () => {
    try {
      const response = await fetch(
        `https://cors-anywhere.herokuapp.com/https://mybusiness.googleapis.com/v4/accounts/${IDclient}/locations?key=${GOOGLE_API_KEY}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      const responseText = await response.text();

      if (response.headers.get('Content-Type')?.includes('application/json')) {
        const data = JSON.parse(responseText);
        setLocations(data.locations);

        if (data.locations && data.locations.length > 0) {
          setLocationId(data.locations[0].name);
        }
      } else {
        console.error('Response is not JSON:', responseText);
      }
    } catch (error) {
      console.error('Error listing locations:', error);
    }
  };

  useEffect(() => {
    listLocations();
  }, []);
  {
    /* fetch place Id for adress */
  }
  useEffect(() => {
    const fetchPlaceId = async () => {
      try {
        const url = `https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/place/textsearch/json?query=${encodeURIComponent(
          address,
        )}&key=${GOOGLE_API_KEY}&fields=name,rating,formatted_phone_number,reviews`;

        const response = await axios.get(url, {
          headers: {
            origin: 'http://localhost:19006',
            'x-requested-with': 'XMLHttpRequest',
          },
        });

        if (response.data.results && response.data.results.length > 0) {
          setPlaceId(response.data.results[0].place_id);
        } else {
          console.error('No place found for this query.');
        }
      } catch (error) {
        console.error('Error fetching place ID: ', error);
      }
    };

    fetchPlaceId();
  }, [address]);
  {
    /* config for bussniee cloud */
  }
  const config = {
    issuer: 'https://accounts.google.com',
    clientId:
      '946898901720-9jk7lrp6qqc2nqv41cauohqtfi5r3ksc.apps.googleusercontent.com',
    redirectUrl: `${Constants.linkingUri}`,
    scopes: [
      'profile',
      'email',
      'https://www.googleapis.com/auth/business.manage',
    ],
  };
  console.log('config', config);

  {
    /* some  help to you if you want to get access token for sing in  bussiness cloud and 02Auth */
  }

  const postReview = async () => {
    try {
      const response = await fetch(
        `https://cors-anywhere.herokuapp.com/https://mybusiness.googleapis.com/v4/accounts/${IDclient}/locations/${locationId}/reviews?key=${GOOGLE_API_KEY}`,

        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            comment: {
              starRating: [
                {name: 'Satisfait', rating: ratings.satisfait.rating},
                {name: 'Gout', rating: ratings.gout.rating},
                {name: 'Portions', rating: ratings.portions.rating},
                {name: 'Packaging', rating: ratings.packaging.rating},
              ],
              comment: ReviewsText,
              reviewer: {
                displayName: user.displayName,
              },
            },
          }),
        },
      );
      const responseText = await response.text();
      if (!response.ok) {
        throw new Error(
          `HTTP error! status: ${response.status}, details: ${responseText}`,
        );
      }
      const data = JSON.parse(responseText);
      console.log('data', data);
    } catch (error) {
      console.error('Error posting review:', error);
    }
  };

  return (
    <SafeAreaView style={styles.screenContainer}>
      <StatusBar backgroundColor={Colors.white} barStyle="dark-content" />
      <ScrollView
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}>
        <View style={styles.content}>
          <View style={[styles.contentRow, {marginBottom: 15}]}>
            <TitleBigA> Satisfait</TitleBigA>
            <View style={styles.startRow}>
              {ratings.satisfait.checked.map((checked, index) => (
                <View key={index}>
                  <StartFillNoteSVG
                    Checked={checked}
                    onPress={() => handleStarPress('satisfait', index)}
                    width={35}
                    height={35}
                  />
                </View>
              ))}
            </View>
          </View>

          <View>
            <Text style={styles.litleTextCommande}>
              Comment c’était pour vous ?{' '}
            </Text>
            <TitleA>Goût </TitleA>
            <View style={[styles.contentRow, {paddingVertical: 8}]}>
              <BodyA style={{color: Colors.darkGray}}> Mauvais </BodyA>
              <View style={styles.startRow}>
                {ratings.gout.checked.map((checked, index) => (
                  <View key={index}>
                    <StartFillNoteSVG
                      Checked={checked}
                      onPress={() => handleStarPress('gout', index)}
                      width={35}
                      height={35}
                    />
                  </View>
                ))}
              </View>
              <BodyA style={{color: Colors.darkGray}}> Très bon </BodyA>
            </View>
            <View style={styles.separator} />
            <TitleA>Portion </TitleA>
            <View style={[styles.contentRow, {paddingVertical: 8}]}>
              <BodyA style={{color: Colors.darkGray}}> Trop petite </BodyA>
              <View style={styles.startRow}>
                {ratings.portions.checked.map((checked, index) => (
                  <View key={index}>
                    <StartFillNoteSVG
                      Checked={checked}
                      onPress={() => handleStarPress('portions', index)}
                      width={35}
                      height={35}
                    />
                  </View>
                ))}
              </View>
              <BodyA style={{color: Colors.darkGray}}> Généreuse </BodyA>
            </View>
            <View style={styles.separator} />
            <TitleA>Packaging</TitleA>
            <View style={[styles.contentRow, {paddingVertical: 8}]}>
              <BodyA style={{color: Colors.darkGray}}> Trop fragile </BodyA>
              <View style={[styles.startRow]}>
                {ratings.packaging.checked.map((checked, index) => (
                  <View key={index}>
                    <StartFillNoteSVG
                      Checked={checked}
                      onPress={() => handleStarPress('packaging', index)}
                      width={35}
                      height={35}
                    />
                  </View>
                ))}
              </View>
              <BodyA style={{color: Colors.darkGray}}> Sécurisé </BodyA>
            </View>
          </View>
          <View style={{paddingVertical: 8}}>
            <Text style={styles.litleTextCommande}>
              Que pouvons nous améliorer ?
            </Text>
            <View style={styles.separator} />
            <View style={styles.contentRowChecked}>
              <CheckedNoteSVG
                onPress={() => handleTextClick('Que pouvons nous améliorer ?')}
                Checked={checkedText === 'Que pouvons nous améliorer ?'}
              />

              <Text>Lorem Ipsum is simply dummy text </Text>
            </View>
            <View style={styles.separator} />
            <View style={styles.contentRowChecked}>
              <CheckedNoteSVG
                onPress={() =>
                  handleTextClick(
                    'Lorem Ipsum printing and typesetting industry',
                  )
                }
                Checked={
                  checkedText ===
                  'Lorem Ipsum printing and typesetting industry'
                }
              />
              <Text>Lorem Ipsum printing and typesetting industry.</Text>
            </View>
            <View style={styles.separator} />
            <View style={styles.contentRowChecked}>
              <CheckedNoteSVG
                onPress={() => handleTextClick('Lorem Ipsum')}
                Checked={checkedText === 'Lorem Ipsum'}
              />
              <Text>Lorem Ipsum </Text>
            </View>
          </View>
          <View style={{marginBottom: 24}} />

          <View style={{paddingVertical: 2}}>
            <TitleA style={{paddingBottom: 10}}>
              {' '}
              Avez vous des suggestions ?
            </TitleA>
            <View style={styles.borderContainer}>
              <TextInput
                onChangeText={handleTextChange}
                caretHidden={true}
                style={styles.textInput}
                value={ReviewsText}
                placeholder="le livreur ne portait pas de masque"
              />
            </View>
          </View>
          <View
            style={{
              marginTop: 10,
              flexDirection: 'row',
              gap: 5,
            }}>
            <ButtonA
              style={{
                backgroundColor: Colors.background,
                borderWidth: 1,
                borderColor: Colors.normalGray,
                width: '48%',
                paddingHorizontal: 20,
              }}
              color={Colors.normalGray}>
              Aidez moi
            </ButtonA>
            <ButtonA
              style={{
                backgroundColor: Colors.darkGreen,
                width: '50%',
                paddingHorizontal: 20,
              }}
              onPress={postReview}>
              Envoyer
            </ButtonA>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};
export default CommandeNote;
