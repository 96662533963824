import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

const TicketsLineSVG = (props) => {
  const {style, ...restProps} = props;
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 36 36"
      style={style}
      {...restProps}>
      <Path
        stroke="#2FBF86"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M25.5 24H12a1.5 1.5 0 0 1-1.5-1.5V9A1.5 1.5 0 0 1 12 7.5h13.5m0 16.5H30a1.5 1.5 0 0 0 1.5-1.5V9A1.5 1.5 0 0 0 30 7.5h-4.5m0 16.5v-1.5m0-15V9"
      />
      <Path
        stroke="#2FBF86"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M19.5 30H6a1.5 1.5 0 0 1-1.5-1.5V15A1.5 1.5 0 0 1 6 13.5h4.5m9 16.5H24a1.5 1.5 0 0 0 1.5-1.5v-3.75m-6 5.25v-1.5m6-14.998V13.5m0 4.502V18"
      />
    </Svg>
  );
};
export default TicketsLineSVG;
