import React from 'react';
import Svg, {G, Defs, ClipPath, Path} from 'react-native-svg';

const StarFillSVG = ({width, height}) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 35 36"
      fill="none">
      <Path
        d="M30.8001 14.9083C30.739 14.7146 30.6245 14.542 30.4697 14.4104C30.3149 14.2788 30.1261 14.1936 29.9251 14.1646L22.0209 13.0125L18.4771 5.85208C18.3763 5.68287 18.2332 5.54276 18.0619 5.44545C17.8907 5.34815 17.697 5.297 17.5001 5.297C17.3031 5.297 17.1095 5.34815 16.9382 5.44545C16.7669 5.54276 16.6238 5.68287 16.523 5.85208L12.9792 13.0125L5.07505 14.1646C4.87399 14.1936 4.6852 14.2788 4.53041 14.4104C4.37562 14.542 4.26111 14.7146 4.20005 14.9083C4.13487 15.1014 4.12491 15.3088 4.17129 15.5072C4.21767 15.7056 4.31855 15.8871 4.46255 16.0312L10.2084 21.6021L8.85214 29.4771C8.81776 29.6798 8.84048 29.8882 8.91774 30.0787C8.99501 30.2693 9.12377 30.4347 9.28964 30.5562C9.45577 30.6746 9.65169 30.7442 9.85528 30.7571C10.0589 30.77 10.262 30.7257 10.4417 30.6292L17.5001 26.9104L24.573 30.6292C24.7294 30.715 24.9049 30.7601 25.0834 30.7604C25.3128 30.7578 25.5363 30.6867 25.7251 30.5562C25.8909 30.4347 26.0197 30.2693 26.097 30.0787C26.1742 29.8882 26.1969 29.6798 26.1626 29.4771L24.7917 21.6021L30.523 16.0312C30.6695 15.8887 30.7731 15.7079 30.8221 15.5094C30.8711 15.3109 30.8634 15.1027 30.8001 14.9083Z"
        fill="#FFB400"
      />
    </Svg>
  );
};
export default StarFillSVG;
