import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

const InfoSVG = (props) => {
  const {style, ...restProps} = props;

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 36 36"
      style={style}
      {...restProps}>
      <Path
        fill="#2FBF86"
        d="M18 14.625a1.125 1.125 0 0 1 1.125 1.125v10.125a1.125 1.125 0 1 1-2.25 0V15.75A1.125 1.125 0 0 1 18 14.625Zm0-2.25a1.687 1.687 0 1 0 0-3.374 1.687 1.687 0 0 0 0 3.374ZM2.25 18C2.25 9.302 9.302 2.25 18 2.25S33.75 9.302 33.75 18 26.698 33.75 18 33.75 2.25 26.698 2.25 18ZM18 4.5C10.545 4.5 4.5 10.545 4.5 18S10.545 31.5 18 31.5 31.5 25.455 31.5 18 25.455 4.5 18 4.5Z"
      />
    </Svg>
  );
};
export default InfoSVG;
