import * as React from 'react';
import Svg, {G, Path, Defs, ClipPath} from 'react-native-svg';

const ShieldLineSVG = (props) => {
  const {style, ...restProps} = props;
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 36 36"
      style={style}
      {...restProps}>
      <G clipPath="url(#a)">
        <Path
          fill="#2FBF86"
          d="M31.25 7.4a43.793 43.793 0 0 1-6.62-2.35 44.995 44.995 0 0 1-6.08-3.21L18 1.5l-.54.35a44.995 44.995 0 0 1-6.08 3.21A43.79 43.79 0 0 1 4.75 7.4L4 7.59v8.34c0 13.39 13.53 18.4 13.66 18.45l.34.12.34-.12c.14 0 13.66-5.05 13.66-18.45V7.59l-.75-.19ZM30 15.93c0 11-10 15.61-12 16.43-2-.82-12-5.44-12-16.43V9.14a47.542 47.542 0 0 0 6.18-2.25 48.232 48.232 0 0 0 5.82-3 48.232 48.232 0 0 0 5.82 3A47.54 47.54 0 0 0 30 9.14v6.79Z"
        />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path fill="#fff" d="M0 0h36v36H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};
export default ShieldLineSVG;
