import {Dimensions, StyleSheet} from 'react-native';
import Colors from '../../theme/colors';

const fullWidth = Dimensions.get('window').width;
const fullHeight = Dimensions.get('window').height;

const styles = StyleSheet.create({
  screenContainer: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  backgroundImage: {
    width: fullWidth,
    height: fullHeight,
    justifyContent: 'flex-end',
  },
  container: {
    padding: 25,
    paddingBottom: 40,

    justifyContent: 'space-between',

    width: fullWidth,
    height: fullHeight - fullHeight / 10,
    borderTopRightRadius: 32,
    borderTopLeftRadius: 32,
    backgroundColor: Colors.white,
  },
  headerContainer: {
    paddingVertical: 30,
  },
  logoContainer: {
    borderRadius: 75,
    borderWidth: 2,
    borderColor: Colors.lightGray,
    borderColor: Colors.darkBlue,
    width: 150,
    height: 150,
    alignSelf: 'center',
  },
  versionContainer: {
    alignSelf: 'center',
  },
  versionText: {
    marginTop: 10,
    color: Colors.darkBlue,
    letterSpacing: 3,
  },
  btnsContainer: {
    paddingVertical: 10,
  },

  wordsWrapper: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginTop: 20,
  },
  footerText: {
    color: Colors.normalGray,
  },
  footerLink: {
    color: Colors.primaryColor,
  },
  errorText: {
    color: Colors.error,
    marginBottom: 10,
    marginLeft: 10,
  },
});

export default styles;
