import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

const ClipboardSVG = (props) => {
  const {style, ...restProps} = props;
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 36 36"
      style={style}
      {...restProps}>
      <Path
        stroke="#2FBF86"
        strokeWidth={2.25}
        d="M24 6.003c3.262.018 5.03.164 6.181 1.316C31.5 8.637 31.5 10.759 31.5 15v9c0 4.244 0 6.365-1.319 7.683C28.864 33 26.742 33 22.5 33h-9c-4.242 0-6.364 0-7.681-1.317C4.5 30.363 4.5 28.243 4.5 24v-9c0-4.242 0-6.363 1.319-7.681C6.97 6.167 8.738 6.02 12 6.003"
      />
      <Path
        stroke="#2FBF86"
        strokeLinecap="round"
        strokeWidth={2.25}
        d="M15.75 21h9.75m-15 0h.75m-.75-5.25h.75m-.75 10.5h.75m4.5-10.5h9.75m-9.75 10.5h9.75"
      />
      <Path
        stroke="#2FBF86"
        strokeWidth={2.25}
        d="M12 5.25A2.25 2.25 0 0 1 14.25 3h7.5A2.25 2.25 0 0 1 24 5.25v1.5A2.25 2.25 0 0 1 21.75 9h-7.5A2.25 2.25 0 0 1 12 6.75v-1.5Z"
      />
    </Svg>
  );
};
export default ClipboardSVG;
