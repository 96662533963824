import React from 'react';
import Svg, {G, Defs, Rect, Path} from 'react-native-svg';
import {TouchableOpacity} from 'react-native';
const CheckedNoteSVG = ({onPress, Checked}) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 25 24"
        fill="none">
        {Checked ? (
          <Path
            d="M11.1 16.2L18.15 9.15L16.75 7.75L11.1 13.4L8.25 10.55L6.85 11.95L11.1 16.2ZM5.5 21C4.95 21 4.479 20.804 4.087 20.412C3.695 20.02 3.49934 19.5493 3.5 19V5C3.5 4.45 3.696 3.979 4.088 3.587C4.48 3.195 4.95067 2.99934 5.5 3H19.5C20.05 3 20.521 3.196 20.913 3.588C21.305 3.98 21.5007 4.45067 21.5 5V19C21.5 19.55 21.304 20.021 20.912 20.413C20.52 20.805 20.0493 21.0007 19.5 21H5.5Z"
            fill="#2FBF86"
          />
        ) : (
          <Rect
            x="0.5"
            y="1"
            width="18"
            height="18"
            rx="2.5"
            stroke="#77838F"
          />
        )}
      </Svg>
    </TouchableOpacity>
  );
};
export default CheckedNoteSVG;
