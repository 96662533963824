import {StyleSheet} from 'react-native';
import Colors from '../../../theme/colors';

const styles = StyleSheet.create({
  screenContainer: {
    flex: 1,
    backgroundColor: Colors.background,
  },
  container: {
    paddingHorizontal: 24,
    paddingTop: 24,
    paddingBottom: 40,
  },
  separator: {
    height: 1,
    marginHorizontal: 0,
    backgroundColor: Colors.lightGray,
    marginVertical: 16,
  },
  item: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  img: {
    width: 46,
    height: 46,
    borderRadius: 23,
    backgroundColor: 'white',
    marginRight: 16,
    borderColor: Colors.primaryColor,
  },
});

export default styles;
