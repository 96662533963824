import {Dimensions, StyleSheet} from 'react-native';
import Colors from '../../theme/colors';

const fullWidth = Dimensions.get('window').width;
const fullHeight = Dimensions.get('window').height;

const styles = StyleSheet.create({
  screenContainer: {
    flex: 1,
    backgroundColor: Colors.background,
  },
  backgroundImage: {
    width: fullWidth,
    height: fullHeight,
    justifyContent: 'flex-end',
  },
  container: {
    width: fullWidth,
    // height: fullHeight - 62,
    height: fullHeight - fullHeight / 10,

    backgroundColor: Colors.white,
    borderTopRightRadius: 32,
    borderTopLeftRadius: 32,
  },
  swiperContainer: {
    flex: 1,
    padding: 25,
  },
  slide: {
    flex: 1,
    alignItems: 'center',
  },
  slideImg: {
    marginTop: 50,
    height: 230,
    aspectRatio: 4 / 3,
  },
  title: {
    marginTop: 70,
    textAlign: 'center',
    color: Colors.darkGray,
    fontFamily: 'SemiBold',
    fontSize: 16,
    lineHeight: 24,
    letterSpacing: 0.64,
  },
  descriptionText: {
    paddingHorizontal: 20,
    marginTop: 11,
    textAlign: 'center',
    color: Colors.normalGray,
    fontFamily: 'Regular',
    fontSize: 14,
    lineHeight: 21,
    letterSpacing: 0.56,
  },
  paginationDotsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    alignSelf: 'center',
    top: 315,
  },
  buttonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginHorizontal: 25,
  },
  buttonContainer: {
    flexBasis: '50%',
  },
  dot: {
    margin: 4,
    width: 8,
    height: 8,
    borderRadius: 4,
  },
  skipContainer: {
    // backgroundColor: 'yellow',
    width: 60,
    height: 36,
    position: 'absolute',
    top: 30,
    right: 30,
  },
  skipBtnText: {
    // paddingHorizontal: 20,
    // marginTop: 11,
    textAlign: 'center',
    color: Colors.primaryColor,
    fontFamily: 'Regular',
    fontSize: 16,
    lineHeight: 36,
  },
});

export default styles;
