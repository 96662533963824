import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback,
} from 'react';
import styles from './EventBStyles';
import {useIsFocused} from '@react-navigation/native';
// import FastImage from 'react-native-fast-image-expo';
// import {BlurView} from 'react-native-blur';
// import {BlurView} from 'expo-blur';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {
  addProduct,
  emptyCart,
  setEvent,
  setReferral,
  addCommandeGroupe,
  removeCommandeGroupe,
  setAccessRestriction,
  clearAccessRestriction,
  cleanupCartAndRestrictions,
} from '../../reducers/cartactions';

import {
  Image,
  SafeAreaView,
  StatusBar,
  View,
  ScrollView,
  ActivityIndicator,
  Dimensions,
  TextInput,
  Share,
  Animated,
  Text,
  Platform,
} from 'react-native';
import {AuthContext} from '../../contexts/context';
import {
  collection,
  query,
  where,
  doc,
  onSnapshot,
  getDoc,
} from 'firebase/firestore';
import {db} from '../../services/firebase';
import firebase from 'firebase/compat/app';
import getImgSource from '../../utils/getImgSource';
import ProgressiveImage from '../../utils/progressiveImage';
import Colors from '../../theme/colors';
import FavoriteSVG from '../../components/svgs/FavoriteSVG';
import TitleA from '../../components/titleA/TitleA';
import SubheadingA from '../../components/subheadingA/SubheadingA';
import LocationSVG from '../../components/svgs/LocationSVG';
import CalendarSVG from '../../components/svgs/CalendarSVG';
import BodyA from '../../components/bodyA/BodyA';
import ButtonA from '../../components/buttonA/ButtonA';
import CartSVG from '../../components/svgs/CartSVG';
import MapView from '../../components/mapView/MapView';
import FavoritesEventsContext from '../../contexts/favoritesEventsContext';
import ModalA from '../../components/modalA/ModalA';
import locales from '../../config/locales';
// import ItemCounterA from '../../components/itemCounterA/ItemCounterA';
import RenderHtml from 'react-native-render-html';
import getEnvVars from '../../config';
import {formattedDateUtils, getEventDateTime} from '../../utils/shared';
import InfoModal from '../../components/modals/InfoModal';
import {useTranslation} from 'react-i18next';
import {useLanguage} from '../../contexts/languageEventsContext';
import englishFlag from '../../../assets/images/englishFlag.png';
import germanFlag from '../../../assets/images/germanyFlag.png';
import frenchFlag from '../../../assets/images/frenchFlag.png';
import spanishFlag from '../../../assets/images/spanishFlag.png';
import portugueseFlag from '../../../assets/images/portugueseFlag.png';
import TicketCard from '../../components/ticketCard/TicketCard';
import PriceDeformationModal from '../../components/modals/PriceDeformationModal';
import PriceLevelSelector from '../../components/prices/PriceLevelSelector';
import AccessRestrictionModal from '../../components/modals/AccessRestrictionModal';
import AccessRestrictionSelector from '../../components/prices/AccessRestrictionSelector';

const {links} = getEnvVars();

const deviceWidth = Dimensions.get('window').width;

const CHECKMARK_ICON =
  Platform.OS === 'ios'
    ? 'ios-checkmark-circle-outline'
    : 'md-checkmark-circle-outline';

const FAIL_ICON =
  Platform.OS === 'ios'
    ? 'ios-close-circle-outline'
    : 'md-close-circle-outline';

const EventB = ({
  navigation,
  route,
  addProduct,
  cart,
  emptyCart,
  setReferral,
  commandeGroupe,
  setAccessRestriction,
  clearAccessRestriction,
  cleanupCartAndRestrictions,
}) => {
  const favoritesContext = useContext(FavoritesEventsContext);
  const {t} = useTranslation();

  // Retrieve the eventId and isFavorite from the route parameters
  const {s, isFavorite, h} = route.params;
  const eventId = useMemo(() => s, [s]);
  // console.log('eventId ===>', eventId);
  // const [favorite, setFavorite] = useState(isFavorite);
  const [isLoading, setIsLoading] = useState(false);
  const [event, setEvent] = useState([]);
  const [tickets, setTickets] = useState();
  const [owner, setOwner] = useState();
  // goupe order
  const focus = useIsFocused();
  // const [inputModalVisible, setInputModalVisible] = useState(false);
  const [userTotalCart, setUserTotalCart] = useState(0);
  const [totalPriceShared, setTotalPriceShared] = useState(0);

  //Group order state
  const [waitingForClienNameInStep, setWaitingForClienNameInStep] = useState(1);
  const [clientName, setClientName] = useState('');
  const [isInActiveGroupedOrder, setIsInActiveGroupedOrder] = useState(false);
  const [groupedOrderData, setGroupedOrderData] = useState(null);
  const [groupedOrderIsOwner, setGroupedOrderIsOwner] = useState(false);
  const [groupedOrderModalVisible, setGroupedOrderModalVisible] =
    useState(false);
  const [friendCartStatus, setFriendCartStatus] = useState('draft');
  const [showLoading, setShowLoading] = useState(false);
  const [showClientNameInputModal, setShowClientNameInputModal] =
    useState(false);
  const [infoModal, setInfoModal] = useState({
    infoModalTitle: '',
    infoModalMessage: '',
    infoModalIcon: FAIL_ICON,
    infoModalVisible: false,
  });
  const [audit, setAudit] = useState({});
  const [show, setShow] = useState({});
  const [isMinimumPerOrderNotRespect, setIsMinimumPerOrderNotRespect] =
    useState(false);

  const [priceDeformations, setPriceDeformations] = useState([]);
  const [selectedDeformation, setSelectedDeformation] = useState(null);
  const [showDeformationModal, setShowDeformationModal] = useState(false);

  const [accessRestrictions, setAccessRestrictions] = useState([]);
  const [selectedAccessRestriction, setSelectedAccessRestriction] = useState(null);
  const [showAccessRestrictionModal, setShowAccessRestrictionModal] = useState(false);
  const [userEmail, setUserEmail] = useState('');

  // const [totalPrice, setTotalPrice] = useState(0);

  // const [owner, setOwner] = useState();
  const {user} = useContext(AuthContext);
  const {selectedLanguage} = useLanguage();

  const flag = {
    en: englishFlag,
    fr: frenchFlag,
    es: spanishFlag,
    pt: portugueseFlag,
    de: germanFlag,
  }[selectedLanguage];

  const languageLabel = {
    en: 'EN',
    fr: 'FR',
    es: 'ES',
    pt: 'PT',
    de: 'DE',
  }[selectedLanguage];

  const getTranslatedText = (translations) => {
    // console.log('translations', translations);
    // console.log('selectedLanguage', selectedLanguage);
    // console.log('selectedTEXT', translations?.[selectedLanguage]);
    // console.log(event?.descriptiontranslations);
    return translations?.[selectedLanguage] || translations?.fr;
  };
  const handleNavigation = () => {
    navigation.navigate('GroupOrder', {
      eventId: s,
      clientName: clientName ? clientName : '',
      isInActiveGroupedOrder: isInActiveGroupedOrder,
      title: event?.title,
    });
  };

  useEffect(() => {
    if (commandeGroupe?.id) {
      setGroupedOrderData(commandeGroupe.data);
      setIsInActiveGroupedOrder(commandeGroupe.id);
      commandeGroupe.isOwner && setGroupedOrderIsOwner(true);
    } else {
      recycleGroupedOrder();
    }
  }, [commandeGroupe]);

  useEffect(() => {
    const handleEventChange = () => {
      // Clean up if new event has no restrictions
      if (!accessRestrictions || accessRestrictions.length === 0) {
        cleanupCartAndRestrictions();
        return;
      }
  
      // Clean up if event has different restrictions than current cart
      if (cart.accessRestriction) {
        const currentRestrictionExists = accessRestrictions.some(
          restriction => restriction.id === cart.accessRestriction.restriction.id
        );
        
        if (!currentRestrictionExists) {
          cleanupCartAndRestrictions();
        }
      }
    };
  
    handleEventChange();
  }, [accessRestrictions, cart.accessRestriction]);

  useEffect(() => {
    if (route?.params?.referralid) setReferral(route?.params?.referralid);
  }, [route]);

  const calculateTotalPriceFriends = async (dataGO) => {
    // Get the amount to be added
    let totalPrice = 0;
    dataGO.users &&
      dataGO.users.forEach((user, i) => {
        totalPrice += parseFloat(user.totalPrice);
        // TODO: Handle what to show depending on what will pay
        // if (user.status == "confirmed") totalConfirmedPrice += user.total;
      });

    setTotalPriceShared(totalPrice);
  };

  const checkCartStatusforFriends = (dataGO) => {
    const userData = dataGO?.users
      ? dataGO?.users[dataGO?.users?.findIndex((usr) => usr.userId == user.uid)]
      : null;
    if (userData) {
      setFriendCartStatus(userData.status);
    }
  };

  // Add the current user as a Friend in a Grouped Order (case whenreceives invitation but not owner)
  const addFriendToGroupedOrder = async (userId, goId) => {
    // Add Grouped Order to User
    await db.collection('users').doc(userId).set(
      {
        activeGroupedOrder: goId,
      },
      {merge: true},
    );
    // Push the User as a Friend
    await db
      .collection('groupedorders')
      .doc(goId)
      .get()
      .then(async (docGO) => {
        const groupedOrderData = docGO.data();
        const actualUser = {
          userId: userId,
          userName: clientName,
          acceptedAt: firebase.firestore.Timestamp.fromDate(new Date()),
          products: [],
          totalPrice: userTotalCart,
          status: 'draft',
        };
        const users = groupedOrderData?.users || [];
        db.collection('groupedorders')
          .doc(goId)
          .set(
            {
              users: [...users, actualUser],
            },
            {merge: true},
          );
      });
  };

  const getLastGroupedOrderInfo = async (goId) => {
    // TODO: This should be handled in backend
    return await db
      .collection('groupedorders')
      .doc(goId)
      .get()
      .then(async (docGO) => {
        const dataGO = docGO.data();
        if (dataGO) {
          // Save it locally

          setGroupedOrderData(dataGO);
          // Recalculate Total
          await calculateTotalPriceFriends(dataGO);
          // Check status of Cart for Friend
          if (groupedOrderIsOwner) checkCartStatusforFriends(dataGO);
        } else {
          setGroupedOrderIsOwner(false);
          setGroupedOrderData(null);
        }
        return dataGO;
      });
  };

  const handleAcceptInviteToGroupedOrder = async (groupedOrderId, userId) => {
    const params = route?.params;
    // We push the new user
    // TODO: The following should be handled in backend
    await addFriendToGroupedOrder(userId, groupedOrderId);
    // Add it locally
    setIsInActiveGroupedOrder(groupedOrderId);
    navigation.reset({
      index: 0,
      routes: [
        {
          name: 'Event',
          params: {s: params.s, isFavorite: params.isFavorite, t: params.t},
        },
      ],
    });
    // Setup a snapshot to follow changes
    await setupSnaphotGroupedOrder(groupedOrderId);
  };

  const recycleGroupedOrder = () => {
    setTotalPriceShared(0);
    setIsInActiveGroupedOrder(false);
    setGroupedOrderData(false);
    setGroupedOrderIsOwner(false);
    // setGroupedOrderLink('');
    setFriendCartStatus('draft');
    setShowLoading(false);
  };

  const getUserStatusInGroupedOrder = async (goId, userId) => {
    return await getLastGroupedOrderInfo(goId).then((dataGO) => {
      if (dataGO) {
        // Check if user is Owner
        if (dataGO?.owner?.userId == user.uid) {
          setGroupedOrderIsOwner(true);
          return 'owner';
        } else {
          setGroupedOrderIsOwner(false);
          // Check if exists in Friends
          const users = dataGO?.users;
          if (
            users?.length > 0 &&
            users?.some((obj) => obj.userId === userId)
          ) {
            return 'friend';
          } else {
            return 'none';
          }
        }
      } else return 'nogo';
    });
  };

  const removeOrderFromUser = async () => {
    await db
      .collection('users')
      .doc(user.uid)
      .set(
        {
          activeGroupedOrder: null,
        },
        {merge: true},
      )
      .then(() => {
        removeCommandeGroupe();
        console.log('Grouped Order successfully deleted from user');
        recycleGroupedOrder();
      });
  };

  const handleLeaveGoupedOrder = async () => {
    setShowLoading(true);
    // Remove the Snapshot listener
    // unsubscribeListener();
    // If the User is not the owner, we remove him
    if (!groupedOrderIsOwner) {
      console.log(
        'The user is not the owner and is asked to leave Grouped Order',
      );
      // TODO: The following should be handled in backend
      await db
        .collection('groupedorders')
        .doc(isInActiveGroupedOrder)
        .get()
        .then(async (docGO) => {
          const dataGO = docGO.data();
          let users = dataGO.users;
          users = users.filter((usr) => usr.userId != user.uid);
          // TODO: Must check that the Grouped Order still exists
          await db
            .collection('groupedorders')
            .doc(isInActiveGroupedOrder)
            .set({users}, {merge: true})
            .then(async () => {
              // Remove Grouped Order from current user
              console.log('Remove Grouped Order from current user');
              await removeOrderFromUser();
            });
        });
    }
    // If the owner wants to get out
    await getLastGroupedOrderInfo(isInActiveGroupedOrder).then(async () => {
      if (groupedOrderData.users && groupedOrderData.users.length == 0) {
        // Delete the Grouped Order
        await db
          .collection('groupedorders')
          .doc(isInActiveGroupedOrder)
          .delete()
          .then(async () => {
            // Remove Grouped Order from current user
            await removeOrderFromUser();
            setShowLoading(false);
          })
          .catch((error) => {
            setShowLoading(false);
            console.error('Error removing Grouped Order: ', error);
          });
      } else {
        // TODO: Handle case where Grouped Order has users
        setShowLoading(false);
        console.log('You cannot remove a Grouped Order while members are in');
      }
    });
  };

  const setupSnaphotGroupedOrder = async (goId) => {
    const unsubscribeListener = () =>
      db
        .collection('groupedorders')
        .doc(goId)
        .onSnapshot(async (doc) => {
          if (doc.exists) {
            const activeGroupedOrder = doc.id;
            if (activeGroupedOrder) {
              await getLastGroupedOrderInfo(activeGroupedOrder).then(
                async (dataGO) => {
                  if (!dataGO) return;
                  // We check the the Grouped Order has not already been paid (for friends)
                  if (dataGO.status === 'paid') {
                    emptyCart();
                    await db
                      .collection('users')
                      .doc(user.uid)
                      .set(
                        {
                          activeGroupedOrder: null,
                        },
                        {merge: true},
                      )
                      .then(() => {
                        recycleGroupedOrder();
                      });
                    if (!groupedOrderIsOwner) {
                      setInfoModal({
                        infoModalTitle: 'Payé',
                        infoModalMessage:
                          'Cette commande groupée a été payée par le propriétaire',
                        infoModalIcon: CHECKMARK_ICON,
                        infoModalVisible: true,
                      });
                      await removeOrderFromUser();
                      return unsubscribeListener();
                    }
                  }
                  // Check if the Friend has been removed from Grouped Order
                  if (
                    dataGO?.owner?.userId != user.uid &&
                    !dataGO?.users?.some((obj) => obj?.userId == user.uid) &&
                    activeGroupedOrder
                  ) {
                    console.log(
                      'MenuA: User is no more a Friend in the Grouped Order',
                    );
                    unsubscribeListener();
                    await handleLeaveGoupedOrder();
                  }
                },
              );
            } else {
              unsubscribeListener();
            }
          } else {
            unsubscribeListener();
          }
        });
  };

  const checkClientHasName = async (step, callback) => {
    await db
      .collection('users')
      .doc(user.uid)
      .get()
      .then(async (docuser) => {
        const userData = docuser?.data();
        if (userData && 'displayName' in userData) {
          console.log('User name is : ' + userData.displayName);
          setClientName(userData.displayName);
          callback();
        } else {
          setWaitingForClienNameInStep(step);
          //add  the modal
          if (step === 1) {
            setShowClientNameInputModal(true);
          } else if (step === 2) {
            handleNavigation();
          }
        }
      });
  };

  const handleFinishedGroupedOrder = async (goId) => {
    return await db
      .collection('groupedorders')
      .doc(goId)
      .get()
      .then(async (docGO) => {
        const dataGO = docGO.data();
        if (!dataGO) return false;
        if (dataGO.status === 'paid') {
          // If owner, we remove data from Cart and local/dbb data from Grouped Order in User
          emptyCart();
          await removeOrderFromUser();
          return true;
        } else return false;
      });
  };

  const handleGroupedOrderInitialStatus = async () => {
    const params = route?.params;
    await db
      .collection('users')
      .doc(user.uid)
      .get()
      .then(async (docuser) => {
        const userData = docuser.data();
        // Check if there is an active Grouped Order in User
        if (docuser.exists && userData.activeGroupedOrder) {
          // if (userData.activeGroupedOrder !== params.go) {
          //   await db
          //     .collection('groupedorders')
          //     .doc(userData.activeGroupedOrder)
          //     .get()
          //     .then(async (docGO) => {
          //       const dataGO = docGO.data();
          //       let users = dataGO.users;
          //       users = users.filter((usr) => usr.userId != user.uid);
          //       // TODO: Must check that the Grouped Order still exists
          //       await db
          //         .collection('groupedorders')
          //         .doc(userData.activeGroupedOrder)
          //         .set({users}, {merge: true})
          //         .then(async () => {
          //           // Remove Grouped Order from current user
          //           console.log('Remove Grouped Order from current user 4');
          //           await removeOrderFromUser();
          //           checkClientHasName(
          //             1,
          //             async () =>
          //               await handleAcceptInviteToGroupedOrder(
          //                 params.go,
          //                 user.uid,
          //               ),
          //           );
          //         });
          //     });

          //   return;
          // }
          // We verify that the user exists in the Grouped Order, and if so, as Owner or Friend
          await getUserStatusInGroupedOrder(
            userData.activeGroupedOrder,
            user.uid,
          ).then(async (userRole) => {
            if (userRole == 'owner' || userRole == 'friend') {
              // We check the the Grouped Order has not already been paid (after checkout)
              const statusOrder = await handleFinishedGroupedOrder(
                userData.activeGroupedOrder,
              );

              if (statusOrder) {
                console.log('Ending handleGroupedOrderInitialStatus');
              } else {
                // All good, we save the Grouped Order locally
                setIsInActiveGroupedOrder(userData.activeGroupedOrder);
                // Add products in Store to Grouped Orders
                if (cart.products.length > 0) {
                  await addProductsToGroupedOrder(cart.products);
                }
                // Setup a snapshot to follow changes
                await setupSnaphotGroupedOrder(userData.activeGroupedOrder);
              }
            } else if (userRole == 'nogo' || userRole == 'none') {
              // The Grouped Order does not exist
              // Remove Grouped Order from User
              emptyCart();
              await db.collection('users').doc(user.uid).set(
                {
                  activeGroupedOrder: null,
                },
                {merge: true},
              );
            } else console.log('Unhandled response.');
          });
          // If the user is not in an active Grouped Order, but came from an URL with Grouped Order invitation (Dynamic Link)
          // Handle case where user is in active Grouped Order but also opened an invitation
        } else if (params && params.go) {
          // We verify that the user exists in the Grouped Order, and if so, as Owner or Friend
          await getUserStatusInGroupedOrder(params.go, user.uid).then(
            async (userRole) => {
              if (userRole == 'none') {
                await emptyCart();
                checkClientHasName(
                  1,
                  async () =>
                    await handleAcceptInviteToGroupedOrder(params.go, user.uid),
                );
              } else if (userRole == 'owner' || userRole == 'friend') {
                // This shouldn't happen
                console.log(
                  'Unhandled case: The User is in the Grouped Order but no Grouped Order inthe User',
                );
              } else
                console.log('The Grouped Order from invitation is invalid.');
            },
          );
          // Otherwise, nothing happens
        } else {
          setIsInActiveGroupedOrder(false);
        }
      });
  };

  const addProductsToGroupedOrder = async (products) => {
    // TODO: The following should be handled in backend
    if (groupedOrderIsOwner) {
      let ownerData = groupedOrderData.owner;
      ownerData.products = [...products];
      await db
        .collection('groupedorders')
        .doc(isInActiveGroupedOrder)
        .set({owner: JSON.parse(JSON.stringify(ownerData))}, {merge: true});
    } else {
      if (groupedOrderData?.users) {
        let usersData = groupedOrderData?.users;

        if (usersData?.length > 0) {
          const indexUser = usersData?.findIndex((e) => e.userId == user.uid);
          if (indexUser > 0) {
            usersData[indexUser].products = [...products];
            // Calculate Total price
            let totalPrice = 0;
            products.forEach((product, i) => {
              totalPrice += parseFloat(product.total);
            });
            usersData[indexUser].totalPrice = totalPrice;
            await db
              .collection('groupedorders')
              .doc(isInActiveGroupedOrder)
              .set(
                {users: JSON.parse(JSON.stringify(usersData))},
                {merge: true},
              );
          }
        }
      }
    }
  };

  // Function to get the month name
  const getMonthName = (monthIndex) => {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    return months[monthIndex];
  };

  const handleIncrease = useCallback(
    (item, maxOrderTickets) => {
      // Check if there are products from other events and clear cart if so
      if (cart?.products?.length > 0) {
        let isotherEventExist = cart.products.some(
          (product) => item.eventId !== product.eventId,
        );
        if (isotherEventExist) emptyCart();
      }

      const filteredProduct = cart.products.find(
        (product) => item.id === product.id,
      );

      // Calculate deformed prices based on selected deformation
      const calculateDeformedPrices = (basePrice) => {
        if (!selectedDeformation) return null;

        const deformedPrice = selectedDeformation.deformationType === 'PERCENTAGE'
          ? basePrice * (1 - selectedDeformation.deformationValue / 100)
          : basePrice - selectedDeformation.deformationValue;

        return {
          deformedPrice,
          deformedPriceHt: item.tvaRate 
            ? deformedPrice / (1 + (item.tvaRate / 100))
            : deformedPrice,
          deformedPlatformFee: item.includePlatformFees && item.platformFee
            ? deformedPrice * (item.platformFee / 100)
            : 0,
        };
      };

      // Handle existing product in cart
      if (filteredProduct) {
        if (filteredProduct.quantity + 1 < filteredProduct.minQuantityPerOrder)
          setIsMinimumPerOrderNotRespect(true);
        else setIsMinimumPerOrderNotRespect(false);

        if (
          (item.maxQuantityPerOrder
            ? filteredProduct.quantity < item.maxQuantityPerOrder
            : true) &&
          filteredProduct.quantity < maxOrderTickets
        ) {
          const deformedPrices = calculateDeformedPrices(item.price);
          addProduct({
            id: item.id,
            quantity: 1,
            price: item.price,
            priceDeformation: selectedDeformation,
            ...deformedPrices,
          });
        }
      } else {
        // Handle new product addition
        if (item.quantity < item.minQuantityPerOrder)
          setIsMinimumPerOrderNotRespect(true);
        else setIsMinimumPerOrderNotRespect(false);

        const definedValues = {
          id: item.id,
          name: item.name,
          order: item.order,
          description: item.description,
          price: item.price,
          photo: item?.photo || '',
          isFree: item.isFree,
          isAccessTimeLimit: item?.isAccessTimeLimit,
          isAccessTimeThreshold: item?.isAccessTimeThreshold,
          accessTimeLimit: item?.accessTimeLimit,
          accessTimeThreshold: item?.accessTimeThreshold,
          isSellTimeLimit: item.isSellTimeLimit,
          minQuantityPerOrder: item.minQuantityPerOrder,
          maxQuantityPerOrder: item.maxQuantityPerOrder,
          initialQuantity: item.initialQuantity,
          quantity: 1,
          includePlatformFees: item.includePlatformFees,
          platformFee: item.platformFee || null,
          priceAfterFeePlatform: item.priceAfterFeePlatform,
          promoCode: item?.promoCode || null,
          categoryid: item.id,
          tier: 'ticket',
          eventId: item.eventId,
          ownerid: owner?.ownerid || '',
          products: item?.products?.length > 0 ? item.products : undefined,
          isMultiple: item?.isMultiple || false,
          personsNumber: item?.personsNumber || null,
          isTvaActive: item?.isTvaActive || null,
          tvaRate: item?.tvaRate || null,
          priceHt: item?.priceHt || null,
          accessRestrictions: item?.accessRestrictions || null,
          // Add price deformation related fields
          priceDeformation: selectedDeformation,
          ...calculateDeformedPrices(item.price),
        };

        // Calculate initial total based on deformed price if applicable
        if (definedValues.deformedPrice) {
          const deformedFinalPrice = definedValues.includePlatformFees
            ? definedValues.deformedPrice + definedValues.deformedPlatformFee
            : definedValues.deformedPrice;
          definedValues.total = deformedFinalPrice;
        } else {
          definedValues.total = item.price;
        }

        // Filter out undefined values
        const filteredValues = {};
        for (const key in definedValues) {
          if (definedValues[key] !== undefined) {
            filteredValues[key] = definedValues[key];
          }
        }

        addProduct(filteredValues);
      }
    },
    [cart, addProduct, owner, selectedDeformation],
  );

  const handleDecrease = useCallback(
    (item) => {
      const filteredProduct = cart.products.find(
        (product) => item.id === product.id,
      );
      if (filteredProduct) {
        if (
          filteredProduct.quantity - 1 < filteredProduct.minQuantityPerOrder &&
          filteredProduct.quantity > 1
        )
          setIsMinimumPerOrderNotRespect(true);
        else setIsMinimumPerOrderNotRespect(false);
        addProduct({id: item.id, quantity: -1, price: item.price});
      }
    },
    [cart, addProduct],
  );

  const totalPrice = useMemo(() => {
    if (!tickets) return 0;
  
    return tickets.reduce((acc, ticket) => {
      const filteredProduct = cart.products.find(
        (product) => ticket.id === product.id
      );
  
      if (!filteredProduct) return acc;
  
      const quantity = filteredProduct.quantity;
  
      // If the product has deformed prices, use those
      if (filteredProduct.deformedFinalPrice) {
        return acc + (filteredProduct.deformedFinalPrice * quantity);
      }
  
      // Otherwise use original pricing logic
      if (ticket?.includePlatformFees && ticket?.priceAfterFeePlatform) {
        return acc + (ticket.priceAfterFeePlatform * quantity);
      }
  
      return acc + (ticket.price * quantity);
    }, 0);
  }, [tickets, cart, selectedDeformation]);

  const handleAddToCart = () => {
    navigation.navigate('Cart', {eventId: event.id});
  };

  const shareGroupedOrder = async (user, link, paymentType) => {
    console.log(clientName);
    console.log(link);
    console.log(paymentType);
    try {
      // `${t('cartBPage.En attente de validation')}`;
      let message =
        // 'Vous avez été invité à partager une commande avec'
        `${t('EventBPage.Vous avez été invité à partager une commande avec')}`;

      +clientName + '.';
      message = message.concat(' ', 'Lien pour procéder : ' + link);
      const result = await Share.share({
        title: message,
        message: message,
      });
    } catch (error) {
      alert(error.message);
    }
  };

  const handleCreateGroupedOrder = async (paymentType) => {
    const products = JSON.parse(JSON.stringify(cart.products)); // Deep clone needed
    // Remove popup and Show Loader
    setGroupedOrderModalVisible(false);
    setShowLoading(false);
    // TODO: The following checks should be handled in backend
    // Add the Grouped Order in groupedorders collection in Firebase
    const groupedOrderParams = {
      owner: {
        userId: user.uid,
        products,
      },
      reservationId: '',
      eventId: show.id,
      paymentType,
      status: 'draft',
      users: [],
      createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
    };
    // Save it locally
    setGroupedOrderData(groupedOrderParams);
    setGroupedOrderIsOwner(true);

    // Push it to Firestore
    await db
      .collection('groupedorders')
      .add(groupedOrderParams)
      .then(async (docGO) => {
        // Setup a snapshot to follow changes
        await setupSnaphotGroupedOrder(docGO.id);
        // Add the active Grouped Order to the User
        await db.collection('users').doc(user.uid).set(
          {
            activeGroupedOrder: docGO.id,
          },
          {merge: true},
        );
        // Save the ID for later usedocdata
        setIsInActiveGroupedOrder(docGO.id);
        // TODO: Generate the dynamic link
        const link =
          links.webappurl +
          'shop?go=' +
          docGO.id +
          '&s=' +
          show.id +
          '&t=event';
        console.log('Link to be shared : ' + link);
        // Trigger the native share
        shareGroupedOrder(user.displayName, link, paymentType);
        setShowLoading(false);
      });
  };

  const submitClientNameInputModal = async () => {
    const params = route?.params;
    await db.collection('users').doc(user.uid).set(
      {
        displayName: clientName,
      },
      {merge: true},
    );
    setShowClientNameInputModal(false);
    if (waitingForClienNameInStep == 2) setGroupedOrderModalVisible(true);
    else if (waitingForClienNameInStep == 1 && params.go) {
      handleAcceptInviteToGroupedOrder(params.go, user.uid);
    }
  };

  useEffect(() => {
    const queryFn = async (eventId) => {
      setIsLoading(true);
      try {
        const eventDocRef = doc(db, 'events', eventId);
  
        // Create a reference to the priceDeformations subcollection
        const priceDeformationsRef = collection(eventDocRef, 'priceDeformations');
        const accessRestrictionsRef = collection(eventDocRef, 'accessRestrictions');
        
        
        // Setup listeners for both event and price deformations
        const unsubscribeFromEvent = onSnapshot(eventDocRef, async (eventSnapshot) => {
          if (!eventSnapshot.exists()) {
            console.log('Event not found');
            setIsLoading(false);
            return;
          }
  
          const docdata = eventSnapshot.data();
          if (!docdata) {
            console.log('No data in event document');
            setIsLoading(false);
            return;
          }
  
          try {
            // Only proceed with organization fetch if we have an organization reference
            if (docdata.organisation) {
              const organisationDocRef = doc(db, 'organisers', docdata.organisation);
              const qUSers = await getDoc(organisationDocRef);
              if (qUSers.exists()) {
                setOwner(qUSers.data());
              } else {
                console.log('Organization not found');
              }
            }
  
            // Handle dates only if they exist
            const startDate = docdata.startDate?.toDate();
            const endDate = docdata.endDate?.toDate();
            const formattedDate = startDate && endDate ? getEventDateTime(startDate, endDate) : '';
  
            setShow({id: eventId, ...docdata});
            
            const eventData = {
              id: eventId,
              description: docdata.description || '',
              summary: docdata.summary || '',
              dateDisplay: formattedDate,
              descriptiontranslations: docdata?.descriptiontranslations || {},
              titletranslations: docdata?.titletranslations || {},
              title: docdata.title || '',
              imageUri: docdata.coverImage || '',
              address: docdata.address || '',
              location: docdata.location || '',
              subcategory: docdata.subcategory || '',
              capacity: docdata.capacity || 0,
              stopSellDate: docdata.stopSellDate || null,
            };
  
            setEvent(eventData);
          } catch (error) {
            console.error('Error processing event data:', error);
          }
        });
  
        // Add listener for price deformations subcollection
        const unsubscribeFromDeformations = onSnapshot(
          query(priceDeformationsRef, where('isActive', '==', true)),
          (deformationsSnapshot) => {
            try {
              if (!deformationsSnapshot.empty) {
                const deformations = deformationsSnapshot.docs
                  .map(doc => ({
                    id: doc.id,
                    ...doc.data()
                  }))
                  .filter(deformation => {
                    // Additional validation of deformation data
                    return (
                      deformation.name &&
                      deformation.condition?.type === 'EMAIL_DOMAIN' &&
                      Array.isArray(deformation.condition?.domains) &&
                      deformation.deformationType &&
                      typeof deformation.deformationValue === 'number' &&
                      typeof deformation.priority === 'number'
                    );
                  })
                  .sort((a, b) => b.priority - a.priority);
  
                //setPriceDeformations(deformations);
                console.log(deformations);
                if (deformations.length > 0) {
                  setShowDeformationModal(true);
                }
              } else {
                setPriceDeformations([]);
              }
            } catch (error) {
              console.error('Error processing price deformations:', error);
              setPriceDeformations([]);
            }
          },
          (error) => {
            console.error('Error in price deformations subscription:', error);
            setPriceDeformations([]);
          }
        );

        const unsubscribeFromAccessRestrictions = onSnapshot(
          query(accessRestrictionsRef, where('isActive', '==', true)),
          (restrictionsSnapshot) => {
            try {
              if (!restrictionsSnapshot.empty) {
                const restrictions = restrictionsSnapshot.docs
                  .map(doc => ({
                    id: doc.id,
                    ...doc.data()
                  }))
                  .filter(restriction => {
                    // Validate restriction data
                    return (
                      restriction.name &&
                      restriction.condition?.type === 'EMAIL_DOMAIN' &&
                      Array.isArray(restriction.condition?.domains) &&
                      typeof restriction.priority === 'number' &&
                      restriction.type === 'ACCESS_RESTRICTION'
                    );
                  })
                  .sort((a, b) => b.priority - a.priority);
  
                setAccessRestrictions(restrictions);
                if (restrictions.length > 0) {
                  setShowAccessRestrictionModal(true);
                }
              } else {
                setAccessRestrictions([]);
              }
            } catch (error) {
              console.error('Error processing access restrictions:', error);
              setAccessRestrictions([]);
            }
          }
        );
  
        // Check for hidden ticket access
        const { h: encryptedToken } = route.params || {};
        const isHiddenAccess = !!encryptedToken;
  
        let ticketsQuery = query(collection(db, 'events', eventId, 'tickets'));
        
        const unsubscribeFromTickets = onSnapshot(ticketsQuery, (querySnapshot) => {
          try {
            if (!querySnapshot.empty) {
              const tickets = querySnapshot.docs.map((ticketsDoc) => {
                const ticketData = ticketsDoc.data();
                if (!ticketData) return null;
  
                const PlatformFee = ticketData.includePlatformFees
                  ? ticketData.platformFee
                  : null;
                const price = ticketData.price || 0;
                
                return {
                  id: ticketsDoc.id,
                  ...ticketData,
                  platformFee: PlatformFee,
                  priceAfterFeePlatform: ticketData.includePlatformFees
                    ? price +  PlatformFee
                    : null,
                };
              }).filter(Boolean); // Remove any null entries
              
              // If we have hidden access, filter out non-hidden tickets
              const filteredTickets = isHiddenAccess
                ? tickets.filter(ticket => ticket.isHidden === true)
                : tickets.filter(ticket => ticket.isHidden !== true);
  
              setTickets(filteredTickets);
            } else {
              setTickets(null);
            }
          } catch (error) {
            console.error('Error processing tickets:', error);
            setTickets(null);
          }
        }, (error) => {
          console.error('Error in tickets subscription:', error);
          setTickets(null);
        });
  
        // Handle referral with proper check
        if (route?.params?.referralid) {
          setReferral(route.params.referralid);
        }
  
        // Handle auditorium if applicable
        if (route.params?.item?.auditoriumid) {
          try {
            const audRef = doc(db, 'auditoriums', route.params.item.auditoriumid);
            const audDoc = await getDoc(audRef);
            if (audDoc.exists()) {
              const audit = { id: audDoc.id, ...audDoc.data() };
              setAudit(audit);
              setShow(route.params.item);
            }
          } catch (error) {
            console.error('Error fetching auditorium:', error);
          }
        }
  
        setIsLoading(false);
  
        // Cleanup function to unsubscribe from all listeners
        return () => {
          unsubscribeFromEvent();
          unsubscribeFromDeformations();
          unsubscribeFromAccessRestrictions();
          unsubscribeFromTickets();
        };
      } catch (error) {
        console.error('Error in queryFn:', error);
        setIsLoading(false);
      }
    };
  
    if (route?.params?.s) queryFn(route.params.s);
  }, [route, user]);


  const handleAccessRestrictionModalClose = () => {
    // If user closes without selecting, clean up
    if (!selectedAccessRestriction) {
      cleanupCartAndRestrictions();
    }
    setShowAccessRestrictionModal(false);
  };

  const handleAccessRestrictionSelect = (email, restriction) => {
    const restrictionInfo = {
      restriction,
      verifiedEmail: email
    };
    
    // Process the change - cart filtering happens automatically in reducer
    setAccessRestriction(restrictionInfo);
    setSelectedAccessRestriction({
      ...restriction,
      verifiedEmail: email
    });
    setShowAccessRestrictionModal(false);
  
    // Display warning only in selector if products were removed
    const removedProducts = cart.products.filter(product => 
      product.accessRestrictions?.length > 0 && 
      !product.accessRestrictions.some(r => r.id === restriction.id)
    );
    
    // if (removedProducts.length > 0) {
    //   toast.warning(t('EventBPage.Some restricted items were removed from your cart'));
    // }
  };

  // Add handler for clearing restriction
  const handleClearAccessRestriction = () => {
    clearAccessRestriction();
    setSelectedAccessRestriction(null);
  };



  useEffect(() => {
    handleGroupedOrderInitialStatus();
    // return () => {
    //   return unsubscribeListener.current && unsubscribeListener.current();
    // };
  }, [isInActiveGroupedOrder, focus]);

  useEffect(() => {
    if (
      isInActiveGroupedOrder &&
      groupedOrderData &&
      cart?.products?.length > 0
    ) {
      addProductsToGroupedOrder(cart.products);
    }
  }, [cart, isInActiveGroupedOrder, groupedOrderData]);


  const handleDeformationSelect = (email, deformation) => {
    setSelectedDeformation(deformation);
    setShowDeformationModal(false);
    
    // Also update cart prices if there are items in cart
    if (cart.products.length > 0) {
      const updatedProducts = cart.products.map(product => {
        const deformedPrice = deformation.deformationType === 'PERCENTAGE'
          ? product.price * (1 - deformation.deformationValue / 100)
          : product.price - deformation.deformationValue;
        
        return {
          ...product,
          deformedPrice,
          total: deformedPrice * product.quantity,
          deformedPriceHt: deformedPrice / (1 + (product.tvaRate || 0) / 100),
          deformedPlatformFee: product.includePlatformFees 
            ? deformedPrice * (product.platformFee || 0) / 100 
            : 0,
          deformedFinalPrice: product.includePlatformFees
            ? deformedPrice + (deformedPrice * (product.platformFee || 0) / 100)
            : deformedPrice
        };
      });
      
      // Update cart with deformed prices
      emptyCart();
      updatedProducts.forEach(product => {
        addProduct(product);
      });
    }
  };

  const getTicketPrice = (ticket) => {
    if (selectedDeformation) {
      // Calculate base deformed price
      const baseDeformedPrice = selectedDeformation.deformationType === 'PERCENTAGE'
        ? ticket.price * (1 - selectedDeformation.deformationValue / 100)
        : ticket.price - selectedDeformation.deformationValue;
  
      // Add platform fees if applicable
      if (ticket.includePlatformFees) {
        const platformFee = baseDeformedPrice * (ticket.platformFee || 0) / 100;
        return baseDeformedPrice + platformFee;
      }
      
      return baseDeformedPrice;
    }
    
    // Return original price with platform fees if applicable
    return ticket.includePlatformFees 
      ? ticket.finalPrice
      : ticket.price;
  };

  const renderCartButton = () => {
    let cartText = t('EventBPage.Voir le panier');
    if (isInActiveGroupedOrder) {
      cartText = t('EventBPage.Afficher la commande groupée');
    }
    const hasProducts = groupedOrderData?.users?.some(
      (user) => user?.products && user?.products?.length > 0,
    );
    // console.log('hasProducts', hasProducts);
    // console.log('groupedOrderData?.users', groupedOrderData?.users);
    // const active = true;
    if (cart.products.length > 0 || hasProducts || groupedOrderIsOwner) {
      let total = cart.products.reduce(function (prev, cur) {
        if (__DEV__) {
          console.log('cur');
          console.log(cur);
        }
        if (cur?.includePlatformFees && cur?.priceAfterFeePlatform) {
          return (
            prev +
            (cur.priceAfterFeePlatform === -1 ? 0 : cur.priceAfterFeePlatform)
          );
        }
        return prev + (cur.price === -1 ? 0 : cur.total);
      }, 0);

      return (
        <View style={{marginVertical: 10}}>
          <ButtonA onPress={handleAddToCart}>
            <CartSVG
              style={{
                width: 18,
                height: 18,
                marginBottom: -2,
                marginRight: 10,
              }}
              stroke={Colors.background}
            />
            {cart.products.length === 0 && hasProducts
              ? `${t('EventBPage.Afficher la commande groupée')}`
              : `${locales.financial.currency.symbol} ${total.toFixed(
                  2,
                )} - ${cartText}`}
          </ButtonA>
        </View>
      );
    } else {
      return null;
    }
  };

  const renderGroupedOrderButton = () => {
    if (isInActiveGroupedOrder) {
      handleAddToCart();
    } else {
      checkClientHasName(2, () => handleNavigation());
    }
  };

  const hasAccessToTicket = useCallback((ticket) => {
    // If ticket has no access restrictions, it's always visible
    if (!ticket.accessRestrictions || ticket.accessRestrictions.length === 0) {
      return true;
    }
    // If no access restriction is selected/verified, hide restricted tickets
    if (!selectedAccessRestriction) {
      return false;
    }
    console.log('selectedAccessRestriction', selectedAccessRestriction);
    console.log('ticket.accessRestrictions', ticket.accessRestrictions);
    // Check if the ticket's restrictions include the verified restriction
    return ticket.accessRestrictions.some(
      restriction => restriction.id === selectedAccessRestriction.id
    );
  }, [selectedAccessRestriction]);

  const renderTickets = () => {
    if (!tickets) return null;

    console.log(tickets);

    const availableTickets = tickets.filter(ticket => hasAccessToTicket(ticket));
    console.log('availableTickets', availableTickets);

    return availableTickets?.sort((a, b) => a.order - b.order)
    .map((ticket, index) => {
      const minimumPerOrder = 0;
      const filteredProduct = cart.products.find(
        (product) => ticket.id === product.id,
      );

      let outDated = false;
      let comming = false;
      var today = new Date();

      ticket.soldQuantity = ticket?.soldQuantity || 0;
      ticket.maxQuantityPerOrder =
        ticket?.maxQuantityPerOrder ||
        ticket?.initialQuantity ||
        event?.capacity ||
        1000000000000;
      ticket.quantity = filteredProduct
        ? filteredProduct.quantity
        : 0;
      ticket.initialQuantity =
        ticket?.initialQuantity ||
        event?.capacity ||
        100000000000;
      ticket.isLimitless = !ticket.maxQuantityPerOrder;

      // Convert the start and end dates to <<JavaScript Date objects
      var startDate = new Date(
        ticket.SellTimeLimit?.start?.seconds * 1000,
      );
      var endDateTicket = new Date(
        ticket.SellTimeLimit?.end?.seconds * 1000,
      );

      var eventStopSellDate = new Date(
        event.stopSellDate?.seconds * 1000,
      );

      // Check if today's date is between the start and end dates
      if (
        endDateTicket <= today ||
        eventStopSellDate <= today
      ) {
        outDated = true;
      } else if (startDate >= today) {
        comming = true;
      }

      let count = minimumPerOrder;

      let isMinimumPerOrderNotRespect = false;

      const maxOrderTickets =
        ticket?.initialQuantity - ticket?.soldQuantity;
      if (filteredProduct) {
        if (
          filteredProduct.quantity <
          filteredProduct.minQuantityPerOrder
        ) {
          isMinimumPerOrderNotRespect = true;
        } else isMinimumPerOrderNotRespect = false;
        if (
          !ticket.isLimitless &&
          filteredProduct.quantity > maxOrderTickets
        ) {
          filteredProduct.quantity = maxOrderTickets;
          count = maxOrderTickets;
        } else {
          count = filteredProduct.quantity;
        }
      }
      const notAvailable =
        comming ||
        outDated ||
        ticket.initialQuantity <= ticket?.soldQuantity;

      return (
        <TicketCard
          ticket={ticket}
          count={count}
          index={index}
          notAvailable={notAvailable}
          comming={comming}
          outDated={outDated}
          maxOrderTickets={maxOrderTickets}
          isMinimumPerOrderNotRespect={isMinimumPerOrderNotRespect}
          selectedLanguage={selectedLanguage}
          handleIncrease={handleIncrease}
          handleDecrease={handleDecrease}
          price={getTicketPrice(ticket)}
          priceDeformation={selectedDeformation} // Pass the entire deformation object
          currentPrice={getTicketPrice(ticket)}
          originalPrice={ticket.price}
          hasDeformation={!!selectedDeformation}
          />

      );
    })
  };


  if (isLoading) {
    return (
      <SafeAreaView style={styles.screenContainer}>
        <StatusBar
          backgroundColor={Colors.statusBarColor}
          barStyle="dark-content"
        />

        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <ActivityIndicator size="large" color="green" />
        </View>
      </SafeAreaView>
    );
  }

  return (
    <SafeAreaView style={styles.screenContainer}>
      <StatusBar
        backgroundColor={Colors.statusBarColor}
        barStyle="dark-content"
      />
      {isInActiveGroupedOrder && groupedOrderData?.eventId === eventId && (
        <View
          style={{
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            background: Colors.blue,
          }}>
          <Text style={[styles.buttonText, {color: Colors.white, padding: 10}]}>
            {t('EventBPage.Commande de groupe activée')}
          </Text>
        </View>
      )}
      <ScrollView
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.contentContainerStyle}>
        <ProgressiveImage
          // thumbnailSource={imgHolder}
          thumbnailSource={getImgSource(event?.imageUri)}
          source={getImgSource(event?.imageUri)}
          style={styles.coverImage}
          resizeMode="cover"
          rounded={false}
        />
        {/* <Image
          defaultSource={getImgSource(event?.imageUri)}
          source={getImgSource(event?.imageUri)}
          style={styles.coverImage}
          resizeMode="cover"
        /> */}

        <View style={styles.container}>
          <View style={styles.eventNameContainer}>
            <View>
              <Text style={styles.eventName}>
                {event?.title}
              </Text>
            </View>
          
          <View style={{flexDirection: 'row', alignItems: 'center', gap: 6}}>
            <FavoriteSVG
              fill={isFavorite ? Colors.darkGreen : Colors.normalGray}
              style={styles.favorite}
            />
            
            <ButtonA
              style={{
                backgroundColor: Colors.background,
                borderWidth: 1,
                borderColor: Colors.normalGray,
                paddingVertical: 7,
                paddingHorizontal: 7,
              }}
              color={Colors.normalGray}
              onPress={() =>
                navigation.navigate('ChangeLangue', {
                  fromEvent: true,
                })
              }>
              <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <Image
                  source={flag}
                  style={{
                    width: 26,
                    height: 26,
                    borderRadius: 23,
                    backgroundColor: 'white',
                    marginRight: 7,
                    borderColor: Colors.primaryColor,
                  }}
                />
                <BodyA>{languageLabel}</BodyA>
              </View>
            </ButtonA>
          </View>
        </View>
        <View style={styles.contentContainer}>
              <SubheadingA style={{color: Colors.darkGray, marginBottom: 10}}>
                {t('EventBPage.Organisé par')}{' '}
              </SubheadingA>

              <View style={[styles.ownerDetails, styles.ownerDetailsRow]}>
                <View style={styles.ownerInfoContainer}>
                  <ProgressiveImage
                    thumbnailSource={getImgSource(owner?.logoImage)}
                    source={getImgSource(owner?.logoImage)}
                    style={styles.ownerImage}
                    resizeMode="cover"
                    rounded={true}
                  />
                  
                  <View style={styles.ownerTextContainer}>
                    <BodyA
                      style={{
                        ...styles.bodyA,
                        fontFamily: 'SemiBold',
                        color: Colors.darkGray,
                        marginLeft: 12,
                      }}>
                      {owner?.name}
                    </BodyA>
                  </View>
                </View>
              </View>
            </View>

        {/* Date and Time */}
        <View style={styles.bodyRow}>
          <CalendarSVG style={styles.rowIcon} />
          <BodyA style={styles.info}>{event?.dateDisplay}</BodyA>
        </View>

        {/* Venue */}
        <View style={styles.bodyRow}>
          <LocationSVG style={styles.rowIcon} />
          <View>
            <BodyA style={styles.info}>{event?.address}</BodyA>
          </View>
        </View>
          
          {/* <View style={styles.eventNameContainer}>
            <View>
              <TitleA>{event?.title}</TitleA>
            </View>
            <View style={{flexDirection: 'row', alignItems: 'center', gap: 6}}>
              <FavoriteSVG
                fill={isFavorite ? Colors.darkGreen : Colors.normalGray}
                style={styles.favorite}
              />

              <ButtonA
                style={{
                  backgroundColor: Colors.background,
                  borderWidth: 1,
                  borderColor: Colors.normalGray,
                  paddingVertical: 7,
                  paddingHorizontal: 7,
                }}
                color={Colors.normalGray}
                onPress={() =>
                  navigation.navigate('ChangeLangue', {
                    fromEvent: true,
                  })
                }>
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                  <Image
                    source={flag}
                    style={{
                      width: 26,
                      height: 26,
                      borderRadius: 23,
                      backgroundColor: 'white',
                      marginRight: 7,
                      borderColor: Colors.primaryColor,
                    }}
                  />
                  <BodyA>{languageLabel}</BodyA>
                </View>
              </ButtonA>
            </View>
          </View> */}

          <View style={styles.cardContent}>
            {/* <View style={styles.contentContainer}>
              <SubheadingA style={styles.eventName}>
                {event?.location}
              </SubheadingA>
            </View> */}

            {/* <View style={styles.bodyRow}>
              <LocationSVG style={styles.rowIcon} />
              <ScrollView
                horizontal
                showsHorizontalScrollIndicator={false}
                style={styles.scrollView}>
                <BodyA style={styles.bodyA}>{event?.address}</BodyA>
              </ScrollView>
            </View> */}
            {/* <View style={styles.bodyRow}>
              <TimeSVG style={styles.rowIcon} />
              <BodyA style={styles.bodyA}>{event?.dateDisplay}</BodyA>
            </View> */}
            <View style={styles.contentContainer}>
              <SubheadingA style={{color: Colors.darkGray, marginBottom: 10}}>
                {t('EventBPage.À propos')}
              </SubheadingA>
              {event?.descriptiontranslations &&
                typeof event?.descriptiontranslations === 'object' && (
                  <BodyA style={styles.bodyA}>
                    <RenderHtml
                      contentWidth={deviceWidth}
                      source={{                                                       
                        html: getTranslatedText(event?.descriptiontranslations),
                      }}
                    />
                  </BodyA>
                )}
            </View>

            

            <View style={styles.contentContainer}>
              <SubheadingA style={{color: Colors.darkGray, marginBottom: 10}}>
                {t('EventBPage.Atmosphère')}
              </SubheadingA>

              <ScrollView
                horizontal
                showsHorizontalScrollIndicator={false}
                style={{marginBottom: 20}}>
                <View>
                  <ButtonA style={styles.vibe} color={Colors.normalGray}>
                    {event?.subcategory}
                  </ButtonA>
                </View>
              </ScrollView>
            </View>
            {/* Price Level Selector */}
              {priceDeformations.length > 0 && (
                <PriceLevelSelector
                  selectedDeformation={selectedDeformation}
                  priceDeformations={priceDeformations}
                  onDeformationSelect={(deformation) => handleDeformationSelect(null, deformation)}
                  onReset={() => {
                    setSelectedDeformation(null);
                    // Reset cart prices if needed
                    if (cart.products.length > 0) {
                      const updatedProducts = cart.products.map(product => ({
                        ...product,
                        deformedPrice: null,
                        deformedPriceHt: null,
                        deformedPlatformFee: null,
                        deformedFinalPrice: null,
                        total: product.price * product.quantity
                      }));
                      
                      emptyCart();
                      updatedProducts.forEach(product => {
                        addProduct(product);
                      });
                    }
                  }}
                />
              )}
              {accessRestrictions.length > 0 && (
                <View style={styles.contentContainer}>
                  <SubheadingA style={{color: Colors.darkGray, marginBottom: 10}}>
                    {t('EventBPage.Access Restrictions')}
                  </SubheadingA>
                  
                  {selectedAccessRestriction ? (
                    <AccessRestrictionSelector
                    selectedRestriction={cart.accessRestriction.restriction}
                    verifiedEmail={cart.accessRestriction.verifiedEmail}
                    onChangeClick={() => setShowAccessRestrictionModal(true)}
                    onClearClick={handleClearAccessRestriction}
                  />
                  ) : (
                    <ButtonA
                      onPress={() => setShowAccessRestrictionModal(true)}
                      style={styles.verifyButton}
                      color={Colors.white}
                    >
                      {t('EventBPage.Verify Access')}
                    </ButtonA>
                  )}
                </View>
              )}

            {isInActiveGroupedOrder && groupedOrderData?.eventId !== eventId ? (
              <SafeAreaView style={styles.screenContainer}>
                <StatusBar
                  backgroundColor={Colors.statusBarColor}
                  barStyle="dark-content"
                />

                <View
                  style={{
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <View style={{marginVertical: 10}}>
                    <ButtonA onPress={handleAddToCart}>
                      <CartSVG
                        style={{
                          width: 18,
                          height: 18,
                          marginBottom: -2,
                          marginRight: 10,
                        }}
                        stroke={Colors.background}
                      />
                      {/* Afficher la commande groupée */}
                      {t('EventBPage.Afficher la commande groupée')}
                    </ButtonA>
                  </View>
                </View>
              </SafeAreaView>
            ) : (
              <>
                <View style={styles.cartContainer}>
                  {renderTickets()}
                </View>

                <View style={styles.total}>
                  <TitleA
                    style={{
                      fontSize: 20,
                      lineHeight: 30,
                      letterSpacing: 0.4,
                    }}>
                    <Text>{t('EventBPage.Total')}</Text>
                  </TitleA>
                  <TitleA
                    style={{
                      fontSize: 20,
                      lineHeight: 30,
                      letterSpacing: 0.4,
                      color: Colors.darkGreen,
                    }}>
                    <Text>{`€ ${totalPrice.toFixed(2)}`}</Text>
                  </TitleA>
                </View>

                {/*!isInActiveGroupedOrder && (
                  <View style={{marginVertical: 10}}>
                    <ButtonA
                      style={{backgroundColor: Colors.blue}}
                      onPress={() => renderGroupedOrderButton()}>
                      <AddUserSVG
                        style={{
                          width: 18,
                          height: 18,
                          marginBottom: -2,
                          marginRight: 10,
                        }}
                      />
                      {t('EventBPage.Commande groupée')}
                    </ButtonA>
                  </View>
                      )*/}
                {renderCartButton()}
              </>
            )}
          </View>
        </View>

        {/* to render google maps view billing account should be configured !!! */}
        <View style={{flex: 1}}>
          <MapView />
        </View>
      </ScrollView>
      <InfoModal
        title={infoModal.infoModalTitle.toUpperCase()}
        message={infoModal.infoModalMessage}
        iconName={infoModal.infoModalIcon}
        onButtonPress={() =>
          setInfoModal((prev) => ({...prev, infoModalVisible: false}))
        }
        onRequestClose={() =>
          setInfoModal((prev) => ({...prev, infoModalVisible: false}))
        }
        buttonTitle={'Ok'.toUpperCase()}
        visible={infoModal.infoModalVisible}
      />
      <ModalA
        visible={showClientNameInputModal}
        onDismiss={() => setShowClientNameInputModal(false)}>
        <View>
          <TitleA style={{marginBottom: 10, paddingHorizontal: 10}}>
            {t('EventBPage.Vous êtes invité à joindre une commande groupée')}
          </TitleA>
          <SubheadingA style={{marginBottom: 10, paddingHorizontal: 10}}>
            {/* comment devrions-nous vous appeler ? */}
            {t('EventBPage.comment devrions-nous vous appeler ?')}
          </SubheadingA>
          <TextInput
            onChange={(e) => setClientName(e.target.value)}
            value={clientName}
            placeholder={t('EventBPage.Nom du client')}
            maxLength={50}
            style={styles.textInput}
          />
          <ButtonA
            onPress={() => setShowClientNameInputModal(false)}
            style={{marginVertical: 20}}>
            {t('EventBPage.Annuler')}
          </ButtonA>
          <ButtonA
            onPress={() => submitClientNameInputModal()}
            style={{marginVertical: 20}}>
            {t('EventBPage.Soumettre')}
          </ButtonA>
        </View>
      </ModalA>
      <AccessRestrictionModal
        visible={showAccessRestrictionModal}
        onClose={handleAccessRestrictionModalClose}
        accessRestrictions={accessRestrictions}
        onValidateEmail={handleAccessRestrictionSelect}
        currentRestriction={selectedAccessRestriction}
      />
      {/* <ModalA
        visible={showAccessRestrictionModal}
        onDismiss={() => setShowAccessRestrictionModal(false)}
      >
        <View style={styles.modalContent}>
          <TitleA style={styles.modalTitle}>
            {t('EventBPage.Verify Access')}
          </TitleA>
          <SubheadingA style={styles.modalSubtitle}>
            {t('EventBPage.Please verify your email to access restricted tickets')}
          </SubheadingA>
          <TextInput
            value={userEmail}
            onChangeText={setUserEmail}
            placeholder="Enter your email"
            style={styles.emailInput}
          />
          {accessRestrictions.map((restriction) => (
            <ButtonA
              key={restriction.id}
              onPress={() => handleAccessRestrictionSelect(userEmail, restriction)}
              style={styles.restrictionButton}
            >
              {restriction.name}
            </ButtonA>
          ))}
        </View>
      </ModalA> */}
      <ModalA
        visible={groupedOrderModalVisible}
        onDismiss={() => setGroupedOrderModalVisible(false)}>
        <View>
          <TitleA style={{marginBottom: 10, paddingHorizontal: 10}}>
            {t('EventBPage.Partage de panier')}
          </TitleA>
          <SubheadingA style={{marginBottom: 10, paddingHorizontal: 10}}>
            {t(
              'EventBPage.Vous souhaitez partager la facture ou la prendre en charge ?totalement?',
            )}
          </SubheadingA>
          <ButtonA
            onPress={() => handleCreateGroupedOrder('shared')}
            style={{marginVertical: 20}}>
            {t('EventBPage.partagé')}
          </ButtonA>
          <ButtonA
            onPress={() => handleCreateGroupedOrder('covered')}
            style={{marginVertical: 20}}>
            {t('EventBPage.Couvert')}
          </ButtonA>
        </View>
      </ModalA>
      {/* <PriceDeformationModal
        visible={showDeformationModal}
        onClose={() => setShowDeformationModal(false)}
        priceDeformations={priceDeformations}
        onValidateEmail={handleDeformationSelect}
      /> */}
    </SafeAreaView>
  );
};

const mapStateToProps = (state) => {
  const {cart, prefs, event, commandeGroupe} = state;
  return {cart, prefs, event, commandeGroupe};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addProduct,
      emptyCart,
      setEvent,
      setReferral,
      addCommandeGroupe,
      setAccessRestriction,
      clearAccessRestriction,
      cleanupCartAndRestrictions,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(EventB);
