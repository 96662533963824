import React, {createContext, useState, useContext} from 'react';

const LanguageContext = createContext();

export const LanguageProvider = ({children}) => {
  const [selectedLanguage, setSelectedLanguage] = useState('fr');

  return (
    <LanguageContext.Provider value={{selectedLanguage, setSelectedLanguage}}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
