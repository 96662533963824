import * as React from 'react';
import Svg, {Rect} from 'react-native-svg';

const CheckboxSVG = (props) => {
  const {style, ...restProps} = props;

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      fill="none"
      style={style}
      {...restProps}>
      <Rect width={17} height={17} x={0.5} y={0.5} stroke="#77838F" rx={2.5} />
    </Svg>
  );
};

export default CheckboxSVG;
