import React from 'react';
import AccountRemoveSVG from '../../components/svgs/AccountRemoveSVG';
import ClipboardSVG from '../../components/svgs/ClipboardSVG';
import InfoSVG from '../../components/svgs/InfoSVG';
import LogoutSVG from '../../components/svgs/LogoutSVG';
import PenThinSVG from '../../components/svgs/PenThinSVG';
import ShieldLineSVG from '../../components/svgs/ShieldLineSVG';
import TicketsLineSVG from '../../components/svgs/TicketsLineSVG';
import EventsIconSVG from '../../components/svgs/EventsIconSVG';
import PizzaSVG from '../../components/svgs/PizzaSVG';
import CourseSVG from '../../components/svgs/CourseSVG';
import ShoppingSVG from '../../components/svgs/ShoppingSVG';
import MarketSVG from '../../components/svgs/MarketSVG';
import DeliverySVG from '../../components/svgs/DeliverySVG';

export const getAllServicesData = (t) => [
  {
    id: '1',
    icon: <EventsIconSVG />,
    title: t('AllServicesData.Événements'),
    routeName: 'Home',
  },
  {
    id: '2',
    icon: <PizzaSVG />,
    title: t('AllServicesData.Restaurants'),
    routeName: 'Restaurants',
  },
  // {
  //   id: '3',
  //   icon: <CourseSVG />,
  //   title: t('AllServicesData.Course'),
  //   routeName: 'Course',
  // },
  // {
  //   id: '4',
  //   icon: <ShoppingSVG />,
  //   title: t('AllServicesData.Achats'),
  //   routeName: 'Shopping',
  // },
  // {
  //   id: '5',
  //   icon: <MarketSVG />,
  //   title: t('AllServicesData.Marché'),
  //   routeName: 'Market',
  // },
  // {
  //   id: '6',
  //   icon: <DeliverySVG />,
  //   title: t('AllServicesData.Livraison'),
  //   routeName: 'Delivery',
  // },
];
