import Svg, {G, Path, Defs, ClipPath} from 'react-native-svg';
import React from 'react';
const StarFillNotSelectedSVG = ({width, height}) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 26 25"
      fill="none">
      <Path
        d="M18.4167 21.6145C18.2892 21.6143 18.1639 21.5821 18.0521 21.5208L13 18.8645L7.94796 21.5208C7.81958 21.5897 7.67448 21.6214 7.52907 21.6122C7.38365 21.603 7.2437 21.5533 7.12504 21.4687C7.00656 21.3819 6.91459 21.2638 6.8594 21.1276C6.80421 20.9915 6.78798 20.8427 6.81254 20.6979L7.79171 15.0729L3.68754 11.0937C3.58468 10.9907 3.51262 10.8611 3.47949 10.7194C3.44637 10.5777 3.45348 10.4295 3.50004 10.2916C3.54365 10.1532 3.62544 10.0299 3.73601 9.93596C3.84657 9.84198 3.98142 9.78112 4.12504 9.76038L9.77087 8.93746L12.3021 3.82288C12.3742 3.70202 12.4764 3.60193 12.5987 3.53243C12.721 3.46293 12.8593 3.42639 13 3.42639C13.1407 3.42639 13.279 3.46293 13.4014 3.53243C13.5237 3.60193 13.6259 3.70202 13.698 3.82288L16.2292 8.93746L21.875 9.76038C22.0187 9.78112 22.1535 9.84198 22.2641 9.93596C22.3746 10.0299 22.4564 10.1532 22.5 10.2916C22.5466 10.4295 22.5537 10.5777 22.5206 10.7194C22.4875 10.8611 22.4154 10.9907 22.3125 11.0937L18.2084 15.0729L19.1771 20.6979C19.2017 20.8427 19.1854 20.9915 19.1303 21.1276C19.0751 21.2638 18.9831 21.3819 18.8646 21.4687C18.7327 21.5599 18.577 21.6106 18.4167 21.6145ZM13 17.2083C13.1261 17.2098 13.2504 17.2382 13.3646 17.2916L17.375 19.375L16.6146 14.9062C16.594 14.7809 16.603 14.6525 16.641 14.5312C16.679 14.41 16.7449 14.2994 16.8334 14.2083L20.0834 11.0833L15.5938 10.4166C15.4696 10.3969 15.352 10.3478 15.2505 10.2736C15.1491 10.1993 15.0668 10.1019 15.0105 9.98954L13 5.92704L10.9896 9.99996C10.9333 10.1124 10.851 10.2097 10.7496 10.284C10.6481 10.3582 10.5304 10.4073 10.4063 10.427L5.91671 11.0833L9.16671 14.2083C9.25518 14.2994 9.32106 14.41 9.35905 14.5312C9.39704 14.6525 9.40608 14.7809 9.38546 14.9062L8.62504 19.4062L12.6355 17.3229C12.7472 17.2586 12.8716 17.2195 13 17.2083Z"
        fill="#FFB400"
      />
    </Svg>
  );
};
export default StarFillNotSelectedSVG;
