import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

const AccountRemoveSVG = (props) => {
  const {style, ...restProps} = props;

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 36 36"
      style={style}
      {...restProps}>
      <Path
        stroke="#FF5E75"
        strokeLinecap="round"
        strokeWidth={2}
        d="M4.5 31.5V30a6 6 0 0 1 6-6h6a6 6 0 0 1 6 6v1.5M13.5 19.5a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9ZM22.5 9h9"
      />
    </Svg>
  );
};
export default AccountRemoveSVG;
