import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import enTranslation from './en.json';
import frTranslation from './fr.json';
import ptTranslation from './pt.json';
import spTranslation from './sp.json';
import deTranslation from './de.json';
const resources = {
  en: {translation: enTranslation},
  fr: {translation: frTranslation},
  pt: {translation: ptTranslation},
  es: {translation: spTranslation},
  de: {translation: deTranslation},
};

i18n.use(initReactI18next).init({
  compatibilityJSON: 'v3',
  resources,
  lng: 'fr',

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;

// i18n.js
// import i18n from 'i18next';
// import {initReactI18next} from 'react-i18next';

// // Importez les fichiers de traduction ici
// import translationEN from './en.json';
// import translationFR from './fr.json';

// const resources = {
//   en: {
//     translation: translationEN,
//   },
//   fr: {
//     translation: translationFR,
//   },
// };
// console.log('ressources', resources);
// i18n?.use(initReactI18next)?.init({
//   resources,
//   lng: 'fr', // Langue par défaut
//   fallbackLng: 'fr', // Langue de secours
//   keySeparator: false, // Pour utiliser des points dans les clés (par exemple, 'common.header')
//   interpolation: {
//     escapeValue: false,
//   },
// });

// export default i18n;
