import React from 'react';
import {
  SafeAreaView,
  StatusBar,
  StyleSheet,
  View,
  FlatList,
  Image,
} from 'react-native';
import {useTranslation} from 'react-i18next';
import styles from './ChangeLangueStyles';
import Colors from '../../../theme/colors';
import TouchableItem from '../../../components/TouchableItem';

import SubheadingA from '../../../components/subheadingA/SubheadingA';
import getImgSource from '../../../utils/getImgSource';
import englishFlag from '../../../../assets/images/englishFlag.png';
import frenchFlag from '../../../../assets/images/frenchFlag.png';
import spanishFlag from '../../../../assets/images/spanishFlag.png';
import portugueseFlag from '../../../../assets/images/portugueseFlag.png';
import germanyFlag from '../../../../assets/images/germanyFlag.png';
import {useLanguage} from '../../../contexts/languageEventsContext';
import {useNavigation} from '@react-navigation/native';
const Separator = () => {
  return <View style={styles.separator} />;
};

const ChangeLangue = ({route}) => {
  const {i18n, t} = useTranslation();
  const {setSelectedLanguage} = useLanguage();
  const navigation = useNavigation();
  const LANGUAGES = [
    {code: 'en', label: t('languages.english'), icon: englishFlag},
    {code: 'fr', label: t('languages.french'), icon: frenchFlag},
    {code: 'es', label: t('languages.spanish'), icon: spanishFlag},
    {code: 'pt', label: t('languages.portuguese'), icon: portugueseFlag},
    {code: 'de', label: t('languages.german'), icon: germanyFlag},
  ];

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    // navigation.goBack();

    setSelectedLanguage(language);
    if (route?.params?.fromEvent) navigation.goBack();
  };

  const renderItem = ({item}) => {
    const isActiveLanguage = i18n.language === item?.code;

    return (
      <TouchableItem
        onPress={() => {
          changeLanguage(item?.code);
          // navigation.navigate('Parameters');
        }}>
        <View style={styles.item}>
          <Image
            source={getImgSource(item.icon)}
            style={[
              styles.img,
              {
                borderWidth: isActiveLanguage ? 3 : 0,
              },
            ]}
            resizeMode="cover"
          />
          <SubheadingA
            style={{fontFamily: isActiveLanguage ? 'SemiBold' : 'Regular'}}>
            {item.label}
          </SubheadingA>
        </View>
      </TouchableItem>
    );
  };

  return (
    <SafeAreaView style={styles.screenContainer}>
      <StatusBar
        backgroundColor={Colors.statusBarColor}
        barStyle="dark-content"
      />
      <FlatList
        data={LANGUAGES}
        renderItem={renderItem}
        keyExtractor={(item) => item.code}
        contentContainerStyle={styles.container}
        ItemSeparatorComponent={() => <Separator />}
      />
    </SafeAreaView>
  );
};

export default ChangeLangue;
