import React, { useState, useEffect, useRef } from 'react';
import {
  View,
  Text,
  TextInput,
  ScrollView,
  TouchableOpacity,
  StyleSheet,
  SafeAreaView,
  Dimensions,
  Alert,
} from 'react-native';
import { useTranslation } from 'react-i18next';
import { db } from '../../services/firebase';
import Colors from '../../theme/colors';
import ButtonA from '../../components/buttonA/ButtonA';
import AlertModal from './AlertModal'; 

const windowWidth = Dimensions.get('window').width;
const horizontalPadding = 20;

const Survey = ({ route, navigation }) => {
  const { t } = useTranslation();
  const { eventId, reservationId } = route.params;
  const [survey, setSurvey] = useState(null);
  const [answers, setAnswers] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const scrollViewRef = useRef(null);
  const questionRefs = useRef({});

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertCallback, setAlertCallback] = useState(() => {});

  useEffect(() => {
    const fetchSurvey = async () => {
      try {
        const eventDoc = await db.collection('events').doc(eventId).get();
        const eventData = eventDoc.data();
        if (eventData && eventData.survey) {
          setSurvey(eventData.survey);
          const initialAnswers = {};
          const initialErrors = {};
          eventData.survey.questions.forEach((question, index) => {
            initialAnswers[index] = question.type === 'checkbox' ? [] : '';
            initialErrors[index] = false;
          });
          setAnswers(initialAnswers);
          setErrors(initialErrors);
        }
      } catch (error) {
        console.error('Error fetching survey:', error);
      }
    };

    fetchSurvey();
  }, [eventId]);

  const handleInputChange = (questionIndex, value) => {
    setAnswers(prevAnswers => ({
      ...prevAnswers,
      [questionIndex]: value
    }));
    setErrors(prevErrors => ({
      ...prevErrors,
      [questionIndex]: false
    }));
  };

  const handleCheckboxChange = (questionIndex, option) => {
    setAnswers(prevAnswers => {
      const currentAnswers = prevAnswers[questionIndex] || [];
      const newAnswers = currentAnswers.includes(option)
        ? currentAnswers.filter(item => item !== option)
        : [...currentAnswers, option];
      
      setErrors(prevErrors => ({
        ...prevErrors,
        [questionIndex]: newAnswers.length === 0
      }));

      return {
        ...prevAnswers,
        [questionIndex]: newAnswers
      };
    });
  };

  const validateAnswers = () => {
    const newErrors = {};
    let isValid = true;
    let firstErrorIndex = null;

    survey.questions.forEach((question, index) => {
      if (question.required) {
        const answer = answers[index];
        const isEmpty = 
          (question.type === 'checkbox' && answer.length === 0) ||
          (question.type !== 'checkbox' && (!answer || answer.trim() === ''));

        newErrors[index] = isEmpty;
        if (isEmpty && firstErrorIndex === null) {
          firstErrorIndex = index;
        }
        isValid = isValid && !isEmpty;
      }
    });

    setErrors(newErrors);

    if (firstErrorIndex !== null && questionRefs.current[firstErrorIndex]) {
      scrollViewRef.current?.scrollTo({
        y: questionRefs.current[firstErrorIndex].offsetY,
        animated: true
      });
    }

    return isValid;
  };


  const showAlert = (title, message, callback = () => {}) => {
    setAlertTitle(title);
    setAlertMessage(message);
    setAlertCallback(() => callback);
    setAlertVisible(true);
  };

  const hideAlert = () => {
    setAlertVisible(false);
    alertCallback();
  };

  const showThankYouAlert = () => {
    showAlert(
      t('survey.thankYouTitle'),
      t('survey.thankYouMessage'),
      () => navigation.navigate('HomeNavigator')
    );
  };

  const handleSubmit = async () => {
    if (!validateAnswers()) {
      showAlert(
        t('survey.validationErrorTitle'),
        t('survey.validationErrorMessage')
      );
      return;
    }

    setIsSubmitting(true);
    try {
      await db.collection('reservations').doc(reservationId).update({
        surveyAnswers: answers
      });
      showThankYouAlert();
    } catch (error) {
      console.error('Error submitting survey:', error);
      showAlert(
        t('survey.errorTitle'),
        t('survey.errorMessage')
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSkipSurvey = () => {
    showAlert(
      t('survey.skipSurveyTitle'),
      t('survey.skipSurveyMessage'),
      () => navigation.navigate('HomeNavigator')
    );
  };
  if (!survey) {
    return (
      <SafeAreaView style={styles.safeArea}>
        <Text>{t('survey.loading')}</Text>
      </SafeAreaView>
    );
  }

  return (
    <SafeAreaView style={styles.safeArea}>
      <ScrollView 
        ref={scrollViewRef}
        contentContainerStyle={styles.scrollContainer}
      >
        <View style={styles.container}>
          <Text style={styles.title}>{t('survey.eventSurvey')}</Text>
          {survey.questions.map((question, index) => (
            <View 
              key={index} 
              style={[styles.questionContainer, errors[index] && styles.errorContainer]}
              onLayout={(event) => {
                questionRefs.current[index] = {
                  offsetY: event.nativeEvent.layout.y
                };
              }}
            >
              <Text style={styles.questionText}>
                {question.text}
                {question.required && <Text style={styles.requiredStar}>*</Text>}
              </Text>
              {question.type === 'text' && (
                <TextInput
                  style={[styles.input, errors[index] && styles.inputError]}
                  onChangeText={(text) => handleInputChange(index, text)}
                  value={answers[index]}
                  placeholder={t('survey.enterAnswer')}
                />
              )}
              {question.type === 'longText' && (
                <TextInput
                  style={[styles.input, styles.longTextInput, errors[index] && styles.inputError]}
                  onChangeText={(text) => handleInputChange(index, text)}
                  value={answers[index]}
                  placeholder={t('survey.enterAnswer')}
                  multiline
                  numberOfLines={4}
                />
              )}
              {question.type === 'radio' && (
                <View>
                  {question.options.map((option, optionIndex) => (
                    <TouchableOpacity
                      key={optionIndex}
                      style={styles.radioOption}
                      onPress={() => handleInputChange(index, option)}
                    >
                      <View style={[styles.radioButton, answers[index] === option && styles.radioButtonSelected]}>
                        {answers[index] === option && <View style={styles.radioButtonInner} />}
                      </View>
                      <Text style={styles.radioText}>{option}</Text>
                    </TouchableOpacity>
                  ))}
                </View>
              )}
              {question.type === 'checkbox' && (
                <View>
                  {question.options.map((option, optionIndex) => (
                    <TouchableOpacity
                      key={optionIndex}
                      style={styles.checkboxOption}
                      onPress={() => handleCheckboxChange(index, option)}
                    >
                      <View style={[styles.checkbox, answers[index]?.includes(option) && styles.checkboxSelected]}>
                        {answers[index]?.includes(option) && <Text style={styles.checkmark}>✓</Text>}
                      </View>
                      <Text style={styles.checkboxText}>{option}</Text>
                    </TouchableOpacity>
                  ))}
                </View>
              )}
              {errors[index] && (
                <Text style={styles.errorText}>{t('survey.requiredField')}</Text>
              )}
            </View>
          ))}
          <ButtonA
            onPress={handleSubmit}
            disabled={isSubmitting}
            style={styles.submitButton}
          >
            {isSubmitting ? t('survey.submitting') : t('survey.submit')}
          </ButtonA>
          <ButtonA
            onPress={handleSkipSurvey}
            style={styles.skipButton}
          >
            {t('survey.skipSurvey')}
          </ButtonA>
        </View>
      </ScrollView>
      <AlertModal
        visible={alertVisible}
        title={alertTitle}
        message={alertMessage}
        onOk={hideAlert}
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: Colors.background,
  },
  scrollContainer: {
    flexGrow: 1,
  },
  container: {
    flex: 1,
    paddingHorizontal: horizontalPadding,
    paddingVertical: 20,
    width: windowWidth,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
    color: Colors.darkGray,
  },
  questionContainer: {
    marginBottom: 20,
    width: '100%',
    padding: 10,
    borderRadius: 5,
  },
  errorContainer: {
    backgroundColor: Colors.errorBackground,
    borderWidth: 1,
    borderColor: Colors.error,
  },
  questionText: {
    fontSize: 18,
    marginBottom: 10,
    color: Colors.darkGray,
  },
  requiredStar: {
    color: Colors.error,
    fontWeight: 'bold',
  },
  input: {
    borderWidth: 1,
    borderColor: Colors.lightGray,
    borderRadius: 5,
    padding: 10,
    fontSize: 16,
    width: '100%',
  },
  inputError: {
    borderColor: Colors.error,
  },
  longTextInput: {
    height: 100,
    textAlignVertical: 'top',
  },
  radioOption: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
    width: '100%',
  },
  radioButton: {
    height: 20,
    width: 20,
    borderRadius: 10,
    borderWidth: 2,
    borderColor: Colors.blue,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
  },
  radioButtonSelected: {
    borderColor: Colors.blue,
  },
  radioButtonInner: {
    height: 10,
    width: 10,
    borderRadius: 5,
    backgroundColor: Colors.blue,
  },
  radioText: {
    fontSize: 16,
    color: Colors.darkGray,
  },
  checkboxOption: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
    width: '100%',
  },
  checkbox: {
    height: 20,
    width: 20,
    borderWidth: 2,
    borderColor: Colors.blue,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
  },
  checkboxSelected: {
    backgroundColor: Colors.blue,
  },
  checkmark: {
    color: Colors.white,
    fontSize: 16,
  },
  checkboxText: {
    fontSize: 16,
    color: Colors.darkGray,
  },
  submitButton: {
    marginTop: 20,
    marginBottom: 10,
    backgroundColor: Colors.blue,
    width: '100%',
  },
  skipButton: {
    marginBottom: 40,
    backgroundColor: Colors.lightGray,
    width: '100%',
  },
  errorText: {
    color: Colors.error,
    fontSize: 14,
    marginTop: 5,
  },
});

export default Survey;