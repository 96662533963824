import React, { useContext, useState, useEffect, useReducer } from 'react';
import { NavigationContainer, getStateFromPath } from '@react-navigation/native';
// import MainNavigatorA or MainNavigatorB to preview design differnces
import MainNavigator from './MainNavigatorA';

import { ActivityIndicator, View } from 'react-native';

import RootStackNavigator from './RootStackNavigator';
import firebase from 'firebase/compat/app';
import { AuthContext } from '../contexts/context';

import * as Linking from 'expo-linking';
import { createStackNavigator } from '@react-navigation/stack';
import authReducer from '../reducers/authreducer';
const Stack = createStackNavigator();

export const navigationRef = React.createRef();

export function navigate(name, params) {
  navigationRef.current?.navigate(name, params);
}

// const DOMAIN_URL = "http://localhost:19006";
const DOMAIN_URL = 'https://instadrinkpwa.web.app';
const SUCCESS_URL = `${DOMAIN_URL}/CheckoutSuccess?sc_checkout=success`;
const FAILURE_URL = `${DOMAIN_URL}/CheckoutFailed?sc_checkout=cancel`;
const prefix = Linking.makeUrl('/');

export default function RoutesA({ theme }) {
  const { user, setUser, signInAnonymously } = useContext(AuthContext);
  const [fetching, setFetching] = useState(true);
  const [initializing, setInitializing] = useState(true);
  const [authState, dispatch] = useReducer(authReducer);

  const linking = {
    prefixes: [prefix],
    config: {
      screens: {
        //HomeNavigator: 'menu/:id',
        HomeNavigator: {
          screens: {
            Home: {
              screens: {
                AllServices: 'AllServices',
                Home: '',
                Event: 'shop',
                RestaurantPage: 'RestaurantPage',
              },
            },
            Parameters: {
              screens: {
                Parameters: {
                  screens: {
                    Command: 'Command',
                    Commandes: 'Commandes',
                  },
                },
              },
            },
            Orders: {
              screens: {
                Orders: {
                  screens: {
                    upcomingTickets: 'upcomingTickets',
                  },
                },
              },
            },
            Cart: {
              screens: {
                Cart: 'Cart',
                Bill: 'Bill',
                ArticlePayer: 'ArticlePayer',
              },
            },
          },
        },
        CheckoutSuccess: 'CheckoutSuccess',
        CheckoutFailed: 'CheckoutFailed',
      },
    },
  };
  // Handle user state changes
  function onAuthStateChanged(user) {
    setFetching(true);
    //if(__DEV__){
    console.log('setting user');
    //console.log(user);
    //}

    setUser(user);
    if (initializing) {
      setInitializing(false);
    } else {
      setFetching(false);
    }
  }

  const handleOpenURL = (event) => {
    //console.log(event);
    if (event.url === SUCCESS_URL) {
      navigate('CheckoutSuccess');
    }
    if (event.url === FAILURE_URL) {
      navigate('CheckoutFailed');
    }
  };

  useEffect(() => {
    Linking.addEventListener('url', handleOpenURL);

    const subscriber = firebase.auth().onAuthStateChanged(onAuthStateChanged);
    return () => {
      Linking.removeEventListener('url', handleOpenURL);
      subscriber;
    };
    //return subscriber; // unsubscribe on unmount
  }, [initializing]);

  console.log('user ===>', user);

  if (!user) {
    if (__DEV__) {
      //console.log("application is loading");
      //console.log(user);
    }

    return (
      <View style={{ paddingTop: 30 }}>
        <ActivityIndicator size="large" color="green" />
      </View>
    );
  } else {
    //alert('entering routes1');
    if (__DEV__) {
      //console.log("application is not loading");
      //console.log(user);
    }
    return (
      <NavigationContainer linking={linking} ref={navigationRef}>
        {user ? <MainNavigator /> : <RootStackNavigator />}
      </NavigationContainer>
    );
  }
}
