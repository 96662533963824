import React, { useState } from 'react';
import {
  View,
  Text,
  Modal,
  TouchableOpacity,
  TextInput,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  TouchableWithoutFeedback,
} from 'react-native';
import Colors from '../../theme/colors';
import ButtonA from '../../components/buttonA/ButtonA';
import TitleA from '../../components/titleA/TitleA';
import SubheadingA from '../../components/subheadingA/SubheadingA';
import BodyA from '../../components/bodyA/BodyA';
import { useTranslation } from 'react-i18next';

const PriceDeformationModal = ({
  visible,
  onClose,
  priceDeformations,
  onDeformationSelect,
  onValidateEmail
}) => {
  const [selectedDeformation, setSelectedDeformation] = useState(null);
  const [email, setEmail] = useState('');
  const [step, setStep] = useState('select'); // 'select' | 'validate'
  const [error, setError] = useState('');
  const { t } = useTranslation();

  const handleDeformationSelect = (deformation) => {
    setSelectedDeformation(deformation);
    setStep('validate');
    setError('');
  };

  const handleEmailSubmit = () => {
    const emailDomain = email.split('@')[1];
    if (!emailDomain) {
      setError(t("priceDeformation.invalidEmail"));
      return;
    }

    const isValidDomain = selectedDeformation.condition.domains.includes(emailDomain);
    
    if (isValidDomain) {
      onValidateEmail(email, selectedDeformation);
      resetAndClose();
    } else {
      setError(t("priceDeformation.ineligibleDomain"));
    }
  };

  const resetAndClose = () => {
    setStep('select');
    setSelectedDeformation(null);
    setEmail('');
    setError('');
    onClose();
  };

  const renderSelectStep = () => (
    <ScrollView style={styles.scrollView}>
      <View style={styles.alertContainer}>
        <TitleA style={styles.alertTitle}>
          {t("priceDeformation.modalTitle")}
        </TitleA>
        <SubheadingA style={styles.alertDescription}>
          {t("priceDeformation.modalDescription")}
        </SubheadingA>
      </View>

      <View style={styles.deformationsContainer}>
        {priceDeformations.map((deformation) => (
          <TouchableOpacity
            key={deformation.id}
            style={[styles.deformationButton, { backgroundColor: deformation.color || Colors.darkGreen }]}
            onPress={() => handleDeformationSelect(deformation)}
          >
            <BodyA style={styles.deformationName}>{deformation.name}</BodyA>
            <BodyA style={styles.deformationValue}>
              {deformation.deformationType === 'PERCENTAGE'
                ? `${deformation.deformationValue}${t("priceDeformation.percentageOff")}`
                : `${deformation.deformationValue}${t("priceDeformation.amountOff")}`}
            </BodyA>
          </TouchableOpacity>
        ))}
      </View>

      <ButtonA
        style={styles.regularPricingButton}
        color={Colors.normalGray}
        onPress={resetAndClose}
      >
        {t("priceDeformation.continueRegularPrice")}
      </ButtonA>
    </ScrollView>
  );

  const renderValidateStep = () => (
    <View style={styles.validationContainer}>
      <View style={styles.alertContainer}>
        <TitleA style={styles.alertTitle}>
          {t("priceDeformation.emailValidationTitle")}
        </TitleA>
        <SubheadingA style={styles.alertDescription}>
          {`${t("priceDeformation.emailValidationDescription")} ${selectedDeformation.name}`}
        </SubheadingA>
      </View>

      <View style={styles.inputContainer}>
        <TextInput
          value={email}
          onChangeText={setEmail}
          placeholder={t("priceDeformation.enterEmail")}
          style={styles.input}
          keyboardType="email-address"
          autoCapitalize="none"
        />
        {error ? <Text style={styles.errorText}>{error}</Text> : null}
      </View>

      <View style={styles.buttonGroup}>
        <ButtonA onPress={handleEmailSubmit}>
          {t("priceDeformation.validate")}
        </ButtonA>
        
        <ButtonA
          style={styles.backButton}
          color={Colors.normalGray}
          onPress={() => {
            setStep('select');
            setError('');
          }}
        >
          {t("priceDeformation.back")}
        </ButtonA>
      </View>
    </View>
  );

  return (
    <Modal
      visible={visible}
      transparent
      animationType="slide"
      onRequestClose={resetAndClose}
    >
      <TouchableWithoutFeedback onPress={resetAndClose}>
        <View style={styles.modalOverlay} />
      </TouchableWithoutFeedback>
      
      <SafeAreaView style={styles.modalContainer}>
        <View style={styles.modalContent}>
          <TouchableOpacity 
            style={styles.closeButton}
            onPress={resetAndClose}
          >
            <Text style={styles.closeButtonText}>✕</Text>
          </TouchableOpacity>
          
          {step === 'select' ? renderSelectStep() : renderValidateStep()}
        </View>
      </SafeAreaView>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  modalContent: {
    backgroundColor: Colors.background,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    paddingTop: 20,
    paddingHorizontal: 16,
    paddingBottom: 34,
    maxHeight: '80%',
  },
  closeButton: {
    position: 'absolute',
    right: 16,
    top: 16,
    zIndex: 1,
    width: 30,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.lightGray,
    borderRadius: 15,
  },
  closeButtonText: {
    fontSize: 18,
    color: Colors.darkText,
  },
  scrollView: {
    marginTop: 40, // To account for close button
  },
  alertContainer: {
    backgroundColor: Colors.lightGray,
    borderRadius: 8,
    padding: 16,
    marginBottom: 20,
  },
  alertTitle: {
    marginBottom: 8,
    color: Colors.darkText,
  },
  alertDescription: {
    color: Colors.normalGray,
  },
  deformationsContainer: {
    gap: 12,
    marginBottom: 20,
  },
  deformationButton: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 16,
    borderRadius: 8,
  },
  deformationName: {
    color: Colors.white,
  },
  deformationValue: {
    color: Colors.white,
  },
  regularPricingButton: {
    backgroundColor: Colors.background,
    borderWidth: 1,
    borderColor: Colors.normalGray,
    marginBottom: 20,
  },
  validationContainer: {
    padding: 16,
    marginTop: 40, // To account for close button
  },
  inputContainer: {
    marginBottom: 20,
  },
  input: {
    borderWidth: 1,
    borderColor: Colors.normalGray,
    borderRadius: 8,
    padding: 12,
    fontSize: 16,
    color: Colors.darkText,
  },
  errorText: {
    color: Colors.red,
    marginTop: 8,
    fontSize: 14,
  },
  buttonGroup: {
    gap: 12,
  },
  backButton: {
    backgroundColor: Colors.background,
    borderWidth: 1,
    borderColor: Colors.normalGray,
  },
});

export default PriceDeformationModal;