import React, {useRef, useState} from 'react';
import {
  I18nManager,
  Image,
  ImageBackground,
  SafeAreaView,
  StatusBar,
  Text,
  View,
} from 'react-native';
// import Swiper from 'react-native-swiper';
import Swiper from 'react-native-swiper/src';

import TouchableItem from '../../components/TouchableItem';
import Colors from '../../theme/colors';
import styles from './OnboardingDStyles';
import OnboardingSlide1SVG from '../../components/svgs/OnboardingSlide1SVG';
import OnboardingSlide2SVG from '../../components/svgs/OnboardingSlide2SVG';
import OnboardingSlide3SVG from '../../components/svgs/OnboardingSlide3SVG';
import ButtonPro from '../../components/buttonPro/ButtonPro';
import test from '../../../assets/images/gateaux.jpg';
const isRTL = I18nManager.isRTL;

const slides = [
  {
    id: 'slide1',
    img: <OnboardingSlide1SVG />,
    title: 'Digital ticketing',
    description: 'Add your events and sell tickets on our platform',
  },
  {
    id: 'slide2',
    img: <OnboardingSlide2SVG />,
    title: 'Scan and pay',
    description:
      'After scanning an Instadrink QR code, your customers will be able to instantly order and pay for their food and drinks via their smartphone.',
  },
  {
    id: 'slide3',
    img: <OnboardingSlide3SVG />,
    title: 'Digital marketing',
    description:
      'Notify your customers directly of your future events and promotions',
  },
];

const OnboardingD = ({navigation}) => {
  const swiperRef = useRef(null);

  const [activeIndex, setActiveIndex] = useState(0);

  const onIndexChanged = (index) => {
    let newActiveIndex;
    if (isRTL) {
      newActiveIndex = slides.length - 1 - index;
    } else {
      newActiveIndex = index;
    }
    setActiveIndex(newActiveIndex);
  };

  const previousSlide = () => {
    swiperRef.current.scrollBy(-1, true);
  };

  const nextSlide = () => {
    swiperRef.current.scrollBy(1, true);
  };

  const navigateTo = (screen) => () => {
    navigation.navigate(screen);
  };

  return (
    <SafeAreaView style={styles.screenContainer}>
      <StatusBar
        backgroundColor={Colors.statusBarColor}
        barStyle="dark-content"
      />
      <ImageBackground
        // source={require('../../../assets/images/splash_screen_instadrinkv2.png')}
        source={test}
        style={styles.backgroundImage}>
        <View style={styles.container}>
          <View style={styles.swiperContainer}>
            <Swiper
              ref={swiperRef}
              index={isRTL ? slides.length - 1 : 0}
              onIndexChanged={onIndexChanged}
              loop={false}
              // scrollEnabled={false}
              showsPagination={false}>
              {slides.map((item) => (
                <View key={item.id} style={styles.slide}>
                  <View style={styles.slideImg}>{item.img}</View>
                  <Text style={styles.title}>{item.title}</Text>

                  <Text style={styles.descriptionText}>{item.description}</Text>
                </View>
              ))}
            </Swiper>
          </View>

          <View style={styles.paginationDotsContainer}>
            {slides.map((item, idx) => (
              <View
                key={`dot_${item.id}`}
                style={[
                  styles.dot,
                  {
                    backgroundColor:
                      activeIndex === idx
                        ? Colors.primaryColor
                        : Colors.seconda2,
                  },
                ]}
              />
            ))}
          </View>

          {activeIndex < 2 && (
            <View style={styles.skipContainer}>
              <TouchableItem onPress={isRTL ? previousSlide : nextSlide}>
                <Text style={styles.skipBtnText}>Skip</Text>
              </TouchableItem>
            </View>
          )}

          <View style={styles.buttonsContainer}>
            <View style={styles.buttonContainer}>
              {activeIndex > 0 ? (
                <ButtonPro
                  accessid="PrevButton"
                  onPress={isRTL ? nextSlide : previousSlide}
                  text={'Back'}
                  textColor={Colors.normalGray}
                  style={{
                    backgroundColor: Colors.transparent,
                    marginBottom: 25,
                    marginTop: 0,
                  }}
                />
              ) : (
                <ButtonPro
                  accessid="IGNOREButton"
                  onPress={navigateTo('Welcome')}
                  text={'Ignore'}
                  textColor={Colors.normalGray}
                  style={{
                    backgroundColor: Colors.transparent,
                    marginBottom: 25,
                    marginTop: 0,
                  }}
                />
              )}
            </View>

            <View style={styles.buttonContainer}>
              {activeIndex < slides.length - 1 ? (
                <ButtonPro
                  accessid="NextButton"
                  onPress={isRTL ? previousSlide : nextSlide}
                  text={'Next'}
                  textColor={Colors.background}
                  style={{
                    backgroundColor: Colors.primaryColor,
                    marginBottom: 25,
                    marginTop: 0,
                  }}
                />
              ) : (
                <ButtonPro
                  // accessid="NextButton"
                  onPress={navigateTo('Welcome')}
                  text={'Welcome'}
                  textColor={Colors.background}
                  style={{
                    backgroundColor: Colors.primaryColor,
                    marginBottom: 25,
                    marginTop: 0,
                  }}
                />
              )}
            </View>
          </View>
        </View>
      </ImageBackground>
    </SafeAreaView>
  );
};

export default OnboardingD;
