import {TouchableOpacity} from 'react-native';
import StarFillSVG from './StarFillSVG'; // Assurez-vous d'importer correctement les composants SVG
// import StarFillNotSelectedSVG from './StarFillNotSelectedSVG';
import React from 'react';
import StarFillNotSelectedSVG from './StarFillNotSelectedSVG';
// import StarFillNotSVG from './StartFillNotSelectedSVG';
const StartFillNoteSVG = ({Checked, onPress, width, height}) => {
  return (
    <TouchableOpacity onPress={onPress}>
      {Checked ? (
        <StarFillSVG width={width} height={height} />
      ) : (
        <StarFillNotSelectedSVG width={width} height={height} />
      )}
    </TouchableOpacity>
  );
};

export default StartFillNoteSVG;
