import React, {
  useEffect,
  useState,
  useContext,
  useMemo,
  useCallback,
} from 'react';
import {
  View,
  Text,
  TextInput,
  SafeAreaView,
  StatusBar,
  ScrollView,
  TouchableOpacity,
} from 'react-native';
import styles from './SondajeStyles';
import DropDownInput from '../../components/dropDownInput/DropDownInput';
import SubheadingA from '../../components/subheadingA/SubheadingA';
import {AuthContext} from '../../contexts/context';
import ButtonA from '../../components/buttonA/ButtonA';
import Colors from '../../theme/colors';

import {SmallText} from '../../components/text/CustomText';
import TitleA from '../../components/titleA/TitleA';

const RadioButtonx = ({selected, onPress, label}) => (
  <TouchableOpacity
    onPress={onPress}
    style={{flexDirection: 'row', alignItems: 'center'}}>
    <View
      style={{
        height: 20,
        width: 20,
        borderRadius: 10,
        borderWidth: 2,
        borderColor: selected ? Colors.darkGreen : Colors.lightGray,
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 10,
      }}>
      {selected ? (
        <View
          style={{
            height: 10,
            width: 10,
            borderRadius: 5,
            backgroundColor: Colors.darkGreen,
          }}
        />
      ) : null}
    </View>
    {/* <Text>{label}</Text> */}
  </TouchableOpacity>
);

const Sondaje = () => {
  const {user} = useContext(AuthContext);
  const [error, setError] = useState(false);

  const createEmptySondaje = (sondajeInfo) => {
    const sondaje = {
      connaissance: '',
      connaissanceFocused: false,
      problem: '',
      problemFocused: false,

      errors: {},
    };
    sondajeInfo?.map((p) => {
      sondaje[p.field] = '';
      sondaje[`${p.field}Focused`] = false;
    });
    return sondaje;
  };
  const sondajeData = [
    // {
    //   field: 'fonctionatile',
    //   required: false,
    // },
    {
      field: 'connaissance',
      required: true,
      // connaissanceFields: ['Linkedin', 'Amies', 'Facebook', 'Autre'],
    },
    {
      field: 'opinion',
      required: true,
      /*  if you want to get data from firebase*/
      opinionFields: ['veryGood', 'good', 'satisfait', 'bad'],
    },
    {
      field: 'typeEvent le plus interese pour vous',
      required: true,
      typeEvent: ['Santé', 'Concert', 'Deseigne', 'Sport', 'Autres'],
    },
    {
      field: 'Type Something ',
      required: true,
    },
  ];
  const [dataSondaje, setDataSondaje] = React.useState();
  /* data from frontend */
  const connaissanceFields = ['Linkedin', 'Amies', 'Facebook', 'Autre'];

  console.log('dataSondaje', dataSondaje);
  React.useEffect(() => {
    const dataSondajes = {
      id: user?.uid,
      sondajeInfo: Array.from({length: 1}, () =>
        createEmptySondaje(sondajeData),
      ),
    };
    setDataSondaje(dataSondajes);
  }, []);
  const filedSondaje = useMemo(() => {
    let baseFieldSondaje = ['connaissance', 'problem'];
    if (sondajeData)
      sondajeData?.map((field) => baseFieldSondaje.push(field.field));
    return baseFieldSondaje;
  }, [sondajeData]);
  const validetInputSondaje = () => {
    if (dataSondaje?.sondajeInfo) {
      const updatedSondajeInfo = dataSondaje?.sondajeInfo.map(
        (sondaje, index) => {
          let isNotEmpty = Object.keys(sondaje)
            .filter((key) => filedSondaje.includes(key))
            .some((key) => Boolean(sondaje[key]));

          if (index === 0 || (index > 0 && isNotEmpty)) {
            const sondajeErrors = Object.fromEntries(
              Object.keys(sondaje)
                .filter((field) => {
                  if (filedSondaje.includes(field)) {
                    if (
                      checkIfRequiredSondaje(field, sondajeData) &&
                      !sondaje[field].trim()
                    ) {
                      return true;
                    }
                    return false;
                  }
                  return false;
                })
                .map((field) => [field, true]),
            );

            return {...sondaje, errors: sondajeErrors};
          } else {
            return {...sondaje, errors: {}};
          }
        },
      );

      setDataSondaje((prevState) => ({
        ...prevState,
        sondajeInfo: updatedSondajeInfo,
      }));

      const allValid = updatedSondajeInfo.every(
        (invoice) => Object.keys(invoice.errors).length === 0,
      );

      return allValid;
    } else {
      return true;
    }
  };
  const handelSondajeField = (sondajeIndex, field, value) => {
    setDataSondaje((prevInvoicesInfo) => ({
      ...prevInvoicesInfo,
      sondajeInfo: prevInvoicesInfo.sondajeInfo.map((item, i) => {
        if (sondajeIndex === i) {
          return {
            ...item,
            [field]: value,
          };
        }
        return item;
      }),
    }));
  };
  const handelFocusSondajeField = (sondajeIndex, field, focused) => {
    setDataSondaje((prevInvoicesInfo) => ({
      ...prevInvoicesInfo,
      sondajeInfo: prevInvoicesInfo.sondajeInfo.map((item, i) => {
        if (sondajeIndex === i) {
          return {
            ...item,
            [`${field}Focused`]: focused,
          };
        }
        return item;
      }),
    }));
  };
  const checkIfRequiredSondaje = useCallback((fieldName, sondajeInfo) => {
    return sondajeInfo?.some(
      (field) => field.field === fieldName && field.required,
    );
  }, []);
  const handelPress = () => {
    const SondajeIsValid = validetInputSondaje();

    setError(true);
    if (SondajeIsValid) {
      setError(false);
      alert('Your Formulaire Sondaje Envoyer');
    }
  };
  return (
    <SafeAreaView style={styles.screenContainer}>
      <StatusBar backgroundColor={Colors.white} barStyle="dark-content" />
      <ScrollView
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}>
        <View style={styles.container}>
          <TitleA style={{textAligne: '"center'}}>Sondaje</TitleA>
          {dataSondaje && (
            <View style={[styles.inputsContainer, {marginTop: 13}]}>
              {dataSondaje?.sondajeInfo.map((sondaje, j) => (
                <View key={j} style={{marginBottom: 15}}>
                  {Object.keys(sondaje)
                    .filter((v) => filedSondaje.includes(v))
                    .map((key) => (
                      <View key={key} style={{marginVertical: 8}}>
                        <SubheadingA style={styles.label}>
                          {`${key}`}
                          {checkIfRequiredSondaje(key, sondajeData) && (
                            <Text style={{color: Colors.red}}> *</Text>
                          )}
                        </SubheadingA>
                        <View style={{marginVertical: 15}}>
                          {key === 'connaissance' ? (
                            <View /*style={{marginTop: 10}}*/>
                              {connaissanceFields.map((field, index) => (
                                <View
                                  key={index}
                                  style={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    marginTop: 10,
                                  }}>
                                  <RadioButtonx
                                    selected={sondaje['connaissance'] === field}
                                    onPress={() =>
                                      handelSondajeField(
                                        j,
                                        'connaissance',
                                        field,
                                      )
                                    }
                                    label={field}
                                  />
                                  <Text style={{marginLeft: 8}}>{field}</Text>
                                </View>
                              ))}
                            </View>
                          ) : key === 'typeEvent le plus interese pour vous' ? (
                            <DropDownInput
                              // label="Type d'événement le plus intéressé pour vous"
                              placeholder="Sélectionnez une option"
                              dataArray={
                                sondajeData.find(
                                  (item) =>
                                    item.field ===
                                    'typeEvent le plus interese pour vous',
                                )?.typeEvent
                              }
                              value={sondaje[key]}
                              onChangeText={(value) =>
                                handelSondajeField(j, key, value)
                              }
                              onFocus={() =>
                                handelFocusSondajeField(j, key, true)
                              }
                              isFocused={sondaje[`${key}Focused`]}
                            />
                          ) : key === 'opinion' ? (
                            <View /*style={{marginTop: 10}}*/>
                              {sondajeData
                                .find((item) => item.field === 'opinion')
                                ?.opinionFields.map((field, index) => (
                                  <View
                                    key={index}
                                    style={{
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                      marginVertical: 10,
                                    }}>
                                    <RadioButtonx
                                      selected={sondaje['opinion'] === field}
                                      onPress={() =>
                                        handelSondajeField(j, 'opinion', field)
                                      }
                                      label={field}
                                    />
                                    <Text style={{marginLeft: 8}}>{field}</Text>
                                  </View>
                                ))}
                            </View>
                          ) : (
                            <View /*style={{marginTop: 15}}*/>
                              <TextInput
                                placeholderTextColor={Colors.lightGray}
                                maxLength={550}
                                style={[
                                  styles.textInput,
                                  sondaje?.errors?.[key] && styles.errorBorder,
                                  {
                                    height: 150,
                                    textAlignVertical: 'top',
                                    paddingTop: 10,
                                  },
                                ]}
                                multiline={true}
                                numberOfLines={20}
                                value={sondaje[key]}
                                onChangeText={(text) =>
                                  handelSondajeField(j, key, text)
                                }
                                onFocus={() =>
                                  handelFocusSondajeField(j, key, true)
                                }
                                onBlur={() =>
                                  handelFocusSondajeField(j, key, false)
                                }
                                isFocused={sondaje[`${key}Focused`]}
                              />
                            </View>
                          )}
                        </View>
                      </View>
                    ))}
                </View>
              ))}
            </View>
          )}

          <View>
            {error && (
              <View
                style={{
                  display: 'flex',
                  paddingVertical: 10,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <SmallText style={{color: Colors.red}}>
                  Required field{' '}
                </SmallText>
              </View>
            )}
          </View>
          <View>
            <ButtonA onPress={handelPress}>Test Sondaje</ButtonA>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};
export default Sondaje;
