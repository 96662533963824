import React, {useContext, useEffect, useMemo, useState} from 'react';
import styles from './CheckoutSuccessAStyles';
import Colors from '../../theme/colors';
import {
  FlatList,
  SafeAreaView,
  ScrollView,
  StatusBar,
  Text,
  View,
} from 'react-native';
import {emptyCart} from '../../reducers/cartactions';
// Import Firebase
import firebase from 'firebase/compat/app';
import {db} from '../../services/firebase';
import {collection, query, where, getDocs} from 'firebase/firestore';
import {connect} from 'react-redux';
import ButtonA from '../../components/buttonA/ButtonA';
import TicketSVG from '../../components/svgs/TicketSVG';
import ConfirmedPurchaseSVG from '../../components/svgs/ConfirmedPurchaseSVG';
import SubheadingA from '../../components/subheadingA/SubheadingA';
import TimeSVG from '../../components/svgs/TimeSVG';
import BodyA from '../../components/bodyA/BodyA';
import TitleA from '../../components/titleA/TitleA';
import LocationSVG from '../../components/svgs/LocationSVG';
import PriceSVG from '../../components/svgs/PriceSVG';
import FavoritesEventsContext from '../../contexts/favoritesEventsContext';
import EventCardA from '../../components/eventCardA/EventCardA';
import {bindActionCreators} from 'redux';
import {useTranslation} from 'react-i18next';

function formatDate(date) {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 to month because it's zero-based.
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

const SuggestedEvents = () => {
  const {t} = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const favoritesContext = useContext(FavoritesEventsContext);
  const [showFavorites, setShowFavorites] = useState(false);

  const [events, setEvents] = useState([]);
  const [event, setEvent] = useState([]);

  const queryFn = async (reservationID) => {
    try {
      setIsLoading(true);

      // Calculate the start and end timestamps for the selected day
      const today = new Date();
      const startDate = firebase.firestore.Timestamp.fromDate(today);
      if (reservationID) {
        const reservation = await db
          .collection('reservations')
          .doc(reservationID)
          .get();

        setReservation(reservation.data());

        const eventData = reservation.data().event;
        setEvent(eventData);

      }

      const qAllEvents = db
        .collection('events')
        .where('creationStatus', '==', 'published')
        .where('startDate', '>=', startDate);

      const eventSnapshots = await qAllEvents.get();
      const eventsData = [];

      for (const doc of eventSnapshots.docs) {
        const docdata = doc.data();
        const startDate = docdata.startDate?.toDate();
        const formattedDate = `${startDate
          .getDate()
          .toString()
          .padStart(2, '0')} ${getMonthName(
          startDate.getMonth(),
        )} ${startDate.getFullYear()} at ${startDate
          .getHours()
          .toString()
          .padStart(2, '0')}:${startDate
          .getMinutes()
          .toString()
          .padStart(2, '0')}`;

        const qTickets = db
          .collection('events')
          .doc(doc.id)
          .collection('tickets');
        const ticketSnapshots = await qTickets.get();
        const tickets = ticketSnapshots.docs.map((ticketDoc) =>
          ticketDoc.data(),
        );
        const price = tickets.length > 0 ? tickets[0].price : 'N/A';

        const eventData = {
          id: doc.id,
          //auditoriumid: params.id,
          description: docdata.description,
          summary: docdata.summary,
          dateDisplay: formattedDate,
          title: docdata.title,
          imageUri: docdata.coverImage,
          address: docdata.address,
          location: docdata.location,
          price: price, // Price from the first ticket in the tickets sub-collection
        };

        eventsData.push(eventData);
      }

      setEvents(eventsData);
      setIsLoading(false);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await queryFn();
      } catch (error) {
        console.log('error', error);
      }
    };

    fetchData();
  }, []);

  // Function to get the month name
  const getMonthName = (monthIndex) => {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    return months[monthIndex];
  };

  const eventKeyExtractor = (item, index) =>
    item && item.id ? item.id.toString() : index.toString();

  const renderShowItem = ({item, index}) => {
    if (!item || !item.id) {
      return null; // If item or item.id is not defined, return null to skip rendering
    }
    return (
      <EventCardA
        key={index}
        eventId={item.id}
        imageUri={item.imageUri}
        name={item.title}
        description={item.summary}
        date={item.dateDisplay}
        address={item.address}
        location={item.location}
        price={item.price}
      />
    );
  };

  return (
    <View>
      <TitleA
        style={{
          paddingVertical: 20,
          marginHorizontal: 25,
          borderTopWidth: 1,
          borderColor: Colors.lightGray,
        }}>
        {/* Voir aussi */}
        {/* See as well */}
        {t('CheckoutSuccessA.Voir aussi')}
      </TitleA>

      <FlatList
        data={events}
        showsHorizontalScrollIndicator={false}
        alwaysBounceHorizontal={false}
        keyExtractor={eventKeyExtractor}
        renderItem={renderShowItem}
        contentContainerStyle={styles.contentContainerStyle}
      />
    </View>
  );
};

const CheckoutSuccessA = ({navigation, route, emptyCart}) => {
  const {t} = useTranslation();
  const [reservation, setReservation] = useState();
  const [event, setEvent] = useState();
  const [tierMode, setTierMode] = useState('');
  const [surveyEnabled, setSurveyEnabled] = useState(false);


  const queryFnOrders = async (id) => {
    db.collection('orders')
      .doc(id)
      // .get()
      .onSnapshot(async (reservationData) => {
        const reservation = reservationData.data();
        setReservation(reservation);
      });
  };

  const queryFn = async (reservationID) => {
    try {
      if (reservationID) {
        const reservation = await db
          .collection('reservations')
          .doc(reservationID)
          .get();
        let reservationData = reservation.data();
        reservationData['id'] = reservation.id;
        setReservation(reservationData);

        const eventdoc = await db
          .collection('events')
          .doc(reservation.data().event.id)
          .get();

        const eventData = eventdoc.data();
        eventData['id'] = eventdoc.id;
        setEvent(eventData);
        console.log('eventData', eventData.survey);
        // Check if survey is enabled for this event
        if (eventData && eventData.survey && eventData.survey?.enabled) {
          setSurveyEnabled(true);
        }
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    emptyCart();
  }, []);

  useEffect(() => {
    console.log('route?.params?.newid', route?.params?.newid);
    const fetchData = async (reservationID) => {
      try {
        await queryFn(reservationID);
      } catch (error) {
        console.log('error', error);
      }
    };
    if (route?.params?.newid) {
      setTierMode(route?.params?.tier);
      if (route?.params?.tier === 'food') {
        queryFnOrders(route?.params?.newid);
      } else fetchData(route?.params?.newid);
    }
  }, [route]);

  const dateEvent = useMemo(() => {
    if (event?.startDate) {
      const milliseconds =
        event.startDate.seconds * 1000 + event.startDate.nanoseconds / 1e6;
      const date = new Date(milliseconds);
      return formatDate(date);
    } else return '';
  }, [event]);

  return (
    <SafeAreaView style={styles.screenContainer}>
      <StatusBar backgroundColor={Colors.white} barStyle="dark-content" />
      <ScrollView showsVerticalScrollIndicator={false}>
        <View style={styles.imageContainer}>
          <ConfirmedPurchaseSVG style={styles.image} />
          <Text
            style={{
              marginTop: 10,
              color: Colors.darkGreen,
              textAlign: 'center',
              fontFamily: 'Bold',
              fontSize: 24,
              lineHeight: 36,
              letterSpacing: 0.96,
            }}>
            {/* Toutes nos félicitations */}
            {t('CheckoutSuccessA.Toutes nos félicitations')}
          </Text>

          <Text
            style={{
              color: Colors.darkGray,
              textAlign: 'center',
              fontFamily: 'SemiBold',
              fontSize: 16,
              // lineHeight: 36,
              letterSpacing: 0.64,
            }}>
            {t('CheckoutSuccessA.Votre achat est confirmé')}
          </Text>

          <SubheadingA
            style={{
              color: Colors.normalGray,
            }}>
            {/* Vous pouvez retrouver et consulter votre billet dans votre boîte
            mail. */}
            {t(
              'CheckoutSuccessA.Vous pouvez retrouver et consulter votre billet dans votre boîte mail.',
            )}
          </SubheadingA>
        </View>
        {tierMode === 'food' ? (
          reservation && (
            <View style={styles.suivieCommandeContainer}>
              {reservation?.products?.map((product) => {
                const groupedComposition = product.composition.reduce(
                  (grouped, item) => {
                    if (!grouped[item.category]) {
                      grouped[item.category] = [];
                    }
                    grouped[item.category].push(item);
                    return grouped;
                  },
                  {},
                );
                return (
                  <View style={{paddingHorizontal: 15, paddingBottom: 8}}>
                    <View style={styles.detailA}>
                      <View>
                        <Text style={styles.textDetailA}> {product.name}</Text>
                      </View>
                      <Text>{product.quantity}</Text>
                      <Text>
                        {product.price}
                        {' €'}
                      </Text>
                    </View>

                    <View style={styles.allDetailesA}>
                      <View>
                        {Object.keys(groupedComposition).map(
                          (category, index) => {
                            const items = groupedComposition[category];
                            if (items && items.length > 0) {
                              return (
                                <View key={index}>
                                  <Text
                                    style={styles.titleTextDetaileContainer}>
                                    {category}
                                  </Text>
                                  {items?.map((composition, phraseIndex) => (
                                    <View style={styles.textMapingCommande}>
                                      <Text
                                        key={phraseIndex}
                                        style={{
                                          color: 'var(--Normal-Grey, #77838F)',
                                          fontFamily: 'Bai Jamjuree',
                                          fontSize: '14px',
                                          fontStyle: 'normal',
                                          fontWeight: 300,
                                        }}>
                                        {composition.quantity
                                          ? composition.quantity
                                          : 1}{' '}
                                        {composition.name}
                                      </Text>
                                    </View>
                                  ))}
                                </View>
                              );
                            }
                          },
                        )}
                      </View>
                    </View>
                  </View>
                );
              })}
            </View>
          )
        ) : (
          <View style={styles.container}>
            <TitleA style={{marginVertical: 5}}>{event?.title}</TitleA>
            <View>
              <SubheadingA
                style={{
                  color: Colors.normalGray,
                  marginTop: 10,
                }}>
                {t('CheckoutSuccessA.Les détails de l évènement')}{' '}
              </SubheadingA>

              <View style={styles.bodyRow}>
                <View style={styles.left}>
                  <TimeSVG
                    fill={Colors.darkGreen}
                    style={styles.rowIcon}
                    stroke={Colors.primaryColor}
                  />
                  <BodyA style={{color: Colors.normalGray}}>Date</BodyA>
                </View>

                <View style={styles.right}>
                  <SubheadingA
                    style={{color: Colors.darkGray, textAlign: 'right'}}>
                    {dateEvent}
                  </SubheadingA>
                </View>
              </View>

              <View style={styles.bodyRow}>
                <View style={styles.left}>
                  <LocationSVG
                    fill={Colors.darkGreen}
                    style={styles.rowIcon}
                    stroke={Colors.primaryColor}
                  />
                  <BodyA style={{color: Colors.normalGray}}>Address</BodyA>
                </View>

                <View style={styles.right}>
                  <SubheadingA
                    style={{color: Colors.darkGray, textAlign: 'right'}}>
                    {event?.address}
                  </SubheadingA>
                </View>
              </View>
            </View>

            
          {surveyEnabled && (
            <View style={{marginTop: 20, marginBottom: 10, paddingHorizontal: 7}}>
              <ButtonA
                onPress={() => {
                  navigation.navigate('Survey', { eventId: event.id, reservationId: reservation.id });                  
                }}
                style={{backgroundColor: Colors.blue}}>
                {t('CheckoutSuccessA.TakeSurvey')}
              </ButtonA>
            </View>
          )}
          </View>
        )}
        <SuggestedEvents />
      </ScrollView>
    </SafeAreaView>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      emptyCart,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(CheckoutSuccessA);
