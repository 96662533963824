import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

const LogoutSVG = (props) => {
  const {style, ...restProps} = props;

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 36 36"
      style={style}
      {...restProps}>
      <Path
        stroke="#FF5E75"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M18 18h10.5m0 0L24 22.5m4.5-4.5L24 13.5M28.5 9V7.5a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v21a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V27"
      />
    </Svg>
  );
};
export default LogoutSVG;
