import React, { useState, useEffect } from 'react';
import { View, TextInput, Text } from 'react-native';
import { useTranslation } from 'react-i18next';
import SubheadingA from '../subheadingA/SubheadingA';
import TitleA from '../titleA/TitleA';
import BodyA from '../bodyA/BodyA';
import EmailSVG from '../svgs/EmailSVG';
import PhoneSVG from '../svgs/PhoneSVG';
import CalendarSVG from '../svgs/CalendarSVG';
import AgeSVG from '../svgs/AgeSVG';
import GenderSVG from '..//svgs/GenderSVG';
import CheckboxSVG from '..//svgs/CheckboxSvg';
import DropDownInput from '../dropDownInput/DropDownInput';
import Colors from '../../theme/colors';

const ParticipantForm = ({
    participant,
    participantIndex,
    lc,
    fieldsOfCoordinates,
    event,
    handleChange,
    handleFocusChange,
    cart,
    styles,
  }) => {
    const { t } = useTranslation();
  
    const genders = [
      t('TicketCoordinatesBPage.Mâle'),
      t('TicketCoordinatesBPage.Femelle'),
      t('TicketCoordinatesBPage.Autre'),
    ];
  
    const checkIfRequired = (fieldName, participantInfo) => {
      return participantInfo?.some(
        (field) => field.field === fieldName && field.required
      );
    };
  
    const renderNameInputs = () => (
      <View style={{ marginBottom: 15 }}>
        <View style={{ marginBottom: 15 }}>
          <SubheadingA style={styles.label}>
            {t('TicketCoordinatesBPage.firstName')}
            <Text style={{color: Colors.red}}> *</Text>
          </SubheadingA>
          <TextInput
            placeholder="••••••••••••••••••••••••••••"
            placeholderTextColor={Colors.lightGray}
            maxLength={50}
            style={[
              styles.textInput,
              participant?.errors?.firstName && styles.errorBorder,
            ]}
            value={participant.firstName}
            onChangeText={(text) => handleChange(lc.id, participantIndex, 'firstName', text)}
            onFocus={() => handleFocusChange(lc.id, participantIndex, 'firstName', true)}
            onBlur={() => handleFocusChange(lc.id, participantIndex, 'firstName', false)}
          />
        </View>
  
        <View style={{ marginBottom: 15 }}>
          <SubheadingA style={styles.label}>
            {t('TicketCoordinatesBPage.lastName')}
            <Text style={{color: Colors.red}}> *</Text>
          </SubheadingA>
          <TextInput
            placeholder="••••••••••••••••••••••••••••"
            placeholderTextColor={Colors.lightGray}
            maxLength={50}
            style={[
              styles.textInput,
              participant?.errors?.lastName && styles.errorBorder,
            ]}
            value={participant.lastName}
            onChangeText={(text) => handleChange(lc.id, participantIndex, 'lastName', text)}
            onFocus={() => handleFocusChange(lc.id, participantIndex, 'lastName', true)}
            onBlur={() => handleFocusChange(lc.id, participantIndex, 'lastName', false)}
          />
        </View>
      </View>
    );
  
    const renderEmailInput = (key) => {
      const isFirstParticipant = participantIndex === 0;
      const hasAccessRestriction = cart?.accessRestriction?.verifiedEmail;
      const isAccessRestrictedEmail = isFirstParticipant && hasAccessRestriction;
      const emailValue = isAccessRestrictedEmail 
        ? cart.accessRestriction.verifiedEmail 
        : participant[key];
  
      return (
        <View style={{position: 'relative', marginBottom: 15}}>
          <SubheadingA style={styles.label}>
            {t(`${key}`)}
            {checkIfRequired(key, event.participantInfo) && (
              <Text style={{color: Colors.red}}> *</Text>
            )}
            {isAccessRestrictedEmail && (
              <BodyA style={styles.verifiedLabel}>
                {t('TicketCoordinatesBPage.Verified Access')}
              </BodyA>
            )}
          </SubheadingA>
          <TextInput
            placeholder="••••••••••••••••••••••••••••"
            keyboardType="email-address"
            placeholderTextColor={Colors.lightGray}
            maxLength={50}
            style={[
              styles.textInput,
              {paddingLeft: 44},
              participant?.errors[key] && styles.errorBorder,
              isAccessRestrictedEmail && styles.verifiedInput
            ]}
            value={emailValue}
            onChangeText={(text) => handleChange(lc.id, participantIndex, key, text)}
            onFocus={() => handleFocusChange(lc.id, participantIndex, key, true)}
            onBlur={() => handleFocusChange(lc.id, participantIndex, key, false)}
            isFocused={participant[`${key}Focused`]}
            editable={!isAccessRestrictedEmail}
          />
          <EmailSVG style={styles.inputLeftIcon} />
          {isAccessRestrictedEmail && (
            <View style={styles.verifiedIcon}>
              <CheckboxSVG width={16} height={16} fill={Colors.darkGreen} />
            </View>
          )}
        </View>
      );
    };
  
    return (
      <View style={{marginBottom: 20}}>
        <TitleA style={{marginBottom: 20, marginTop: 10}}>
          {t('participant')} {participantIndex + 1}
        </TitleA>
        
        {renderNameInputs()}
  
        {Object.keys(participant)
          .filter((v) => fieldsOfCoordinates.includes(v) && !['firstName', 'lastName'].includes(v))
          .map((key) => (
            <View key={key} style={{position: 'relative', marginBottom: 15}}>
              <SubheadingA style={styles.label}>
                {t(`${key}`)}
                {checkIfRequired(key, event.participantInfo) && (
                  <Text style={{color: Colors.red}}> *</Text>
                )}
              </SubheadingA>
  
              {key === 'gender' ? (
                <View style={{position: 'relative'}}>
                  <DropDownInput
                    label={t('TicketCoordinatesBPage.Genre')}
                    placeholder={t('TicketCoordinatesBPage.Genre')}
                    icon={<GenderSVG />}
                    isRequired={checkIfRequired(key, event.participantInfo)}
                    dataArray={genders}
                    value={participant[key]}
                    style={[participant?.errors[key] && styles.errorBorder]}
                    onChangeText={(text) => handleChange(lc.id, participantIndex, key, text)}
                  />
                </View>
              ) : key === 'email' ? (
                renderEmailInput(key)
              ) : (
                <>
                  <TextInput
                    placeholder="••••••••••••••••••••••••••••"
                    keyboardType={
                      key === 'age'
                        ? 'numeric'
                        : key === 'phone'
                        ? 'phone-pad'
                        : key === 'birthday'
                        ? 'birthday'
                        : 'default'
                    }
                    placeholderTextColor={Colors.lightGray}
                    maxLength={50}
                    style={[
                      styles.textInput,
                      {paddingLeft: 44},
                      participant?.errors[key] && styles.errorBorder,
                    ]}
                    value={participant[key]}
                    onChangeText={(text) => handleChange(lc.id, participantIndex, key, text)}
                    onFocus={() => handleFocusChange(lc.id, participantIndex, key, true)}
                    onBlur={() => handleFocusChange(lc.id, participantIndex, key, false)}
                    isFocused={participant[`${key}Focused`]}
                  />
                  {key === 'birthday' && <CalendarSVG style={styles.inputLeftIcon} />}
                  {key === 'age' && <AgeSVG style={styles.inputLeftIcon} />}
                  {key === 'phone' && <PhoneSVG style={styles.inputLeftIcon} />}
                </>
              )}
            </View>
          ))}
      </View>
    );
  };
  
  export default ParticipantForm;