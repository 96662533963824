import {StyleSheet} from 'react-native';
import Colors from '../../theme/colors';

const styles = StyleSheet.create({
  screenContainer: {
    flex: 1,
    backgroundColor: Colors.background,
  },
  errorBorder: {
    borderColor: 'red',
  },
  scrollView: {
    height: 'auto',
    width: '100%',
    // padding: 25,
  },

  container: {
    padding: 25,
  },
  firstRow: {
    marginBottom: 20,
  },
  inputsContainer: {
    paddingVertical: 10,
  },
  inputRow: {
    marginBottom: 30,
    position: 'relative',
  },
  label: {
    position: 'absolute',
    top: -10,
    left: 10,
    backgroundColor: Colors.white,
    borderRadius: 14,
    paddingHorizontal: 6,
  },
  textInput: {
    // height: 46,
    borderRadius: 8,
    backgroundColor: Colors.white,
    paddingVertical: 12,
    paddingRight: 16,
    justifyContent: 'flex-start',
    paddingLeft: 16,
    borderColor: '#D2D5DA',
    borderWidth: 1,
    textAlignVertical: 'top',
    shadowColor: 'black',
    shadowOffset: {width: 0, height: 2},
    shadowOpacity: 0.05,
    shadowRadius: 2,
    elevation: 1,
  },
  inputLeftIcon: {
    position: 'absolute',
    top: 15,
    left: 16,
    width: 16,
    height: 16,
  },
  errorText: {
    position: 'absolute',
    bottom: -18,
    color: Colors.red,
  },
  inputRightIcon: {
    position: 'absolute',
    top: 14,
    right: 12,
    width: 18,
    height: 18,
  },
  radioContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 6,
    paddingBottom: 10,
    marginRight: 38,
  },
  outerRadio: {
    marginRight: 15,
    width: 18,
    height: 18,
    borderRadius: 9,
    borderWidth: 1,
    borderColor: Colors.normalGray,
    justifyContent: 'center',
    alignItems: 'center',
  },
  innerRadio: {
    width: 11,
    height: 11,
    borderRadius: 6,
    backgroundColor: Colors.darkGreen,
  },
  radioLabel: {
    color: Colors.normalGray,
  },
  checkboxContainer: {
    flexDirection: 'row',
  },
  checkbox: {
    marginRight: 13,
    marginTop: 3,
    width: 16,
    height: 16,
    flexShrink: 0,
  },

  buttonContainer: {
    paddingHorizontal: 10,
  },
});

export default styles;
