import {StyleSheet, I18nManager} from 'react-native';
import Colors from '../../theme/colors';
import {marginBottom} from 'styled-system';

const styles = StyleSheet.create({
  screenContainer: {
    flex: 1,
    backgroundColor: Colors.background,
  },
  content: {
    paddingHorizontal: 24,
    paddingTop: 24,
    marginBottom: 15,
  },
  contentRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  startRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  titleCommande: {
    // color: 'var(--Dark-Grey, #1D1F21)' ,

    fontSize: 24,
    fontStyle: 'normal',
    // fontWeight: '700',
    // lineHeight: 36, // Adjust as needed
    // letterSpacing: 0.96,
  },
  titleTextCommande: {
    // color: 'var(--Dark-Grey, #1D1F21)' ,

    fontSize: 20,
    fontStyle: 'normal',
    // fontWeight: '600',
    // lineHeight: 30, // Adjust as needed
    // letterSpacing: 0.4,
  },
  textCommande: {
    // color: 'var(--Dark-Grey, #1D1F21)' ,

    fontSize: 14,
    fontStyle: 'normal',
    // fontWeight: '400',
    // lineHeight: "150%" , // Adjust as needed
    // letterSpacing: 0.56,
  },
  litleTextCommande: {
    // color: 'var(--Dark-Grey, #1D1F21)' ,
    marginBottom: 12,
    fontSize: 16,
    // fontStyle: 'normal',
    // fontWeight: '600',
    // lineHeight: "150%" , // Adjust as needed
    letterSpacing: 0.54,
  },
  separator: {
    height: 1,
    backgroundColor: Colors.lightGray,
    marginVertical: 5,
  },
  contentRowChecked: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
    gap: 17,
  },
  borderContainer: {
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D2D5DA',
    backgroundColor: '#FFF',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.05,
    marginTop: 10,
    shadowRadius: 2,
    paddingVertical: 15,
    paddingHorizontal: 5,
    elevation: 2,
  },
  textInput: {
    color: '#6C727F',
    justifyContent: 'center',
    paddingLeft: 5,

    borderWidth: 0,
    outlineStyle: 'none',
    underlineColorAndroid: 'transparent',
    fontFamily: 'Bai Jamjuree', // Adjust to the actual font family and weight in your project
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: '300',
    lineHeight: '150%', // Adjust as needed
  },
});
export default styles;
