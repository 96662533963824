import React, {useContext} from 'react';
import styles from './WelcomeEStyles';
import Colors from '../../theme/colors';
import {
  SafeAreaView,
  StatusBar,
  View,
  Image,
  Text,
  ImageBackground,
} from 'react-native';
import Constants from 'expo-constants';
import {AuthContext} from '../../contexts/context';

import Button from '../../components/buttons/Button';
// import Logo from '../../components/logo/Logo';
// import logo from '../../../assets/images/logo_dark_instadrinkv2.png';
// import bgImage from '../../../assets/images/splash_screen_instadrinkv2.png';
import LinkButton from '../../components/buttons/LinkButton';
import ButtonPro from '../../components/buttonPro/ButtonPro';
import {ScrollView} from 'react-native';
import TitleBigA from '../../components/titleBigA/TitleBigA';
import SubheadingA from '../../components/subheadingA/SubheadingA';
import TitleA from '../../components/titleA/TitleA';

const version = Constants.manifest.version;

const WelcomeE = ({navigation}) => {
  // console.log('welcome page rendered !!!!');
  const {signInAnonymously} = useContext(AuthContext);

  const navigateTo = (screen) => () => {
    navigation.navigate(screen);
  };

  const ignoreAuth = () => {
    // alert('hello');
    console.log('hello');
    signInAnonymously();
  };
  return (
    <SafeAreaView style={styles.screenContainer}>
      <StatusBar
        backgroundColor={Colors.statusBarColor}
        barStyle="dark-content"
      />

      <ImageBackground /*source={bgImage}*/ style={styles.backgroundImage}>
        <View
          // ref={scrollViewRef}
          // showsVerticalScrollIndicator={false}
          style={styles.container}>
          <View style={styles.headerContainer}>
            {/* <TitleBigA style={{color: Colors.primaryColor}}>
            Registration
          </TitleBigA>
          <SubheadingA style={{color: '#999'}}>
            Sign up to get started.
          </SubheadingA> */}

            <View style={styles.logoContainer}>
              {/* <Image
                source={logo}
                style={{height: '100%', width: '100%'}}
                resizeMode="cover"
              /> */}
            </View>
            <View style={styles.versionContainer}>
              <TitleA style={styles.versionText}>
                <Text>v</Text>
                {version}
              </TitleA>
            </View>
          </View>

          <View style={styles.btnsContainer}>
            <ButtonPro
              onPress={navigateTo('SignUp')}
              text={'Sign Up'}
              textColor={Colors.background}
              style={{
                backgroundColor: Colors.primaryColor,
              }}
              // isLoading={}
              // loaderColor={}
              // disabled={registerButtonDisabled}
            />
            <ButtonPro
              onPress={navigateTo('SignIn')}
              text={'Log In'}
              textColor={Colors.primaryColor}
              style={{
                backgroundColor: Colors.white,
                borderColor: Colors.primaryColor,
                borderWidth: 1,
              }}
              // isLoading={}
              // loaderColor={}
              // disabled={registerButtonDisabled}
            />
            <ButtonPro
              onPress={ignoreAuth}
              text={'Skip for now'}
              textColor={Colors.primaryColor}
              style={{
                backgroundColor: Colors.transparent,
              }}
              // isLoading={}
              // loaderColor={}
              // disabled={registerButtonDisabled}
            />
          </View>
        </View>
      </ImageBackground>
    </SafeAreaView>
  );
};

export default WelcomeE;
