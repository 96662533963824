import React from 'react';
import { View, } from 'react-native';
import {SmallText} from '../text/CustomText';
import {useTranslation} from 'react-i18next';
import Colors from '../../theme/colors';

const ErrorDisplay = ({ listCoordinate, error,  currentListIndex, showInvoices }) => {
    const {t} = useTranslation();
    // Helper to check if there are errors in the current form
    const getCurrentFormErrors = () => {
      const currentList = listCoordinate[currentListIndex];
      if (!currentList) return null;
  
      // Find first participant with errors in current list
      const participantWithError = currentList.participants.find(
        participant => Object.keys(participant.errors).length > 0
      );
  
      // Check for society errors in current list
      const hasSocietyErrors = currentList.societyInfo && 
        Object.keys(currentList.societyInfo.errors).length > 0;
  
      return participantWithError || hasSocietyErrors;
    };
  
    // Helper to check if there are errors in other forms
    const hasOtherFormsErrors = () => {
      return listCoordinate.some((list, index) => {
        if (index === currentListIndex) return false;
        
        const hasParticipantErrors = list.participants.some(
          participant => Object.keys(participant.errors).length > 0
        );
        
        const hasSocietyErrors = list.societyInfo && 
          Object.keys(list.societyInfo.errors).length > 0;
  
        return hasParticipantErrors || hasSocietyErrors;
      });
    };
  
    // Get appropriate error message
    const getErrorMessage = () => {
      const currentFormErrors = getCurrentFormErrors();
      const hasErrors = currentFormErrors || hasOtherFormsErrors();
  
      if (!hasErrors) return null;
  
      if (currentFormErrors) {
        // If it's a participant error
        if (currentFormErrors.errors) {
          const participantIndex = listCoordinate[currentListIndex].participants
            .findIndex(p => p === currentFormErrors);
          
          return participantIndex === 0 
            ? t('fillMainParticipant')
            : t('fillAdditionalParticipant', { number: participantIndex + 1 });
        }
        // If it's a society error
        return t('fillSocietyInfo');
      }
  
      // If errors are in other forms
      return t('defilerFormulaire');
    };
  
    return (
      <View>
        {error.errorFields && (
          <View
            style={{
              display: 'flex',
              paddingVertical: 10,
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <SmallText style={{color: Colors.red}}>
              {getErrorMessage()}
            </SmallText>
          </View>
        )}
  
        {error.errorVoice && (
          <View
            style={{
              display: 'flex',
              paddingTop: 15,
              paddingBottom: 25,
            }}>
            <SmallText style={{color: Colors.red}}>
              {showInvoices
                ? `${t('inputVoice')}`
                : `${t('cocherCheckbox')}`}
            </SmallText>
          </View>
        )}
      </View>
    );
  };


  export default ErrorDisplay;