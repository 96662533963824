import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

const PenThinSVG = (props) => {
  const {style, ...restProps} = props;
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 36 36"
      style={style}
      {...restProps}>
      <Path
        fill="#2FBF86"
        stroke="#2FBF86"
        d="M31.569 10.716 25.284 4.43a1.688 1.688 0 0 0-2.39 0L5.556 21.773a1.688 1.688 0 0 0-.494 1.192v6.285a1.688 1.688 0 0 0 1.688 1.688h6.284a1.678 1.678 0 0 0 1.193-.494l12.47-12.47.805 3.235-5.399 5.4a.563.563 0 1 0 .795.796l5.625-5.625a.563.563 0 0 0 .149-.534l-1.049-4.19 3.946-3.947a1.688 1.688 0 0 0 0-2.393ZM6.188 29.25v-5.392l5.955 5.955H6.75a.563.563 0 0 1-.563-.563Zm7.312.33L6.42 22.5 19.126 9.796l7.08 7.08L13.5 29.578Zm17.273-17.274L27 16.08 19.92 9l3.774-3.773a.562.562 0 0 1 .796 0l6.283 6.285a.562.562 0 0 1 0 .794Z"
      />
    </Svg>
  );
};
export default PenThinSVG;
