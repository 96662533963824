import React, {useEffect, useState} from 'react';
import {
  View,
  Text,
  ScrollView,
  StyleSheet,
  ActivityIndicator,
  StatusBar,
  SafeAreaView,
  Image,
} from 'react-native';
import axios from 'axios';
import Icon from 'react-native-vector-icons/FontAwesome';
import Colors from '../../theme/colors.js';
import TitleA from '../../components/titleA/TitleA.js';
const GoogleReviews = ({route}) => {
  const {address} = route.params;
  const GOOGLE_API_KEY = 'AIzaSyDmsSuw9FA61jQhYJKNLa1BiGl_ubliCxc';

  const [reviews, setReviews] = useState([]);
  const [placeId, setPlaceId] = useState(null);
  const [loadingReviews, setLoadingReviews] = React.useState(false);
  const convertTimestampToDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString();
  };
  const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <Icon
          key={i}
          name={i <= rating ? 'star' : 'star-o'}
          size={15}
          color="#FFD700"
        />,
      );
    }
    return <View style={styles.stars}>{stars}</View>;
  };
  useEffect(() => {
    const fetchPlaceId = async () => {
      try {
        {
          /* Cors :  if https://cors.bridged.cc : 403 you can do https://cors-anywhere.herokuapp.com    */
        }
        const url = `https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/place/textsearch/json?query=${encodeURIComponent(
          address,
        )}&key=${GOOGLE_API_KEY}&fields=name,rating,formatted_phone_number,reviews`;

        const response = await axios.get(url, {
          headers: {
            origin: 'http://localhost:19006',
            'x-requested-with': 'XMLHttpRequest',
          },
        });

        if (response.data.results && response.data.results.length > 0) {
          setPlaceId(response.data.results[0].place_id);
        } else {
          console.error('No place found for this query.');
        }
      } catch (error) {
        console.error('Error fetching place ID: ', error);
      }
    };

    fetchPlaceId();
  }, [address]);

  useEffect(() => {
    const fetchReviews = async () => {
      setLoadingReviews(true);
      if (!placeId) return;
      {
        /* Cors :  if https://cors.bridged.cc : 403 you can do https://cors-anywhere.herokuapp.com    */
      }
      try {
        const url = `https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/place/details/json?placeid=${placeId}&key=${GOOGLE_API_KEY}`;

        const response = await axios.get(url, {
          headers: {
            origin: 'http://localhost:19006',
            'x-requested-with': 'XMLHttpRequest',
          },
        });
        if (response.data.result && response.data.result.reviews) {
          setReviews(response.data.result.reviews);
        } else {
          console.error('No reviews found for this place.');
        }
      } catch (error) {
        console.error('Error fetching reviews: ', error);
      } finally {
        setLoadingReviews(false);
      }
    };

    fetchReviews();
  }, [placeId]);

  if (loadingReviews) {
    return (
      <SafeAreaView style={{flex: 1, backgroundColor: Colors.background}}>
        <StatusBar backgroundColor={Colors.white} barStyle="dark-content" />
        <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
          <ActivityIndicator size="large" color="green" />
        </View>
      </SafeAreaView>
    );
  }

  return (
    <ScrollView style={styles.container}>
      {reviews.length > 0 ? (
        reviews.map((review, index) => (
          <View key={index} style={styles.reviewContainer}>
            <View style={{flexDirection: 'row', gap: 15, alignItems: 'center'}}>
              <Image
                source={{uri: review.profile_photo_url}}
                style={styles.profilePhoto}
              />
              <View>
                <Text style={{color: Colors.normalGray}}>
                  Posted on: {convertTimestampToDate(review.time)}
                </Text>
                <TitleA>{review.author_name}</TitleA>
              </View>
            </View>
            {renderStars(review.rating)}
            <Text style={styles.text}>{review.text}</Text>
          </View>
        ))
      ) : (
        <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
          <TitleA>No reviews available</TitleA>
        </View>
      )}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
  },
  reviewContainer: {
    marginBottom: 15,
    padding: 10,
    backgroundColor: Colors.lightGray,
    borderRadius: 15,
  },
  author: {
    fontWeight: 'bold',
  },
  profilePhoto: {
    width: 35,
    height: 35,
    borderRadius: 15,
  },
  rating: {
    marginTop: 5,
  },
  stars: {
    flexDirection: 'row',
    marginTop: 15,
    gap: 5,
  },
  text: {
    marginTop: 5,
    color: Colors.normalGray,
  },
});

export default GoogleReviews;
