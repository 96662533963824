import React, {useContext, useEffect, useMemo, useState} from 'react';
import styles from './CheckoutSuccessAStyles';
import Colors from '../../theme/colors';
import {
  FlatList,
  SafeAreaView,
  ScrollView,
  StatusBar,
  Text,
  View,
} from 'react-native';

// Import Firebase
import firebase from 'firebase/compat/app';
import {db} from '../../services/firebase';
import {collection, query, where, getDocs} from 'firebase/firestore';

import ButtonA from '../../components/buttonA/ButtonA';
import TicketSVG from '../../components/svgs/TicketSVG';
import ConfirmedPurchaseSVG from '../../components/svgs/ConfirmedPurchaseSVG';
import SubheadingA from '../../components/subheadingA/SubheadingA';
import TimeSVG from '../../components/svgs/TimeSVG';
import BodyA from '../../components/bodyA/BodyA';
import TitleA from '../../components/titleA/TitleA';
import LocationSVG from '../../components/svgs/LocationSVG';
import PriceSVG from '../../components/svgs/PriceSVG';
import FavoritesEventsContext from '../../contexts/favoritesEventsContext';
import EventCardA from '../../components/eventCardA/EventCardA';
import {formattedDateUtils} from '../../utils/shared';
import ScreenTitleA from '../../components/screenTitleA/ScreenTitleA';
import {useTranslation} from 'react-i18next';

function formatDate(date) {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 to month because it's zero-based.
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

const SuggestedEvents = () => {
  const {t} = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const favoritesContext = useContext(FavoritesEventsContext);
  const [showFavorites, setShowFavorites] = useState(false);

  const [events, setEvents] = useState([]);

  const queryFn = async (reservationID) => {
    try {
      setIsLoading(true);

      // Calculate the start and end timestamps for the selected day
      const today = new Date();
      const startDate = firebase.firestore.Timestamp.fromDate(today);
      if (reservationID) {
        const reservation = await db
          .collection('reservations')
          .doc(reservationID)
          .get();
      }

      const qAllEvents = db
        .collection('events')
        .where('creationStatus', '==', 'published')
        .where('startDate', '>=', startDate);

      const eventSnapshots = await qAllEvents.get();
      const eventsData = [];

      for (const doc of eventSnapshots.docs) {
        const docdata = doc.data();
        const startDate = docdata.startDate?.toDate();
        const formattedDate = formattedDateUtils(startDate);

        const qTickets = db
          .collection('events')
          .doc(doc.id)
          .collection('tickets');
        const ticketSnapshots = await qTickets.get();
        const tickets = ticketSnapshots.docs.map((ticketDoc) =>
          ticketDoc.data(),
        );
        const price = tickets.length > 0 ? tickets[0].price : 'N/A';

        const eventData = {
          id: doc.id,
          //auditoriumid: params.id,
          description: docdata.description,
          summary: docdata.summary,
          dateDisplay: formattedDate,
          title: docdata.title,
          imageUri: docdata.coverImage,
          address: docdata.address,
          location: docdata.location,
          price: price, // Price from the first ticket in the tickets sub-collection
        };

        eventsData.push(eventData);
      }

      setEvents(eventsData);
      setIsLoading(false);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await queryFn();
      } catch (error) {
        console.log('error', error);
      }
    };

    fetchData();
  }, []);

  // Function to get the month name
  const getMonthName = (monthIndex) => {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    return months[monthIndex];
  };

  const eventKeyExtractor = (item, index) =>
    item && item.id ? item.id.toString() : index.toString();

  const renderShowItem = ({item, index}) => {
    if (!item || !item.id) {
      return null; // If item or item.id is not defined, return null to skip rendering
    }
    return (
      <EventCardA
        key={index}
        eventId={item.id}
        imageUri={item.imageUri}
        name={item.title}
        description={item.summary}
        date={item.dateDisplay}
        address={item.address}
        location={item.location}
        price={item.price}
      />
    );
  };

  return (
    <View>
      <TitleA
        style={{
          paddingVertical: 20,
          marginHorizontal: 25,
          borderTopWidth: 1,
          borderColor: Colors.lightGray,
        }}>
        {/* See as well */}
        {t('CheckoutFailedA.Voir aussi')}
      </TitleA>

      <FlatList
        data={events}
        showsHorizontalScrollIndicator={false}
        alwaysBounceHorizontal={false}
        keyExtractor={eventKeyExtractor}
        renderItem={renderShowItem}
        contentContainerStyle={styles.contentContainerStyle}
      />
    </View>
  );
};

const CheckoutSuccessA = ({navigation, route}) => {
  const {t} = useTranslation();
  const [reservation, setReservation] = useState();
  const [event, setEvent] = useState();

  const queryFn = async (reservationID) => {
    try {
      if (reservationID) {
        const reservation = await db
          .collection('reservations')
          .doc(reservationID)
          .get();

        setReservation(reservation.data());
        setEvent(reservation.data().event);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    const fetchData = async (reservationID) => {
      try {
        await queryFn(reservationID);
      } catch (error) {
        console.log('error', error);
      }
    };
    if (route?.params?.newid) fetchData(route?.params?.newid);
  }, [route]);

  const dateEvent = useMemo(() => {
    if (event?.startsAt) {
      const milliseconds =
        event.startsAt.seconds * 1000 + event.startsAt.nanoseconds / 1e6;
      const date = new Date(milliseconds);
      return formatDate(date);
    } else return '';
  }, [event]);

  const onHandleBack = () => {
    navigation.navigate('HomeNavigator', {
      screen: 'Home',
      params: {
        screen: 'Event',
        params: {s: event?.id},
      },
    });
  };

  return (
    <SafeAreaView style={styles.screenContainer}>
      <ScreenTitleA
        onPress={onHandleBack}
        disableBack={false}
        title={'Checkout Failed'}
      />
      <StatusBar backgroundColor={Colors.white} barStyle="dark-content" />
      <ScrollView showsVerticalScrollIndicator={false}>
        <View style={styles.imageContainer}>
          {/* <ConfirmedPurchaseSVG style={styles.image} /> */}
          <Text
            style={{
              marginTop: 10,
              color: Colors.red,
              textAlign: 'center',
              fontFamily: 'Bold',
              fontSize: 24,
              lineHeight: 36,
              letterSpacing: 0.96,
            }}>
            {/* Echoué */}
            {t('CheckoutFailedA.Echoué')}
          </Text>

          <Text
            style={{
              color: Colors.darkGray,
              textAlign: 'center',
              fontFamily: 'SemiBold',
              fontSize: 16,
              // lineHeight: 36,
              letterSpacing: 0.64,
            }}>
            {t('CheckoutFailedA.Votre achat a échoué')}
          </Text>
        </View>
        {/* 
        <View style={styles.container}>
          <View style={{marginTop: 20, marginBottom: 10, paddingHorizontal: 7}}>
            <ButtonA
              onPress={() => {
                navigation.navigate('upcomingTickets');
              }}
              style={{backgroundColor: Colors.blue}}>
              <TicketSVG
                stroke={Colors.background}
                style={{
                  width: 18,
                  height: 18,
                  marginBottom: -2,
                  marginRight: 10,
                }}
              />
              Events List
            </ButtonA>
          </View>
        </View> */}
        <SuggestedEvents />
      </ScrollView>
    </SafeAreaView>
  );
};

export default CheckoutSuccessA;
