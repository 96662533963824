import React from 'react';
import {
  View,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
} from 'react-native';
import Colors from '../../theme/colors';
import BodyA from '../bodyA/BodyA';
import SubheadingA from '../subheadingA/SubheadingA';
import { useTranslation } from 'react-i18next';

const PriceLevelSelector = ({
  selectedDeformation,
  priceDeformations,
  onDeformationSelect,
  onReset
}) => {
  const { t } = useTranslation();

  return (
    <View style={styles.container}>
      <SubheadingA style={styles.title}>
        {t("priceDeformation.selectPriceLevel")}
      </SubheadingA>
      <ScrollView 
        horizontal 
        showsHorizontalScrollIndicator={false}
        style={styles.scrollView}
      >
        {/* Original Price Option */}
        <TouchableOpacity
          style={[
            styles.priceOption,
            !selectedDeformation && styles.selectedOption
          ]}
          onPress={onReset}
        >
          <BodyA style={[
            styles.optionText,
            !selectedDeformation && styles.selectedOptionText
          ]}>
            {t("priceDeformation.originalPrice")}
          </BodyA>
        </TouchableOpacity>

        {/* Deformation Options */}
        {priceDeformations.map((deformation) => (
          <TouchableOpacity
            key={deformation.id}
            style={[
              styles.priceOption,
              {
                backgroundColor: deformation.color || Colors.darkGreen,
                opacity: selectedDeformation?.id === deformation.id ? 1 : 0.7
              }
            ]}
            onPress={() => onDeformationSelect(deformation)}
          >
            <BodyA style={styles.deformationName}>
              {deformation.name}
            </BodyA>
            <BodyA style={styles.deformationValue}>
              {deformation.deformationType === 'PERCENTAGE'
                ? `${deformation.deformationValue}${t("priceDeformation.percentageOff")}`
                : `${deformation.deformationValue}${t("priceDeformation.amountOff")}`}
            </BodyA>
          </TouchableOpacity>
        ))}
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginVertical: 10,
    paddingHorizontal: 16,
  },
  title: {
    marginBottom: 10,
    color: Colors.darkGray,
  },
  scrollView: {
    flexDirection: 'row',
  },
  priceOption: {
    padding: 8,
    borderRadius: 8,
    marginRight: 8,
    backgroundColor: Colors.background,
    borderWidth: 1,
    borderColor: Colors.normalGray,
    minWidth: 100,
    justifyContent: 'center',
    alignItems: 'center',
  },
  selectedOption: {
    backgroundColor: Colors.darkGreen,
    borderColor: Colors.darkGreen,
  },
  optionText: {
    color: Colors.normalGray,
  },
  selectedOptionText: {
    color: Colors.white,
  },
  deformationName: {
    color: Colors.white,
    marginBottom: 4,
  },
  deformationValue: {
    color: Colors.white,
  },
});

export default PriceLevelSelector;