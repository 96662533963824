// First create a new component for the access restriction selector

import React from 'react';
import { View, Text, StyleSheet, } from 'react-native';
import { useTranslation } from 'react-i18next';
import ButtonA from '../../components/buttonA/ButtonA';
import Colors from '../../theme/colors';

const AccessRestrictionSelector = ({
  selectedRestriction,
  onChangeClick,
  style
}) => {
  const { t } = useTranslation();

  if (!selectedRestriction) return null;

  return (
    <View style={[styles.restrictionContainer, style]}>
      <View style={styles.restrictionInfo}>
        <Text style={styles.restrictionLabel}>
          {t('EventBPage.Current Access')}:
        </Text>
        <Text style={styles.restrictionValue}>
          {selectedRestriction.name}
        </Text>
        <Text style={styles.restrictionEmail}>
          {selectedRestriction.verifiedEmail}
        </Text>
      </View>
      <ButtonA
        onPress={onChangeClick}
        style={styles.changeButton}
        color={Colors.white}
      >
        {t('EventBPage.Change')}
      </ButtonA>
    </View>
  );
};

const styles = StyleSheet.create({
  restrictionContainer: {
    backgroundColor: Colors.lightGray,
    borderRadius: 8,
    padding: 12,
    marginBottom: 16,
  },
  restrictionInfo: {
    marginBottom: 8,
  },
  restrictionLabel: {
    fontSize: 14,
    color: Colors.darkGray,
    marginBottom: 4,
  },
  restrictionValue: {
    fontSize: 16,
    fontWeight: 'bold',
    color: Colors.darkGreen,
    marginBottom: 2,
  },
  restrictionEmail: {
    fontSize: 14,
    color: Colors.normalGray,
  },
  changeButton: {
    backgroundColor: Colors.blue,
    paddingVertical: 8,
    paddingHorizontal: 16,
    alignSelf: 'flex-start',
  },
});

export default AccessRestrictionSelector;