import {Alert} from 'react-native';
import apiKeys from '../config/firebase-config';

import firebase from 'firebase/compat/app';
import {getMessaging, getToken, onMessage} from '@firebase/messaging';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from 'firebase/functions';

const env = 'prod'; //Constants.manifest.releaseChannel
var firebasecredentials = apiKeys.firebaseConfig.prod;

if (__DEV__) {
  firebasecredentials = apiKeys.firebaseConfig.prod;
} else if (env === 'uat') {
  firebasecredentials = apiKeys.firebaseConfig.uat;
} else if (env === 'hom') {
  firebasecredentials = apiKeys.firebaseConfig.hom;
}
//console.log('firebasecredentials');
//console.log(firebasecredentials);

let firebaseApp = null;
if (!firebase.apps.length) {
  firebaseApp = firebase.initializeApp(firebasecredentials);
} else {
  firebaseApp = firebase.app(); // if already initialized, use that one
}
//firebase.initializeApp(apiKeys.firebaseConfig);
const FUNCTIONS_REGION = 'europe-west1';
export const db = firebase.firestore();
export const FUNCTIONS = getFunctions(firebaseApp, FUNCTIONS_REGION);
//connectFunctionsEmulator(FUNCTIONS, "127.0.0.1", 5001);

//const rnmessaging = messaging();

export const requestForToken = () => {
  const rnmessaging = getMessaging();
  return getToken(getMessaging(), {
    vapidKey: `BOT18QfSX_EBTl4Hm932UZTDF2nBg0CIWtWUYFrb5NAwSJFgwgzz13eUkhlxgZxFNyXmN-melKe-0w_LUZeuao4`,
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        const currentUser = firebase.auth().currentUser;
        db.collection('users').doc(currentUser.uid).set(
          {
            webToken: currentToken,
          },
          {merge: true},
        );
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log(
          'No registration token available. Request permission to generate one.',
        );
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
};

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(getMessaging(), (payload) => {
      resolve(payload);
    });
  });

export async function signInAnonymouslyAsync() {
  try {
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    await firebase.auth().signInAnonymously();
    const currentUser = firebase.auth().currentUser;
    db.collection('users').doc(currentUser.uid).set(
      {
        lastLoggedIn: Date.now(),
      },
      {merge: true},
    );

    return currentUser;
  } catch (err) {
    alert(err.message);
    Alert.alert('There is something wrong!', err.message);
  }
}
export async function signInFirebase(email, password) {
  try {
    // await firebase.auth().signInWithEmailAndPassword(email, password);
    // const currentUser = firebase.auth().currentUser;

    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password,
    );

    const currentUser = userCredential.user;

    await db
      .collection('users')
      .doc(currentUser.uid)
      .update({
        lastLoggedIn: Timestamp.fromDate(new Date()),
        isNewUser: false,
        logged: true,
      });
    return currentUser;
  } catch (err) {
    Alert.alert('Un problème est survenu!', err.message);
    // db.collection('users').doc(currentUser.uid).update({
    //   logged: false,
    // });
  }
}
export async function registration(email, password, lastName, firstName) {
  try {
    // Register a new user using the auth constant
    const credentials = await createUserWithEmailAndPassword(
      auth,
      email,
      password,
    );

    // Get the current authenticated user from the credentials
    const currentUser = credentials.user;

    // Access Firestore
    // const db = firebase.firestore();

    // Update the user document in Firestore
    await db
      .collection('users')
      .doc(currentUser.uid)
      .set(
        {
          email: currentUser.email,
          lastName: lastName,
          firstName: firstName,
          displayName: `${firstName} ${lastName}`,
          createdAt: Timestamp.fromDate(new Date()),
          logged: true,
          isNewUser: true,
          uid: currentUser.uid,
        },
        {merge: true},
      );

    // Return the current user
    return currentUser;
  } catch (err) {
    // Handle registration errors and show an alert
    Alert.alert('A problem has occurred!', err.message);
  }
}

export async function loggingOut() {
  console.log('logout');
  try {
    await firebase.auth().signOut();
  } catch (err) {
    Alert.alert('There is something wrong!', err.message);
  }
}

export async function resetPassword(email) {
  try {
    await firebase.auth().sendPasswordResetEmail(email);
  } catch (err) {
    Alert.alert('There is something wrong!', err.message);
  }
}

export async function getCurrentUser() {
  try {
    await firebase.auth().currentUser;
  } catch (err) {
    Alert.alert('There is something wrong!', err.message);
  }
}
