// import dependencies
import React, {useContext, useEffect, useState} from 'react';
import styles from './VerticalFoodCardStyles.js';
import Colors from '../../theme/colors.js';
import {Image, Platform, Text, View} from 'react-native';
import getImgSource from '../../utils/getImgSource.js';

// import components
import TouchableItem from '../TouchableItem.js';
import TitleA from '../titleA/TitleA.js';
import SubheadingA from '../subheadingA/SubheadingA.js';
import BodyA from '../bodyA/BodyA.js';
import NewSVG from '../svgs/NewSVG.js';
import FavoriteSVG from '../svgs/FavoriteSVG.js';
import LocationSVG from '../svgs/LocationSVG.js';
import TimeSVG from '../svgs/TimeSVG.js';
import TicketPriceSVG from '../svgs/TicketPriceSVg.js';
import FavoritesEventsContext from '../../contexts/favoritesEventsContext.js';
import {useNavigation} from '@react-navigation/native';
import TicketSVG from '../svgs/TicketSVG.js';
import StarSVG from '../svgs/StarSVG.js';
import PriceSVG from '../svgs/PriceSVG.js';
import PromoFrame from '../svgs/PromoFrame.js';

// import colors, layout

// RestaurantCard Config
const imgHolder = require('../../../assets/images/imgholder.png');
const IOS = Platform.OS === 'ios';
const STAR_ICON = IOS ? 'ios-star' : 'md-star';
// RestaurantCard Styles

const VerticalFoodCard = ({
  eventId,
  activeOpacity,
  onPress,
  name,
  rate,
  open,
  category,
  imageUri,
  description,
  title,
  review,
  price,
  ownerid,
  status,
  time,
  address,
  promoPercentage,
  exist,
  id,
}) => {
  // const favoritesContext = useContext(FavoritesEventsContext);
  const [isFavorite, setIsFavorite] = useState(false); // State to track if the event is a favorite or not

  const navigation = useNavigation();

  // Check if the event is already a favorite when the component mounts
  // useEffect(() => {
  //   setIsFavorite(favoritesContext.eventIsFavorite(eventId));
  // }, [favoritesContext, eventId]);

  // Function to toggle the event as a favorite or not
  const handleToggleFavorite = () => {
    // if (isFavorite) {
    //   favoritesContext.removeFavorite(eventId);
    // } else {
    //   favoritesContext.addFavorite({
    //     id: eventId,
    //     name, // Replace with the name of the event from the props
    //   });
    // }
    setIsFavorite(!isFavorite); // Toggle the state
  };

  // Function to navigate to the event details screen
  const navigateToRestaurantPage = () => {
    navigation.navigate('RestaurantPage', {id, address, ownerid});
  };

  return (
    <TouchableItem
      activeOpacity={activeOpacity}
      onPress={navigateToRestaurantPage}
      borderless
      useForeground
      style={styles.touchableItem}>
      <View style={styles.cardContainer}>
        {/* <View style={styles.promoFrameContainer}> */}
        {exist && (
          <View style={{zIndex: 100}}>
            <PromoFrame style={styles.newIcon} fill={Colors.darkGreen} />
            <View style={styles.rate}>
              <Text
                style={{
                  color: Colors.white,
                  fontSize: 11,
                  fontFamily: 'SemiBold',
                }}>
                GT
              </Text>
            </View>
          </View>
        )}
        {/* {rate && (
          <View style={{zIndex: 100}}>
            <PromoFrame style={styles.newIcon} fill={Colors.darkGreen} />
            <View style={styles.rate}>
              <Text
                style={{
                  color: Colors.white,
                  fontSize: 11,
                  fontFamily: 'SemiBold',
                }}>
                {rate}
              </Text>
            </View>
          </View>
        )} */}

        <TouchableItem
          onPress={handleToggleFavorite}
          style={{
            backgroundColor: isFavorite ? Colors.darkGreen : Colors.background,
            width: 33,
            height: 33,
            borderRadius: 50,
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            top: 80,
            right: 7,
            zIndex: 2,
          }}>
          <FavoriteSVG
            fill={isFavorite ? Colors.background : Colors.normalGray}
            style={styles.favoriteIcon}
          />
        </TouchableItem>

        <Image
          defaultSource={imgHolder}
          source={getImgSource(imageUri)}
          style={styles.cardImage}
          resizeMode="cover"
        />

        <View style={styles.cardContent}>
          <View style={styles.TitleRow}>
            <TitleA>{description}</TitleA>
          </View>

          <View style={styles.subheadingRow}>
            <SubheadingA style={styles.eventName}>{title}</SubheadingA>
          </View>
          {category && (
            <View style={styles.category}>
              <SubheadingA style={styles.categoryType}>{category}</SubheadingA>
            </View>
          )}
          <View style={styles.bodyRow}>
            <StarSVG style={styles.rowIcon} />
            <BodyA style={{color: Colors.normalGray}}>{review}</BodyA>
          </View>

          {/* <View style={styles.bodyRow}>
            <TimeSVG style={styles.rowIcon} />
            <BodyA style={{color: Colors.normalGray}}>{date}</BodyA>
          </View> */}

          {address && (
            <View style={styles.bodyRow}>
              <LocationSVG style={styles.rowIcon} />
              <BodyA style={{color: Colors.normalGray, width: '85%'}}>
                {address}
              </BodyA>
            </View>
          )}

          {time && (
            <View style={styles.bodyRow}>
              <TimeSVG style={styles.rowIcon} />
              <BodyA style={{color: Colors.normalGray}}>{time}</BodyA>{' '}
              <BodyA style={{color: Colors.darkGreen}}>{status}</BodyA>
            </View>
          )}
          {price && (
            <View style={styles.bodyRow}>
              <PriceSVG
                // viewBox="-6 -6 38 38"
                style={styles.rowIcon}
                stroke={Colors.darkGreen}
              />
              <BodyA style={{color: Colors.darkGreen, fontFamily: 'SemiBold'}}>
                {price}
                {' €'}
              </BodyA>
            </View>
          )}
        </View>
      </View>
    </TouchableItem>
  );
};

export default VerticalFoodCard;
