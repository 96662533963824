import React, {useEffect, useMemo, useState} from 'react';
import ScreenTitleA from '../../components/screenTitleA/ScreenTitleA';
import Colors from '../../theme/colors';
import TitleBigA from '../../components/titleBigA/TitleBigA';
import CartSVG from '../../components/svgs/CartSVG';
import {
  StatusBar,
  Text,
  View,
  TextInput,
  SafeAreaView,
  TouchableOpacity,
} from 'react-native';
import PrimeStartSVG from '../../components/svgs/PrimeStartSVG';
import StarSVG from '../../components/svgs/StarSVG';
import LocationSVG from '../../components/svgs/LocationSVG';
import styles from './LivredCommandStyle';
import TicketSVG from '../../components/svgs/TicketSVG';
import {db} from '../../services/firebase';
import IsolionModeSvgFille from '../../components/svgs/IsolutionModeFilleSVG';
import MenuBookSvg from '../../components/svgs/MenuBookSVG';
import StartFillNoteSVG from '../../components/svgs/StartFillNoteSVG';
import {useNavigation} from '@react-navigation/native';

const StarRating = ({numStars}) => {
  const [checkedStartSatisfait, setCheckedStarSatisfait] = React.useState([
    false,
    false,
    false,
    false,
    false,
  ]);

  const handleStarPress = (index) => {
    const newStars = checkedStartSatisfait.map((_, i) =>
      i <= index ? true : false,
    );
    setCheckedStarSatisfait(newStars);
  };

  return (
    <View style={{flexDirection: 'row'}}>
      {checkedStartSatisfait.map((checked, index) => (
        <View key={index}>
          <StartFillNoteSVG
            Checked={checked}
            onPress={() => handleStarPress(index)}
            width={35}
            height={35}
          />
        </View>
      ))}
    </View>
  );
};
const CommandeLivree = ({route}) => {
  const navigation = useNavigation();
  const [reservation, setReservation] = useState();
  const [loading, setLoading] = useState(false);
  const id = route.params.id;
  const queryFn = async (id) => {
    setLoading(true);
    db.collection('orders')
      .doc(id)
      // .get()
      .onSnapshot(async (reservationData) => {
        const reservation = reservationData.data();
        setReservation(reservation);
      });
    setLoading(false);
  };

  useEffect(() => {
    if (id) queryFn(id);
  }, [id]);

  const foodListName = useMemo(() => {
    if (reservation)
      return reservation?.products?.reduce(
        (acc, item) => acc + item.quantity + ' ' + item.name,
        '',
      );
    else return '';
  }, [reservation]);

  return (
    <SafeAreaView style={styles.containerSceen}>
      <StatusBar />
      <View style={styles.container}>
        <IsolionModeSvgFille />
        <View style={{marginLeft: 40}}>
          <Text style={styles.titleCommandeLivre}> Commande Livrée</Text>
          <Text style={styles.textTitleCommande}>
            {' '}
            Votre commande est livrée{' '}
          </Text>
        </View>
      </View>
      <View style={{backgroundColor: 'dsd'}}>
        <View style={{paddingHorizontal: 24, paddingTop: 16}}>
          <View style={styles.title}>
            <View>
              <CartSVG stroke={Colors.darkGray} style={styles.cartIcon} />
            </View>
            <View>
              <TitleBigA>{reservation?.store?.name}</TitleBigA>
            </View>
          </View>
          <View style={styles.detail}>
            <View style={[styles.icon, {width: 16, height: 16}]}>
              <TicketSVG stroke={Colors.normalGray} />
            </View>
            <View style={{flexWrap: 'wrap', flexShrink: 1}}>
              <Text>{foodListName}</Text>
            </View>
          </View>
          <View style={{paddingHorizontal: 8}}>
            <View style={styles.detail}>
              <View style={[styles.icon, {width: 16, height: 16}]}>
                <TicketSVG stroke={Colors.normalGray} />
              </View>
              <View style={{flexWrap: 'wrap', flexShrink: 1}}>
                <Text>{reservation?.store?.name}</Text>
              </View>
            </View>
            <View style={styles.detail}>
              <View style={styles.icon}>
                <LocationSVG />
              </View>
              <View style={{flexWrap: 'wrap', flexShrink: 1}}>
                <Text style={{color: Colors.normalGray}}>
                  Livrer à l’adresse 1 Bd de Bonne Nouvelle, 75002 Paris
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
      <View style={{paddingHorizontal: 24, paddingBottom: 16}}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <Text style={styles.titleCommandeLivre}>Evaluation </Text>
          <View style={{flexDirection: 'row'}}>
            <StarRating />
          </View>
        </View>
        <View style={{paddingVertical: 10}}>
          <TouchableOpacity
            style={styles.buttonStyle}
            onPress={() => navigation.navigate('Notez')}>
            <Text style={styles.textBtnStyle}>Donnez votre avis </Text>
          </TouchableOpacity>
        </View>

        <View style={[styles.title, {gap: 3}]}>
          <View>
            <MenuBookSvg stroke={Colors.darkGray} style={styles.cartIcon} />
          </View>
          <View>
            <TitleBigA>Détailles</TitleBigA>
          </View>
        </View>
        {reservation &&
          reservation?.products?.map((product) => {
            const groupedComposition = product.composition.reduce(
              (grouped, item) => {
                if (!grouped[item.category]) {
                  grouped[item.category] = [];
                }
                grouped[item.category].push(item);
                return grouped;
              },
              {},
            );
            return (
              <View style={{paddingHorizontal: 15, paddingBottom: 8}}>
                <View style={styles.detailA}>
                  <View>
                    <Text style={styles.textDetailA}> {product.name}</Text>
                  </View>
                  <Text>{product.quantity}</Text>
                  <Text>{product.price}$</Text>
                </View>

                <View style={styles.allDetailesA}>
                  <View>
                    {Object.keys(groupedComposition).map((category, index) => {
                      const items = groupedComposition[category];
                      if (items && items.length > 0) {
                        return (
                          <View key={index}>
                            <Text style={styles.titleTextDetaileContainer}>
                              {category}
                            </Text>
                            {items?.map((composition, phraseIndex) => (
                              <View style={styles.textMapingCommande}>
                                <Text
                                  key={phraseIndex}
                                  style={{
                                    color: 'var(--Normal-Grey, #77838F)',
                                    fontFamily: 'Bai Jamjuree',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: 300,
                                  }}>
                                  {composition.quantity
                                    ? composition.quantity
                                    : 1}{' '}
                                  {composition.name}
                                </Text>
                              </View>
                            ))}
                          </View>
                        );
                      }
                    })}
                  </View>
                </View>
              </View>
            );
          })}
      </View>
    </SafeAreaView>
  );
};
export default CommandeLivree;
