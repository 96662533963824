import React, {useState, useEffect} from 'react';
import {NavigationContainer} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';
import AsyncStorage from '@react-native-async-storage/async-storage';
// import Verification screen
import Verification from '../screens/verification/VerificationA';
// import Onboarding screen
import Onboarding from '../screens/onboarding/OnboardingA';
// import Welcome screen
//import Welcome from '../screens/welcome/WelcomeA';
import Welcome from '../screens/welcome/WelcomeS';
// import SignIn screen
import SignIn from '../screens/signin/SignInA';
// import ForgotPassword screen
import ForgotPassword from '../screens/forgotpassword/ForgotPasswordA';
// import SignUp screen
import SignUp from '../screens/signup/SignUpA';
// import TermsConditions screen
import TermsConditions from '../screens/terms/TermsConditionsA';
// import colors
import Colors from '../theme/colors';

const RootStack = createStackNavigator();
import OnboardingD from '../screens/onboarding/OnboardingD';
import WelcomeE from '../screens/welcome/WelcomeE';
// import SignUpD from '../screens/signup/SignUpD';
// import SignInC from '../screens/signin/SignInC';
function RootStackNavigator({navigation}) {
  const [isFirstLaunch, setIsFirstLaunch] = useState(null);
  let routeName;

  useEffect(() => {
    AsyncStorage.getItem('alreadyLaunched').then((value) => {
      if (value == null) {
        AsyncStorage.setItem('alreadyLaunched', 'true'); // No need to wait for `setItem` to finish, although you might want to handle errors
        setIsFirstLaunch(true);
      } else {
        setIsFirstLaunch(false);
      }
    }); // Add some error handling, also you can simply do setIsFirstLaunch(null)
  }, []);

  /*if (isFirstLaunch === null) {
    return null; // This is the 'tricky' part: The query to AsyncStorage is not finished, but we have to present something to the user. Null will just render nothing, so you can also put a placeholder of some sort, but effectively the interval between the first mount and AsyncStorage retrieving your data won't be noticeable to the user. But if you want to display anything then you can use a LOADER here
  } else if (isFirstLaunch == true) {
    routeName = 'Onboarding';
  } else {
    routeName = 'Welcome';
  }*/
  if (isFirstLaunch === null) {
    return null; // This is the 'tricky' part: The query to AsyncStorage is not finished, but we have to present something to the user. Null will just render nothing, so you can also put a placeholder of some sort, but effectively the interval between the first mount and AsyncStorage retrieving your data won't be noticeable to the user. But if you want to display anything then you can use a LOADER here
  } else if (isFirstLaunch == true) {
    routeName = 'Onboarding';
  } else {
    routeName = 'Welcome';
  }

  // routeName = 'Welcome';

  return (
    <RootStack.Navigator
      initialRouteName={routeName}
      screenOptions={{
        cardOverlayEnabled: false,
        headerStyle: {
          elevation: 1,
          shadowOpacity: 0,
        },
        headerBackTitleVisible: false,
        headerTitleStyle: {
          fontWeight: 'bold',
        },
        headerTintColor: Colors.onBackground,
        headerTitleAlign: 'center',
      }}>
      {/* <RootStack.Screen
        name="Onboarding"
        component={Onboarding}
        options={{headerShown: false}}
      /> */}
      <RootStack.Screen
        name="Onboarding"
        component={OnboardingD}
        options={{headerShown: false}}
      />
      {/* <RootStack.Screen
        name="Welcome"
        component={Welcome}
        options={{headerShown: false}}
      /> */}
      <RootStack.Screen
        name="Welcome"
        component={WelcomeE}
        options={{headerShown: false}}
      />

      {/* <RootStack.Screen
        name="SignUp"
        component={SignUpD}
        options={{
          title: 'Créer un compte',
          headerStyle: {
            elevation: 0,
            shadowOpacity: 0,
          },
        }}
      /> */}
      <RootStack.Screen
        name="Verification"
        component={Verification}
        options={{headerShown: false}}
      />

      {/* <RootStack.Screen
        name="SignIn"
        component={SignInC}
        options={{title: 'Sign In'}}
      /> */}

      <RootStack.Screen
        name="ForgotPassword"
        component={ForgotPassword}
        options={{
          headerStyle: {
            elevation: 0,
            shadowOpacity: 0,
          },
          title: 'Forgot Password?',
        }}
      />
      <RootStack.Screen
        name="TermsConditions"
        component={TermsConditions}
        options={{
          title: 'Termes et conditions',
        }}
      />
    </RootStack.Navigator>
  );
}

export default RootStackNavigator;
