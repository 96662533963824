import { StyleSheet } from 'react-native';
import Color from 'color';
import Colors from '../../theme/colors';


// HomeA Styles
const styles = StyleSheet.create({
    screenContainer: {
      flex: 1,
      backgroundColor: Colors.background,
    },
    container: {
      flex: 1,
    },
    categoriesContainer: {
      paddingBottom: 16,
    },
    titleContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: 8,
      paddingHorizontal: 16,
      paddingBottom: 8,
    },
    titleText: {
      fontWeight: '700',
    },
    viewAllText: {
      color: Colors.primaryColor,
    },
    categoriesList: {
      paddingTop: 4,
      paddingRight: 16,
      paddingLeft: 8,
    },
    cardImg: {borderRadius: 4},
    card: {
      marginLeft: 8,
      width: 104,
      height: 72,
      resizeMode: 'cover',
    },
    cardOverlay: {
      flex: 1,
      borderRadius: 4,
      backgroundColor: `${Color(Colors.overlayColor).alpha(0.2)}`,
      overflow: 'hidden',
    },
    cardContainer: {
      flex: 1,
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    cardTitle: {
      padding: 12,
      fontWeight: '500',
      fontSize: 16,
      color: Colors.white,
      textShadowColor: 'rgba(0, 0, 0, 0.75)',
      textShadowOffset: {width: -1, height: 1},
      textShadowRadius: 10,
    },
    productsList: {
      paddingBottom: 16,
      // spacing = paddingHorizontal + ActionProductCard margin = 12 + 4 = 16
      paddingHorizontal: 12,
    },
    popularProductsList: {
      // spacing = paddingHorizontal + ActionProductCardHorizontal margin = 12 + 4 = 16
      paddingHorizontal: 12,
      paddingBottom: 16,
    },
    productImg: {
      width: '100%',
      height: 286,
      resizeMode: 'contain',
    },
    bottomButtonContainer: {
      width: '100%',
      bottom: 60,
      paddingHorizontal: 24,
      position:'fixed'
    },
    icon: {
      width: 300,
      height: 300,
      alignSelf: 'center',
    },
    topIcon: {
      width: 100,
      height: 100,
      alignSelf: 'center',
    },
    tabBar: {
      backgroundColor: '#fff',
      borderBottomColor: '#f4f4f4',
      borderBottomWidth: 1
    },
    tabContainer: {
      borderBottomColor: '#090909'
    },
    goContainer: {
      padding: 16,
      flexDirection: 'row',
      justifyContent: 'flex-start'
    },
    tabText: {
      padding: 15,
      color: '#9e9e9e',
      fontSize: 18,
      fontWeight: '500'
    },
    separator: {
      height: 0.5,
      width: '96%',
      alignSelf: 'flex-end',
      backgroundColor: '#eaeaea'
    },
    sectionHeaderContainer: {
      height: 10,
      backgroundColor: '#f6f6f6',
      borderTopColor: '#f4f4f4',
      borderTopWidth: 1,
      borderBottomColor: '#f4f4f4',
      borderBottomWidth: 1
    },
    sectionHeaderText: {
      color: '#010101',
      backgroundColor: '#fff',
      fontSize: 23,
      fontWeight: 'bold',
      paddingTop: 25,
      paddingBottom: 5,
      paddingHorizontal: 15
    },
    itemContainer: {
      paddingVertical: 20,
      paddingHorizontal: 15,
      backgroundColor: '#fff'
    },
    itemTitle: {
      flex: 1,
      fontSize: 20,
      color: '#131313'
    },
    itemPrice: {
      fontSize: 18,
      color: '#131313'
    },
    itemDescription: {
      marginTop: 10,
      color: '#b6b6b6',
      fontSize: 16
    },
    itemRow: {
      flexDirection: 'row'
    },
    slide: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      paddingHorizontal: 24,
    },
    slideImg: {
      borderRadius: 8,
      width: 232,
      height: 192,
      resizeMode: 'cover',
    },
    title: {
      textAlign: 'center',
    }
  });

  export default styles;