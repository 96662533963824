import React from 'react';
import TouchableItem from '../TouchableItem';
import {ActivityIndicator, Text, View} from 'react-native';
import Colors from '../../theme/colors';

const ButtonPro = ({
  icon,
  text,
  textColor = Colors.background,
  style,
  isLoading,
  loaderColor = Colors.background,
  ...props
}) => {
  return (
    <TouchableItem
      style={[
        {
          backgroundColor: Colors.darkGray,
          paddingVertical: 15,
          paddingHorizontal: 25,
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 10,
          marginVertical: 10,
          flexDirection: 'row',
          minHeight: 58,
        },
        style,
      ]}
      {...props}>
      {isLoading ? (
        <ActivityIndicator size={'small'} color={loaderColor} />
      ) : (
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'center',
          }}>
          {icon && <View style={{marginRight: 8}}>{icon}</View>}
          <Text
            style={{
              fontFamily: 'SemiBold',
              fontSize: 16,
              letterSpacing: 0.64,
              textAlign: 'center',
              color: textColor,
              lineHeight: 18,
            }}>
            {text}
          </Text>
        </View>
      )}
    </TouchableItem>
  );
};

export default ButtonPro;
