import React from 'react';
import {Image} from 'react-native';
import AccountRemoveSVG from '../../components/svgs/AccountRemoveSVG';
import ClipboardSVG from '../../components/svgs/ClipboardSVG';
import InfoSVG from '../../components/svgs/InfoSVG';
import LogoutSVG from '../../components/svgs/LogoutSVG';
import PenThinSVG from '../../components/svgs/PenThinSVG';
import ShieldLineSVG from '../../components/svgs/ShieldLineSVG';
import TicketsLineSVG from '../../components/svgs/TicketsLineSVG';
import {useTranslation} from 'react-i18next';
import changeLanguageImage from '../../../assets/images/changeL.jpg';
export const getProfileParametersData = (t) => [
  {
    id: '1',
    icon: <ClipboardSVG />,
    title: t('Parametres.Mes commandes'),
    routeName: 'My orders',
  },
  {
    id: '2',
    icon: <TicketsLineSVG />,
    title: t('Parametres.Mes billets'),
    routeName: 'Orders',
  },
  {
    id: '3',
    icon: <TicketsLineSVG />,
    title: t('Parametres.Mes commandes'),
    routeName: 'Commandes',
  },
  {
    id: '4',
    icon: <PenThinSVG />,
    title: t('Parametres.Termes et conditions'),
    routeName: 'Terms and conditions',
  },
  {
    id: '5',
    icon: <ShieldLineSVG />,
    title: t('Parametres.Politique de confidentialité'),
    routeName: 'Privacy policy',
  },
  {
    id: '6',
    icon: <InfoSVG />,
    title: t('Parametres.À propos de nous'),
    routeName: 'About us',
  },
  {
    id: '7',
    icon: <LogoutSVG />,
    title: t('Parametres.se déconnecter'),
    routeName: 'Sign out',
  },
  {
    id: '8',
    icon: <AccountRemoveSVG />,
    title: t('Parametres.Supprimer mon compte'),
    routeName: 'Delete my account',
  },
  {
    id: '9',
    icon: (
      <Image source={changeLanguageImage} style={{width: 35, height: 35}} />
    ),
    title: t('Parametres.changement de langue'),
    routeName: 'ChangeLangue',
  },
  {
    id: '10',
    icon: <InfoSVG />,
    title: 'Sondaje',
    routeName: 'Sondaje',
  },
];
// const ProfileParameters = () => {
//   const {t} = useTranslation();

//   const profileParametersData = [
//     {
//       id: '1',
//       icon: <ClipboardSVG />,
//       title: t('profileParametersData.MyOrders'),
//       routeName: 'My orders',
//     },
//     {
//       id: '2',
//       icon: <TicketsLineSVG />,
//       title: t('profileParametersData.MyTickets'),
//       routeName: 'Orders',
//     },
//     {
//       id: '3',
//       icon: <TicketsLineSVG />,
//       title: t('profileParametersData.MyCommands'),
//       routeName: 'Commandes',
//     },
//     {
//       id: '4',
//       icon: <PenThinSVG />,
//       title: t('profileParametersData.TermsConditions'),
//       routeName: 'Terms and conditions',
//     },
//     {
//       id: '5',
//       icon: <ShieldLineSVG />,
//       title: t('profileParametersData.PrivacyPolicy'),
//       routeName: 'Privacy policy',
//     },
//     {
//       id: '6',
//       icon: <InfoSVG />,
//       title: t('profileParametersData.AboutUs'),
//       routeName: 'About us',
//     },
//     {
//       id: '7',
//       icon: <LogoutSVG />,
//       title: t('profileParametersData.SignOut'),
//       routeName: 'Sign out',
//     },
//     {
//       id: '8',
//       icon: <AccountRemoveSVG />,
//       title: t('profileParametersData.DeleteMyAccount'),
//       routeName: 'Delete my account',
//     },
//   ];
//   return (
//     <View> profileParametersData() </View>
//   )
// };

// export default ProfileParameters;
