
const getMonthName = (monthIndex) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return months[monthIndex];
};

export const formattedDateUtils = (startDate) =>
  `${startDate.getDate().toString().padStart(2, '0')} ${getMonthName(
    startDate.getMonth(),
  )} ${startDate.getFullYear()} at ${startDate
    .getHours()
    .toString()
    .padStart(2, '0')}:${startDate.getMinutes().toString().padStart(2, '0')}`;


    const formatEventDate = (startDate, endDate) => {
      if (!startDate || !endDate) {
        return '';
      }
    
      // For debugging
      console.log('Start Date:', startDate);
      console.log('End Date:', endDate);
    
      // Ensure we're working with Date objects
      const start = new Date(startDate);
      const end = new Date(endDate);
    
      // Format the time
      const timeFormatter = new Intl.DateTimeFormat('fr-FR', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      });
    
      const dateFormatter = new Intl.DateTimeFormat('fr-FR', {
        weekday: 'short',
        day: 'numeric',
        month: 'short',
        year: 'numeric' // Added year to ensure correct date
      });
    
      const startTime = timeFormatter.format(start);
      const endTime = timeFormatter.format(end);
      
      // Check if the event spans multiple days
      const isSameDay = start.toDateString() === end.toDateString();
      
      if (isSameDay) {
        // Format: "mer. 8 nov. de 23:59 à 05:00"
        const formattedDate = dateFormatter.format(start)
          .toLowerCase()
          .replace(/\s/g, ' ')  // Replace potential non-breaking spaces
          .replace(',', '')      // Remove comma if present
          .replace(/\d{4}/, ''); // Remove year
        
        return `${formattedDate}. de ${startTime} à ${endTime}`;
      } else {
        // Format: "mer. 8 nov. 23:59 - jeu. 9 nov. 05:00"
        const formattedStartDate = dateFormatter.format(start)
          .toLowerCase()
          .replace(/\s/g, ' ')
          .replace(',', '')
          .replace(/\d{4}/, '');
        
        const formattedEndDate = dateFormatter.format(end)
          .toLowerCase()
          .replace(/\s/g, ' ')
          .replace(',', '')
          .replace(/\d{4}/, '');
        
        return `${formattedStartDate}. ${startTime} - ${formattedEndDate}. ${endTime}`;
      }
    };
    export const getEventDateTime = (startDate, endDate) => {
      if (!startDate || !endDate) {
        return '';
      }
      
      try {
        // Convert Firebase Timestamp to Date if needed
        const start = startDate instanceof Date ? startDate : startDate.toDate();
        const end = endDate instanceof Date ? endDate : endDate.toDate();
        
        return formatEventDate(start, end);
      } catch (error) {
        console.error('Error formatting date:', error);
        return '';
      }
    };